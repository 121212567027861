import {memo, useCallback, useEffect, useRef, useState} from 'react';
import noop from '@tinkoff/utils/function/noop';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import {useIsMounted} from 'usehooks-ts';
import {useSelector} from 'react-redux';
import debounce from '@tinkoff/utils/function/debounce';
import {type Placement} from '@react-aria/overlays';
import {AxiosError} from 'axios';

import {type User} from 'features/users/types/user';
import {
    GET_MEMBERS_DELAY,
    GroupMentionDropdownError,
    groupMentionDropdownTitleMap,
    SPINNER_TIMEOUT,
    ROW_HEIGHT,
    MEMBERS_PER_PAGE,
} from 'features/groups_mention/constants';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';
import {Spinner} from '@time-webkit/all/atoms/spinner';
import {asyncDelay} from 'utils/asyncDelay';
import {type GlobalState} from 'types/store';
import {getGroup} from 'mattermost-redux/selectors/entities/groups';
import {Client4} from 'mattermost-redux/client';
import {Dropdown} from '@time-webkit/all/organisms/dropdown';
import {ClientError} from 'mattermost-redux/client/client4';
import {getGroupMemberCount} from '../api';

import GroupMemberRow from './group_member_row';

import styles from './group_mention_dropdown.module.css';

type Props = {
    groupId?: string;
    groupName?: string;
    isGroupMembersViewAvailable: boolean;
    overlayRef: React.RefObject<HTMLAnchorElement>;
    placement?: Placement;
    open: boolean;
    className?: string;
    suffix?: string;
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    hide: () => void;
};

const GroupMentionDropdown: React.FC<Props> = ({
    groupId = '',
    groupName,
    isGroupMembersViewAvailable,
    overlayRef,
    placement,
    open,
    className,
    suffix,
    onClick,
    hide,
}) => {
    const group = useSelector((state: GlobalState) => getGroup(state, groupId));
    const [memberCount, setMemberCount] = useState(group?.member_count ?? 0);

    const isMounted = useIsMounted();

    const [isInitializing, setIsInitializing] = useState(true);
    const [isScrolling, setIsScrolling] = useState(false);
    const [isFetchingMoreMembers, setIsFetchingMoreMembers] = useState(false);
    const [error, setError] = useState<GroupMentionDropdownError | null>(null);
    const [members, setMembers] = useState<User[] | null>(null);
    const [nextPage, setNextPage] = useState<number>(1);

    const scrollRef = useRef<HTMLDivElement>(null);

    const {formatMessage} = useIntl();
    const [bodyLTrue, bodyM, bodyS] = useTypography([
        {size: TypographySize.BodyLTrue},
        {size: TypographySize.BodyM},
        {size: TypographySize.BodyS},
    ]);

    const isShowMoreMembersSpinner = members && memberCount && members.length < memberCount;
    const isShowMembers = !isInitializing && members && members.length > 0;
    const isShowEmptyMembers = !isInitializing && members && members.length === 0;

    const handleError = useCallback((err: unknown) => {
        if (!isMounted()) {
            return;
        }

        if ((err instanceof ClientError) && err.status_code === AxiosError.ERR_NETWORK) {
            setError(GroupMentionDropdownError.OFFLINE);
            return;
        }

        setError(GroupMentionDropdownError.UNKNOWN);
    }, [isMounted]);

    const fetchMoreMembers = debounce(
        GET_MEMBERS_DELAY,
        () => {
            const spinnerTimeoutId = setTimeout(() => setIsFetchingMoreMembers(true), SPINNER_TIMEOUT);
            Client4.getProfilesInGroup(groupId, nextPage, MEMBERS_PER_PAGE)
                .then((newMembers) => {
                    if (!isMounted()) {
                        return;
                    }

                    setNextPage((nextPage) => nextPage + 1);
                    setMembers((m) => (m ? m.concat(newMembers) : m));
                })
                .catch(handleError)
                .finally(() => {
                    clearTimeout(spinnerTimeoutId);
                    asyncDelay(GET_MEMBERS_DELAY).then(() => isMounted() && setIsFetchingMoreMembers(false));
                });
        },
    );

    const handleScroll = () => {
        if (!scrollRef.current) {
            return;
        }

        const scrollHeight = scrollRef.current.scrollHeight || 0;
        const scrollTop = scrollRef.current.scrollTop || 0;
        const clientHeight = scrollRef.current.clientHeight || 0;

        setIsScrolling(Boolean(scrollTop));

        if (scrollTop + clientHeight >= scrollHeight - ROW_HEIGHT) {
            if (!isFetchingMoreMembers && isShowMoreMembersSpinner) {
                fetchMoreMembers();
            }
        }
    };

    const handleAnimationEnd = () => {
        if (open) {
            return;
        }

        setNextPage(1);
        setIsScrolling(false);
        setMembers(null);
        setIsInitializing(true);
        setError(null);
    };

    useEffect(() => {
        if (!open) {
            return noop;
        }

        if (!isGroupMembersViewAvailable) {
            setError(GroupMentionDropdownError.INSUFFICIENT_PERMISSION);
            return noop;
        }

        const abortController = new AbortController();

        Promise.all([
            Client4.getProfilesInGroup(groupId, 0, MEMBERS_PER_PAGE, abortController.signal),
            getGroupMemberCount(groupId, abortController.signal),
        ])
            .then(([members, {total_member_count: totalMemberCount}]) => {
                if (!isMounted()) {
                    return;
                }

                setMemberCount(totalMemberCount);
                setMembers(members);
            })
            .catch(handleError)
            .finally(() => {
                setIsInitializing(false);
            });

        return () => {
            abortController?.abort?.();
        };

    // не нужно добавлять memberCount в список зависимостей
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGroupMembersViewAvailable, groupId, isMounted, open, handleError]);

    let component;
    if (error) {
        component = (
            <span className={classNames(bodyM, styles.error)}>
                {formatMessage(groupMentionDropdownTitleMap[error], {groupName})}
            </span>
        );
    } else {
        const displayName = group?.display_name || '';

        let totalMembersCount;
        if (memberCount) {
            totalMembersCount = (
                <span
                    data-testid='membersCount'
                >
                    &nbsp;
                    {`∙ ${formatMessage(groupMentionDropdownTitleMap.members, {
                        memberCount,
                    })}`}
                </span>
            );
        }

        const groupMembers = (
            <>
                <div className={classNames(styles.divider, {[styles.divider_visible]: isScrolling})} />
                <div
                    data-testid='dropdownMembersList'
                    className={styles.list}
                    onScroll={handleScroll}
                    ref={scrollRef}
                >
                    {isInitializing && (
                        <div className={classNames(styles.fullWidth, styles.initialSpinner)}>
                            <div className={styles.center}>
                                <Spinner size={24} />
                            </div>
                        </div>
                    )}
                    {isShowMembers && (
                        <>
                            {members.map((user) => (
                                <GroupMemberRow
                                    key={user.id}
                                    user={user}
                                    hide={hide}
                                />
                            ))}
                            {isShowMoreMembersSpinner && (
                                <div className={classNames(styles.fullWidth, styles.fetchMore, styles.center)}>
                                    <Spinner size={14} />
                                </div>
                            )}
                            <div className={styles.lastPadding} />
                        </>
                    )}
                    {isShowEmptyMembers && (
                        <span className={classNames(bodyM, styles.emptyMembers)}>
                            {formatMessage(groupMentionDropdownTitleMap.emptyMembers)}
                        </span>
                    )}
                </div>
            </>
        );

        component = (
            <>
                <div className={styles.header}>
                    <h5 className={classNames(bodyLTrue, styles.overflow)}>{displayName}</h5>
                    <span className={classNames(styles.subTitle, bodyS)}>
                        <span className={styles.overflow}>@{groupName}</span>
                        <span className={styles.memberCount}>{totalMembersCount}</span>
                    </span>
                </div>
                {groupMembers}
            </>
        );
    }

    return (
        <>
            <Dropdown
                targetRef={overlayRef}
                placement={placement}
                open={open}
                offset={12}
                shouldFlip={false}
                shouldMirrorWidth={false}
                onClose={hide}
                withTransition={true}
                className={styles.dropdown}
                onAnimationEnd={handleAnimationEnd}
            >
                <div
                    data-testid='groupMentionDropdown'
                    className={styles.content}
                >
                    {component}
                </div>
            </Dropdown>

            <a
                className={className}
                onClick={onClick}
                ref={overlayRef}
            >
                {'@' + groupName}
            </a>
            {suffix}
        </>
    );
};

export default memo(GroupMentionDropdown);
