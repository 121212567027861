import {createSlice, isAnyOf} from '@reduxjs/toolkit';

import {type User} from 'features/users';
import {getProfile, getProfileIfAllowed} from '../actions/get_profile';
import {updateProfile} from '../actions/update_profile';
import {type Profile} from '../types/profile';

export type ProfilesState = Record<User['id'], Profile>;

const initialState: ProfilesState = {};

export const profilesSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(updateProfile.fulfilled, (state, action) => {
            state[action.meta.arg.user_id] = action.payload;
        });
        builder.addMatcher(isAnyOf(getProfile.fulfilled, getProfileIfAllowed.fulfilled), (state, action) => {
            state[action.meta.arg] = action.payload;
        });
    },
});
