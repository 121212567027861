import {createSelector} from 'reselect';

import type {FileInfo, FileSearchResultItem} from 'mattermost-redux/types/files';
import type {GlobalState} from 'mattermost-redux/types/store';

export function getAllFiles(state: GlobalState) {
    return state.entities.files.files;
}

export function getFileIdsByPostId(state: GlobalState) {
    return state.entities.files.fileIdsByPostId;
}

function getAllFilesFromSearch(state: GlobalState) {
    return state.entities.files.filesFromSearch;
}

export function getFilePublicLink(state: GlobalState) {
    return state.entities.files.filePublicLink;
}

export function makeGetFilesForPost(): (state: GlobalState, fileIds?: string[]) => FileInfo[] {
    return createSelector(
        getAllFiles,
        (_: GlobalState, fileIds?: string[]) => fileIds,
        (allFiles, fileIds) => {
            const fileInfos = fileIds?.reduce<FileInfo[]>((acc, id) => {
                const cur = allFiles[id];
                if (cur) {
                    acc.push(cur);
                }

                return acc;
            }, []);

            return fileInfos ?? [];
        },
    );
}

export const getSearchFilesResults: (state: GlobalState) => FileSearchResultItem[] = createSelector(
    getAllFilesFromSearch,
    (state: GlobalState) => state.entities.search.fileResults,
    (files, fileIds) => {
        if (!fileIds) {
            return [];
        }

        return fileIds.map((id) => files[id]);
    },
);

