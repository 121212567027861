import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

export const termsOfServiceSlice = createSlice({
    name: 'termsOfService',
    initialState: {
        isLoading: false,
    },
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
});

export const setIsLoading = termsOfServiceSlice.actions.setIsLoading;
