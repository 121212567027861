export enum GroupMentionDropdownError {
    INSUFFICIENT_PERMISSION = 'INSUFFICIENT_PERMISSION',
    UNKNOWN = 'UNKNOWN',
    OFFLINE = 'OFFLINE',
}

export const groupMentionDropdownTitleMap = {
    members: {
        id: 'groupMentionDropdown.members',
        defaultMessage: '{memberCount, number} {memberCount, plural, one {member} few {members} other {members}}',
    },
    emptyMembers: {
        id: 'groupMentionDropdown.emptyMembers',
        defaultMessage: 'No one is in this group',
    },
    [GroupMentionDropdownError.INSUFFICIENT_PERMISSION]: {
        id: 'groupMentionDropdown.error.notSufficientPermission',
        defaultMessage: 'You don\'t have enough rights to view the members of the group @{groupName}',
    },
    [GroupMentionDropdownError.UNKNOWN]: {
        id: 'groupMentionDropdown.error.unknown',
        defaultMessage: 'Something went wrong, try again later',
    },
    [GroupMentionDropdownError.OFFLINE]: {
        id: 'groupMentionDropdown.error.offline',
        defaultMessage: 'No internet connection, check your connection and try again',
    },
};

export const SPINNER_TIMEOUT = 300;

export const GET_MEMBERS_DELAY = 500;

export const ROW_HEIGHT = 36;

export const MEMBERS_PER_PAGE = 20;

export const MAX_DESCRIPTION_LENGTH = 20;
