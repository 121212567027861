import React, {} from 'react';
import type {SuggestionProps} from '@tiptap/suggestion';

import {TildeIcon} from '@time-webkit/all/icons/tilde';
import {LockOutlineIcon} from '@time-webkit/all/icons/lock-outline';
import classNames from 'classnames';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {sendToStatist} from '@time-webkit/statist';

import type {ChannelMention} from '../../types';

import {MentionList} from '../../../../components/mention-list';

import styles from './styles.module.css';

type MentionListProps = {
    getMentions: (query: string) => Promise<ChannelMention[]>;
} & Pick<SuggestionProps<ChannelMention>, 'query' | 'command' | 'decorationNode'>;

const Description: React.FunctionComponent = (props) => {
    const typographyCls = useTypography({
        size: TypographySize.BodyS,
        noDefaultColor: true,
    });

    return <span className={classNames(styles.description, typographyCls)}>{props.children}</span>;
};

const Handle: React.FunctionComponent = (props) => {
    const typographyCls = useTypography({
        size: TypographySize.BodyL,
    });

    return <span className={classNames(styles.handle, typographyCls)}>{props.children}</span>;
};

const ChannelOption = (props: ChannelMention) => {
    const isPrivateChannelOption = props.type === 'P';
    return (
        <div className={styles.mentionWrapper}>
            <span className={classNames(styles.icon, styles.groupIcon)}>
                {isPrivateChannelOption ? <LockOutlineIcon /> : <TildeIcon />}
            </span>
            <Handle>{props.displayName}</Handle>
            <Description>{`~${props.handle}`}</Description>
        </div>
    );
};

/* 8px top padding, 4 list items and just enough of 5th */
const LIST_MAX_HEIGHT = 8 + (36 * 4) + (36 * 0.6);

export const ChannelMentionList: React.VoidFunctionComponent<MentionListProps> = (props) => {
    const {query, getMentions, command, decorationNode} = props;
    const onSelect = (_: ChannelMention, index: number) => {
        sendToStatist('editor.searchChannel.resultTap', {
            resultNumber: index + 1,
        });
    };

    return (
        <MentionList
            query={query}
            command={command}
            decorationNode={decorationNode}
            getMentions={getMentions}
            renderMentionOption={(item) => <ChannelOption {...item} />}
            onSelect={onSelect}
            maxHeight={LIST_MAX_HEIGHT}
            ignoreEmptyQuery={false}
        />
    );
};
