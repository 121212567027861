import * as DateFNS from 'date-fns';
import * as DateTZ from 'date-fns-tz';
import {enUS} from 'date-fns/locale';

import type {Day} from 'date-fns';

const shouldTruncate = new Map<Intl.RelativeTimeFormatUnit, boolean>([
    ['year', true],
    ['quarter', true],
    ['month', true],
    ['week', true],
    ['day', true],
    ['hour', false],
    ['minute', false],
    ['second', true],
]);

export function add(a: Date | string | number, n: number, duration: DateFNS.DurationUnit) {
    return DateFNS.add(a, {[duration]: n});
}

export function subtract(a: Date | string | number, n: number, duration: DateFNS.DurationUnit) {
    return DateFNS.sub(a, {[duration]: n});
}

// isEqual и isWithin - функции которые используются только в timestamp
export function isEqual(
    a: Date,
    b: Date,
    timeZone: string = new Intl.DateTimeFormat().resolvedOptions().timeZone,
    unit: Intl.RelativeTimeFormatUnit,
    threshold = 1,
    truncateEndpoints = shouldTruncate.get(unit) || false,
): boolean {
    return threshold === getDiff(a, b, timeZone, unit, truncateEndpoints);
}

export function isWithin(
    a: Date,
    b: Date,
    timeZone: string = new Intl.DateTimeFormat().resolvedOptions().timeZone,
    unit: Intl.RelativeTimeFormatUnit,
    threshold = 1,
    truncateEndpoints = shouldTruncate.get(unit) || false,
): boolean {
    const diff = getDiff(a, b, timeZone, unit, truncateEndpoints);
    return threshold >= 0 ? diff <= threshold && diff >= 0 : diff >= threshold && diff <= 0;
}

export function isSame(a: Date | string | number, b: Date | string | number) {
    return DateFNS.isEqual(a, b);
}

export function isAfter(a: Date | string | number, b: Date | string | number) {
    return DateFNS.isAfter(a, b);
}

export function isBefore(a: Date | string | number, b: Date | string | number) {
    return DateFNS.isBefore(a, b);
}

export function isWithinLastWeek(a: Date | string | number): boolean {
    return DateFNS.isWithinInterval(a, {start: DateFNS.sub(Date.now(), {weeks: 1}), end: Date.now()});
}

export function isSameDay(a: Date | string | number, b: Date | string | number): boolean {
    return DateFNS.isSameDay(a, b);
}

export function isSameDayOfMonth(a: Date | string | number, b: Date | string | number): boolean {
    // проверка на число месяца 1..31
    return DateFNS.getDate(a) === DateFNS.getDate(b);
}

export function isSameYear(a: Date | string | number, b: Date | string | number): boolean {
    return DateFNS.isSameYear(a, b);
}

export function isSameOrAfter(a: Date | string | number, b: Date | string | number, timezone?: string) {
    if (timezone) {
        const zonedA = getDateTimeForTimezone(a, timezone);
        const zonedB = getDateTimeForTimezone(b, timezone);
        return DateFNS.isEqual(zonedA, zonedB) || DateFNS.isAfter(zonedA, zonedB);
    }
    return DateFNS.isEqual(a, b) || DateFNS.isAfter(a, b);
}

export function isYesterday(date: Date | string | number): boolean {
    return DateFNS.isYesterday(date);
}

export function getDiff(
    a: Date | string | number,
    b: Date | string | number,
    timeZone: string = new Intl.DateTimeFormat().resolvedOptions().timeZone,
    unit: Intl.RelativeTimeFormatUnit,
    truncateEndpoints = shouldTruncate.get(unit) || false,
): number {
    let diffFn = null;
    switch (unit) {
    case 'year':
    case 'years':
        diffFn = DateFNS.differenceInYears;
        break;
    case 'quarter':
    case 'quarters':
        diffFn = DateFNS.differenceInQuarters;
        break;
    case 'month':
    case 'months':
        diffFn = DateFNS.differenceInMonths;
        break;
    case 'week':
    case 'weeks':
        diffFn = DateFNS.differenceInWeeks;
        break;
    case 'day':
    case 'days':
        diffFn = DateFNS.differenceInDays;
        break;
    case 'hour':
    case 'hours':
        diffFn = DateFNS.differenceInHours;
        break;
    case 'minute':
    case 'minutes':
        diffFn = DateFNS.differenceInMinutes;
        break;
    case 'second':
    case 'seconds':
        diffFn = DateFNS.differenceInSeconds;
        break;
    default:
        throw new Error(`Unsupported value of unit - ${unit}`);
    }

    if (timeZone) {
        const zonedA = DateTZ.toZonedTime(a, timeZone);
        const zonedB = DateTZ.toZonedTime(b, timeZone);
        return truncateEndpoints ? diffFn(startOf(zonedA, unit), startOf(zonedB, unit)) : diffFn(zonedA, zonedB);
    }
    return truncateEndpoints ? diffFn(startOf(a, unit), startOf(b, unit)) : diffFn(a, b);
}

export function startOf(a: Date | string | number, duration: Intl.RelativeTimeFormatUnit): Date {
    switch (duration) {
    case 'year':
    case 'years':
        return DateFNS.startOfYear(a);
    case 'quarter':
    case 'quarters':
        return DateFNS.startOfQuarter(a);
    case 'month':
    case 'months':
        return DateFNS.startOfMonth(a);
    case 'week':
    case 'weeks':
        return DateFNS.startOfWeek(a);
    case 'day':
    case 'days':
        return DateFNS.startOfDay(a);
    case 'hour':
    case 'hours':
        return DateFNS.startOfHour(a);
    case 'minute':
    case 'minutes':
        return DateFNS.startOfMinute(a);
    case 'second':
    case 'seconds':
        return DateFNS.startOfSecond(a);
    default:
        return new Date(a);
    }
}

export function endOf(a: Date | string | number, duration: Intl.RelativeTimeFormatUnit): Date {
    switch (duration) {
    case 'year':
    case 'years':
        return DateFNS.endOfYear(a);
    case 'quarter':
    case 'quarters':
        return DateFNS.endOfQuarter(a);
    case 'month':
    case 'months':
        return DateFNS.endOfMonth(a);
    case 'week':
    case 'weeks':
        return DateFNS.endOfWeek(a);
    case 'day':
    case 'days':
        return DateFNS.endOfDay(a);
    case 'hour':
    case 'hours':
        return DateFNS.endOfHour(a);
    case 'minute':
    case 'minutes':
        return DateFNS.endOfMinute(a);
    case 'second':
    case 'seconds':
        return DateFNS.endOfSecond(a);
    default:
        return new Date(a);
    }
}

export function setDateSecondsAndMillisecondsToZero(a: Date | string | number): Date {
    return DateFNS.set(a, {seconds: 0, milliseconds: 0});
}

export function toUTCUnix(a: Date | string | number): number {
    return DateFNS.getUnixTime(a);
}

export function getBrowserTimezone(): string {
    // eslint-disable-next-line
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getBrowserUtcOffset(): number {
    const browserTimezone = getBrowserTimezone();
    return DateTZ.getTimezoneOffset(browserTimezone);
}

export function getBrowserUtcOffsetMinutes(): number {
    const browserTimezone = getBrowserTimezone();
    return DateTZ.getTimezoneOffset(browserTimezone) / 1000 / 60;
}

export function getTimezoneOffset(timezone: string, date?: number | Date): number {
    return DateTZ.getTimezoneOffset(timezone, date);
}

export function getTimezoneOffsetMinutes(timezone: string, date?: number | Date): number {
    return DateTZ.getTimezoneOffset(timezone, date) / 1000 / 60;
}

export function getCurrentDateTimeForTimezone(timezone?: string): Date {
    if (timezone) {
        const now = new Date();
        return DateFNS.set(DateTZ.toZonedTime(now, timezone), {milliseconds: 0});
    }
    return DateFNS.set(new Date(), {milliseconds: 0});
}

export function getDateTimeForTimezone(date: Date | string | number, timezone?: string): Date {
    if (timezone) {
        return DateTZ.toZonedTime(date, timezone);
    }
    return new Date(date);
}

export function daysToDate(a: Date | string | number): number {
    const startA = startOf(a, 'day');
    const startNow = startOf(new Date(), 'day');

    return DateFNS.differenceInDays(startA, startNow);
}

export function getNextBillingDate() {
    const now = new Date();
    const nextBillingDate = startOf(DateFNS.add(now, {months: 1}), 'month');
    return DateFNS.format(nextBillingDate, 'MMM d, yyyy');
}

// returns Unix timestamp in milliseconds
export function getTimestamp(): number {
    return Date.now();
}

export function getDateForUnixTicks(ticks: number): Date {
    return new Date(ticks);
}

export function getRemainingDaysFromFutureTimestamp(timestamp: number): number {
    const MS_PER_DAY = 24 * 60 * 60 * 1000;
    const futureDate = new Date(timestamp);
    const utcFuture = Date.UTC(futureDate.getFullYear(), futureDate.getMonth(), futureDate.getDate());
    const today = new Date();
    const utcToday = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

    return Math.floor((utcFuture - utcToday) / MS_PER_DAY);
}

export function getRoundedTime(time: Date | string | number, roundedTo: number, duration: Intl.RelativeTimeFormatUnit): Date {
    const startMinutes = DateFNS.getMinutes(time);
    const diff = startMinutes % roundedTo;
    if (diff === 0) {
        return new Date(time);
    }
    const remainder = roundedTo - diff;

    return setDateSecondsAndMillisecondsToZero(DateFNS.add(time, {[duration]: remainder}));
}

export function formatDate(date: Date | string | number, format: string) {
    return DateFNS.format(date, format);
}

export function formatDateTimeWithTimezone(date: Date | string | number, timezone?: string, format?: string): string {
    if (timezone) {
        const zoned = DateTZ.formatInTimeZone(date, timezone, format || "iii MMM dd yyyy HH:mm:ss 'GMT'xxxx");
        return zoned + (timezone ? ` (${timezone})` : '');
    }

    return formatDate(date, format || "iii MMM dd yyyy HH:mm:ss 'GMT'xxxx");
}

export const formatWeekdayByNumber = (weekdayNumber: Day, width: 'wide' | 'short') => {
    const dateFnsLocale = DateFNS.getDefaultOptions().locale || enUS;
    return dateFnsLocale.localize.day(weekdayNumber, {width});
};

export function getTimezonesList(): string[] {
    return Intl.supportedValuesOf('timeZone');
}

export function getTimezoneLabel(timezone: string): string {
    const now = new Date();
    const offset = DateTZ.formatInTimeZone(now, timezone, 'xxx');

    return `(UTC${offset}) ${timezone}`;
}

export const getFirstDayOfWeek = () => {
    const dateFnsLocale = DateFNS.getDefaultOptions().locale || enUS;
    return dateFnsLocale.options?.weekStartsOn || 0;
};
