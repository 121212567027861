import {useRef} from 'react';
import {useButton} from '@react-aria/button';

import {FocusRing} from '@react-aria/focus';
import {mergeProps} from '@react-aria/utils';
import {useHover} from '@react-aria/interactions';

import classNames from 'classnames';

import {Spinner} from '../../spinner';

import type {Props} from './types';
import {BUTTON_SIZE, BUTTON_VARIANT} from './constants';
import {useTextClass} from './use-text-class';
import styles from './styles.module.css';
import {useSpinnerSize} from './use_button_size';

export const Button: React.FunctionComponent<Props> = (props) => {
    const {
        size = BUTTON_SIZE.MEDIUM,
        variant = BUTTON_VARIANT.PRIMARY,
        isDisabled = false,
        destructive = false,
        inverted = false,
        loading = false,
        autoFocus,
        className,
        block,
        buttonRef,
        style,
    } = props;

    const innerRef = useRef(null);

    const ref = buttonRef || innerRef;

    const {buttonProps, isPressed} = useButton(props, ref);
    const {hoverProps, isHovered} = useHover({isDisabled});

    const textCls = useTextClass(size);

    const spinnerSize = useSpinnerSize(size);

    return (
        <FocusRing focusRingClass={styles.focused} autoFocus={autoFocus}>
            <button
                disabled={isDisabled}
                type='button' ref={ref} {...mergeProps(buttonProps, hoverProps)} className={classNames(className,
                    styles.base,
                    styles[size],
                    styles[variant],
                    inverted && styles.inverted,
                    destructive && styles.destructive,
                    isPressed && styles.active,
                    isHovered && styles.hover,
                    isDisabled && styles.disabled,
                    loading && styles.loading,
                    block && styles.block,
                )}
                style={style}
            >
                {loading && (
                    <span className={styles.spinner} >
                        <Spinner size={spinnerSize} color='currentColor' />
                    </span>
                )}
                <span className={textCls}>{props.children}</span>
            </button>
        </FocusRing>
    );
};
