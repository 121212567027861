import {createSlice, type PayloadAction} from '@reduxjs/toolkit';

export const appVisibilitySlice = createSlice({
    name: 'appVisibility',
    initialState: {
        visible: false,
        focused: false,
    },
    reducers: {
        changeAppVisibility(state, action: PayloadAction<boolean>) {
            state.visible = action.payload;
        },
        changeAppFocus(state, action: PayloadAction<boolean>) {
            state.focused = action.payload;
        },
    },
});

export const changeAppVisibility = appVisibilitySlice.actions.changeAppVisibility;
export const changeAppFocus = appVisibilitySlice.actions.changeAppFocus;
