import React from 'react';
import type {SuggestionProps} from '@tiptap/suggestion';

import classNames from 'classnames';

import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {sendToStatist} from '@time-webkit/statist';

import HashTagIcon from 'components/widgets/icons/hash_tag_icon';

import {MentionList} from 'packages/editor/src/components/mention-list';

import styles from './styles.module.css';

type MentionListProps = {
    getMentions: (query: string) => Promise<string[]>;
} & Pick<SuggestionProps<string>, 'query' | 'command' | 'decorationNode'>;

const Handle: React.FunctionComponent = (props) => {
    const typographyCls = useTypography({
        size: TypographySize.BodyL,
    });

    return <span className={classNames(styles.handle, typographyCls)}>{props.children}</span>;
};

const HashtagOption = ({hashtag}: {hashtag: string}) => {
    return (
        <div className={styles.mentionWrapper}>
            <span className={classNames(styles.icon, styles.groupIcon)}>
                <HashTagIcon size={20} />
            </span>
            <Handle>{hashtag.slice(1)}</Handle>
        </div>
    );
};

/* 8px top padding, 4 list items and just enough of 5th */
const LIST_MAX_HEIGHT = 8 + (36 * 4) + (36 * 0.6);

export const HashtagMentionList: React.VoidFunctionComponent<MentionListProps> = (props) => {
    const {query, getMentions, command, decorationNode} = props;
    const onSelect = (_: {handle: string}, index: number) => {
        sendToStatist('editor.searchHashtag.resultTap', {
            resultNumber: index + 1,
        });
    };
    const onGetMentions = async (query: string) => {
        const mentions = await getMentions(query);
        return mentions.map((m) => ({handle: m}));
    };

    return (
        <MentionList
            query={query}
            command={(mention) => command(mention.handle)}
            decorationNode={decorationNode}
            getMentions={onGetMentions}
            renderMentionOption={(item) => <HashtagOption hashtag={item.handle} />}
            onSelect={onSelect}
            maxHeight={LIST_MAX_HEIGHT}
        />
    );
};
