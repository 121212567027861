import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

import {Client4} from 'mattermost-redux/client';

import {
    AbortedError,
    CanceledError,
    InvalidRequestError,
    NetworkError,
    NotAuthorizedError,
    NoPermissionError,
    RequestError,
    TimedOutOnClientError,
    TimedOutOnServerError,
    UnavailableError,
} from './errors';

Client4.requester.defaults.withCredentials = true;

export async function makeGetRequest<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config: AxiosRequestConfig<never> = {},
): Promise<R> {
    try {
        return await Client4.requester.get(url, config);
    } catch (e) {
        const error = e as AxiosError;

        if (!error.isAxiosError) {
            throw e;
        }

        const errorJSON = error.toJSON() as any;
        const stack = error.stack;

        if (error.status === 400) {
            throw new InvalidRequestError(errorJSON, stack);
        }

        if (error.status === 401) {
            throw new NotAuthorizedError(errorJSON, stack);
        }

        if (error.status === 403) {
            throw new NoPermissionError(errorJSON, stack);
        }

        /**
         * ALB cancels request
         */
        if (error.status === 408) {
            throw new TimedOutOnServerError(errorJSON, stack);
        }

        if (error.status === 503) {
            throw new UnavailableError(errorJSON, stack);
        }

        if (error.code === AxiosError.ERR_NETWORK) {
            throw new NetworkError(errorJSON, stack);
        }

        if (error.code === AxiosError.ECONNABORTED) {
            throw new AbortedError(errorJSON, stack);
        }

        if (error.code === AxiosError.ETIMEDOUT) {
            throw new TimedOutOnClientError(errorJSON, stack);
        }

        if (error.code === AxiosError.ERR_CANCELED) {
            throw new CanceledError(errorJSON, stack);
        }

        throw new RequestError(errorJSON, stack);
    }
}

export function makePostRequest<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
): Promise<R> {
    return Client4.requester.post(url, data, config);
}

export function makePostFormRequest<T = unknown, R = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
): Promise<R> {
    return Client4.requester.postForm(url, data, config);
}
