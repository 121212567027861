import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

import {getEmojiImageUrl} from 'mattermost-redux/utils/emoji_utils';

import {getEmojiMap} from 'selectors/emojis';

interface ComponentProps {
    name: string;
    size?: number;
    style?: React.CSSProperties;
    onClick?: () => void;
    preview?: boolean;
}

const Emoji = ({name, style, size, onClick, preview}: ComponentProps) => {
    const emojiMap = useSelector(getEmojiMap);

    if (!name) {
        return null;
    }

    const emojiFromMap = emojiMap.get(name);

    if (!emojiFromMap) {
        return null;
    }
    const emojiImageUrl = getEmojiImageUrl(emojiFromMap);
    const emojiSize = size || (preview ? 64 : 16);

    const emoji = (
        <span
            onClick={onClick}
            className={classNames('emoticon', {'emoticon--preview': preview})}
            alt={`:${name}:`}
            data-emoticon={name}
            style={{
                backgroundImage: `url(${emojiImageUrl})`,
                backgroundSize: emojiSize,
                height: emojiSize,
                width: emojiSize,
                maxHeight: emojiSize,
                maxWidth: emojiSize,
                minHeight: emojiSize,
                minWidth: emojiSize,
                overflow: 'hidden',
                ...style,
            }}
        />
    );

    if (!preview) {
        return emoji;
    }

    return (
        <span className='emoticon-preview-container'>
            {emoji}
        </span>
    );
};

Emoji.defaultProps = {
    emojiName: '',
    emojiStyle: {},
};

export default React.memo(Emoji);
