import ReactDayPickerInput from 'react-day-picker/DayPickerInput';
import type {DayPickerInputProps, DayPickerProps} from 'react-day-picker';

import React from 'react';

import {LocaleUtils} from './utils';

export type Props = Omit<DayPickerInputProps, 'dayPickerProps'> & {
    dayPickerProps: Omit<DayPickerProps, 'localeUtils' | 'locale'>;
}

export const DayPickerInput: React.FC<Props> = (props) => {
    return (
        <ReactDayPickerInput
            {...props}
            dayPickerProps={{
                ...props.dayPickerProps,
                localeUtils: LocaleUtils,
            }}
        />
    );
};
