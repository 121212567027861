import React, {} from 'react';
import type {SuggestionProps} from '@tiptap/suggestion';

import {Avatar} from '@time-webkit/all/atoms/avatar';
import {AccountMultipleIcon} from '@time-webkit/all/icons/account-multiple-outline';
import classNames from 'classnames';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {sendToStatist} from '@time-webkit/statist';

import {MentionList} from '../../../../components/mention-list';

import type {GroupMention, Mention, UserMention} from '../../types';

import styles from './styles.module.css';

type MentionListProps = {
    getMentions: (query: string) => Promise<Mention[]>;
} & Pick<SuggestionProps<Mention>, 'query' | 'command' | 'decorationNode'>;

const Description: React.FunctionComponent = (props) => {
    const typographyCls = useTypography({
        size: TypographySize.BodyS,
        noDefaultColor: true,
    });

    return <span className={classNames(styles.description, typographyCls)}>{props.children}</span>;
};

const Handle: React.FunctionComponent = (props) => {
    const typographyCls = useTypography({
        size: TypographySize.BodyL,
    });

    return <span className={classNames(styles.handle, typographyCls)}>{props.children}</span>;
};

const UserOption = (props: UserMention) => {
    const hasNameDescription = props.displayName !== props.handle;
    return (
        <div className={styles.mentionWrapper}>
            <Avatar
                className={styles.icon}
                src={props.imageUrl}
                text={props.displayName}
                username={props.handle}
                size={20}
                inline={true}
            />
            <Handle>{`@${props.handle}`}</Handle>
            {hasNameDescription ? <Description>{props.displayName}</Description> : null}
        </div>
    );
};

const GroupOption = (props: GroupMention) => {
    const hasNameDescription = props.displayName !== props.handle;
    return (
        <div className={styles.mentionWrapper}>
            <span className={classNames(styles.icon, styles.groupIcon)}>
                <AccountMultipleIcon />
            </span>
            <Handle>{`@${props.handle}`}</Handle>
            {hasNameDescription ? <Description>{props.displayName}</Description> : null}
        </div>
    );
};

const MentionOption = (props: Mention) => {
    const displayName = props.displayName;

    if (props.type === 'user') {
        return <UserOption {...props} />;
    }

    if (props.type === 'group') {
        return <GroupOption {...props} />;
    }

    if (props.type === 'custom') {
        const groupProps = props as unknown as GroupMention;
        return <GroupOption {...groupProps} />;
    }

    return <span>{displayName}</span>;
};

/* 8px top padding, 4 list items and just enough of 5th */
const LIST_MAX_HEIGHT = 8 + (36 * 4) + (36 * 0.6);

export const UserMentionsList: React.VoidFunctionComponent<MentionListProps> = (props) => {
    const {query, getMentions, command, decorationNode} = props;
    const onSelect = (_: Mention, index: number) => {
        sendToStatist('editor.searchUser.resultTap', {
            resultNumber: index + 1,
        });
    };

    return (
        <MentionList
            query={query}
            command={command}
            decorationNode={decorationNode}
            getMentions={getMentions}
            renderMentionOption={(item) => <MentionOption {...item} />}
            onSelect={onSelect}
            maxHeight={LIST_MAX_HEIGHT}
            ignoreEmptyQuery={false}
        />
    );
};
