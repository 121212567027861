// ported from https://gitlab.tcsbank.ru/messenger/time-server/-/blob/f7b9c85e39adc2156cb998758fccce78edf8de15/model/search_params.go#L125

const whitespaceRegexp = /(\s+)/;

export const splitWords = (text: string): string[] => {
    const words: string[] = [];

    let foundQuote = false;
    let location = 0;
    for (let idx = 0; idx < text.length; idx++) {
        if (text[idx] === '"') {
            if (foundQuote) {
                // Grab the quoted section
                const word = text.slice(location, idx + 1);
                words.push(word);
                foundQuote = false;
                location = idx + 1;
            } else {
                let nextStart = idx;
                if (idx > 0 && text[idx - 1] === '-') {
                    nextStart = idx - 1;
                }
                const substring = text.slice(location, nextStart);
                const splitWords = substring.split(whitespaceRegexp).filter((e) => e.trim().length > 0);
                words.push(...splitWords);
                foundQuote = true;
                location = nextStart;
            }
        }
    }
    const rest = text.slice(location);
    const splitWords = rest.split(whitespaceRegexp).filter((e) => e.trim().length > 0);
    words.push(...splitWords);
    return words;
};
