import React from 'react';
import {Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';

import {checkDialogElementForError, checkIfErrorsMatchElements} from 'mattermost-redux/utils/integration_utils';

import SpinnerButton from 'components/spinner_button';

import {localizeMessage} from 'utils/utils';

import type {DialogSubmission, InteractiveDialogConfig, SubmitDialogResponse} from '@mattermost/types/integrations';

import type EmojiMap from 'utils/emoji_map';

import {ServerErrorIds} from 'utils/constants';

import DialogElement from './dialog_element';
import DialogIntroductionText from './dialog_introduction_text';

function hasOwnProperty(obj: Record<string, any>, prop: string) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}

type Props = {
    channelId: string;
    url: InteractiveDialogConfig['url'];
    callbackId?: InteractiveDialogConfig['dialog']['callback_id'];
    elements?: InteractiveDialogConfig['dialog']['elements'];
    title: InteractiveDialogConfig['dialog']['title'];
    introductionText?: InteractiveDialogConfig['dialog']['introduction_text'];
    iconUrl?: InteractiveDialogConfig['dialog']['icon_url'];
    submitLabel?: InteractiveDialogConfig['dialog']['submit_label'];
    notifyOnCancel?: InteractiveDialogConfig['dialog']['notify_on_cancel'];
    state?: InteractiveDialogConfig['dialog']['state'];
    emojiMap: EmojiMap;
    actions: {
        submitInteractiveDialog: (dialog: DialogSubmission) => Promise<{data: SubmitDialogResponse}>;
    };
    onExited?: () => void;
};

type State = {
    show: boolean;
    values: Record<string, boolean | string | undefined>;
    defaultValues: Record<string, boolean | string | undefined>;
    error: any;
    errors: Record<string, any>;
    submitting: boolean;
};

// eslint-disable-next-line react/require-optimization
export default class InteractiveDialog extends React.Component<Props, State> {
    state = {
        show: true,
        error: null,
        errors: {},
        submitting: false,
        values: {},
        defaultValues: {},
    } as State;

    static getDerivedStateFromProps(props: Props, state: State) {
        if (!props.elements) {
            return null;
        }

        const defaultValues = props.elements.reduce((acc, e) => {
            if (hasOwnProperty(state, e.name)) {
                return acc;
            }

            if (e.type === 'bool') {
                const isDefaultChecked = e.default === true || String(e.default).toLowerCase() === 'true';

                acc[e.name] = isDefaultChecked;
                return acc;
            }

            acc[e.name] = e.default || undefined;

            return acc;
        }, {} as State['values']);

        return {
            ...state,
            defaultValues,
        };
    }

    getFieldValues() {
        const values: Record<string, any> = {};
        this.props.elements?.forEach((element) => {
            const fieldName = element.name;
            if (hasOwnProperty(this.state.values, fieldName)) {
                values[fieldName] = this.state.values[fieldName];
                return;
            }

            values[fieldName] = this.state.defaultValues[fieldName];
        });

        return values;
    }

    handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const {elements} = this.props;
        const values = this.getFieldValues();
        const errors: State['errors'] = {};
        if (elements) {
            elements.forEach((elem) => {
                const error = checkDialogElementForError(elem, values[elem.name]);

                if (error) {
                    errors[elem.name] = (
                        <FormattedMessage
                            id={error.id}
                            defaultMessage={error.defaultMessage}
                            values={error.values}
                        />
                    );
                }
            });
        }

        this.setState({errors});

        if (Object.keys(errors).length !== 0) {
            return;
        }

        const {url, callbackId, state} = this.props;

        const dialog = {
            url,
            callback_id: callbackId,
            state,
            submission: values,
            channel_id: this.props.channelId,
        } as DialogSubmission;

        this.setState({submitting: true});

        const {data} = await this.props.actions.submitInteractiveDialog(dialog);

        this.setState({submitting: false});

        let hasErrors = false;

        if (data) {
            if (data.error) {
                hasErrors = true;
                this.setState({error: data.error});
            }

            if (
                data.errors &&
                Object.keys(data.errors).length >= 0 &&
                checkIfErrorsMatchElements(data.errors as Record<string, any>, elements)
            ) {
                hasErrors = true;
                this.setState({errors: data.errors});
            }
        }

        if (!hasErrors) {
            this.handleHide(true);
        }
    };

    onHide = () => {
        this.handleHide(false);
    };

    handleHide = (submitted = false) => {
        const {url, callbackId, state, notifyOnCancel} = this.props;

        if (!submitted && notifyOnCancel) {
            const dialog = {
                url,
                callback_id: callbackId,
                state,
                cancelled: true,
                channel_id: this.props.channelId,
            } as DialogSubmission;

            this.props.actions.submitInteractiveDialog(dialog);
        }

        this.setState({show: false});
    };

    onChange = (name: string, value: any) => {
        this.setState((state) => ({
            ...state,
            values: {
                ...state.values,
                [name]: value,
            },
        }));
    };

    getFieldValue = (fieldName: string) => {
        if (hasOwnProperty(this.state.values, fieldName)) {
            return this.state.values[fieldName];
        }

        return this.state.defaultValues[fieldName];
    };

    localizeErrorMessage(errorMessage = '') {
        switch (errorMessage) {
        case ServerErrorIds.LICENSE_LIMIT:
            return localizeMessage(
                'interactive_dialog.errors.licenseLimit',
                'Failed to create an account. No licenses available — contact support service',
            );
        case ServerErrorIds.BOT_CREATE_DISABLED:
            return localizeMessage(
                'interactive_dialog.errors.botCreationDisabled',
                'Failed to create an account. Creation is disabled — contact support service',
            );
        case ServerErrorIds.BOT_USERNAME_INVALID:
            return localizeMessage(
                'bot.add.username.invalid',
                'Invalid bot name',
            );
        case ServerErrorIds.USER_EMAIL_EXISTS:
            return localizeMessage(
                'interactive_dialog.errors.emailExists',
                'The entered email already exists',
            );
        case ServerErrorIds.USER_USERNAME_EXISTS:
            return localizeMessage(
                'interactive_dialog.errors.usernameExists',
                'The entered name already exists',
            );
        case ServerErrorIds.USER_EXISTS:
        case ServerErrorIds.USER_SAVE_APP_ERROR:
        case ServerErrorIds.USERPROFILE_SAVE_APP_ERROR:
        case ServerErrorIds.USER_GET_APP_ERROR:
        case ServerErrorIds.BOT_CREATE_FAILED:
            return localizeMessage(

                // @TODO: использовать общую ошибку как только обновится перевод https://jira.tcsbank.ru/browse/TIME-7490
                'admin.channel_settings.channel_list.search_channels_errored',
                'Something went wrong. Try again',
            );
        default:
            return errorMessage;
        }
    }

    get renderError() {
        if (!this.state.error) {
            return null;
        }

        return <div className='error-text'>{this.localizeErrorMessage(this.state.error)}</div>;
    }

    render() {
        const {title, introductionText, iconUrl, submitLabel, elements} = this.props;

        let submitText: any = (
            <FormattedMessage
                id='interactive_dialog.submit'
                defaultMessage='Submit'
            />
        );
        if (submitLabel) {
            submitText = submitLabel;
        }

        let icon;
        if (iconUrl) {
            icon = (
                <img
                    id='interactiveDialogIconUrl'
                    alt={'modal title icon'}
                    className='more-modal__image'
                    width='36'
                    height='36'
                    src={iconUrl}
                />
            );
        }

        return (
            <Modal
                id='interactiveDialogModal'
                dialogClassName='a11y__modal'
                show={this.state.show}
                onHide={this.onHide}
                onExited={this.props.onExited}
                backdrop='static'
                role='dialog'
                aria-labelledby='interactiveDialogModalLabel'
            >
                <form
                    onSubmit={this.handleSubmit}
                    autoComplete={'off'}
                >
                    <Modal.Header
                        closeButton={true}
                        style={{borderBottom: elements ? undefined : '0px'}}
                    >
                        <Modal.Title
                            componentClass='h1'
                            id='interactiveDialogModalLabel'
                        >
                            {icon}
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    {(elements || introductionText) && (
                        <Modal.Body>
                            {introductionText && (
                                <DialogIntroductionText
                                    id='interactiveDialogModalIntroductionText'
                                    value={introductionText}
                                    emojiMap={this.props.emojiMap}
                                />
                            )}
                            {elements &&
                                elements.map((e, index) => {
                                    return (
                                        <DialogElement
                                            autoFocus={index === 0}
                                            key={'dialogelement' + e.name}
                                            displayName={e.display_name}
                                            channelId={this.props.channelId}
                                            name={e.name}
                                            type={e.type}
                                            subtype={e.subtype}
                                            helpText={e.help_text}
                                            errorText={this.state.errors[e.name]}
                                            placeholder={e.placeholder}
                                            minLength={e.min_length}
                                            maxLength={e.max_length}
                                            dataSource={e.data_source}
                                            optional={e.optional}
                                            options={e.options}
                                            value={this.getFieldValue(e.name)}
                                            onChange={this.onChange}
                                        />
                                    );
                                })}
                        </Modal.Body>
                    )}
                    <Modal.Footer>
                        {this.renderError}
                        <button
                            id='interactiveDialogCancel'
                            type='button'
                            className='btn btn-link cancel-button'
                            onClick={this.onHide}
                        >
                            <FormattedMessage
                                id='interactive_dialog.cancel'
                                defaultMessage='Cancel'
                            />
                        </button>
                        <SpinnerButton
                            id='interactiveDialogSubmit'
                            type='submit'
                            autoFocus={!elements || elements.length === 0}
                            className='btn btn-primary save-button'
                            spinning={this.state.submitting}
                            spinningText={localizeMessage('interactive_dialog.submitting', 'Submitting...')}
                        >
                            {submitText}
                        </SpinnerButton>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}
