import type {JSONContent} from '@tiptap/core';

import {TimeEditoHashtagMentionExtension} from 'features/hashtags/extension';

import {TimeEditorSlashCommandExtension} from './extensions/slash-command';
import {TimeWebkitEmojiExtension} from './extensions/emoji-search';
import {TimeEditorMentionExtension} from './extensions/mention';
import {TimeEditorChannelMentionExtension} from './extensions/channel';

export const NO_TEXT_CONTENT_EXTENSIONS = [
    TimeWebkitEmojiExtension.name,
    TimeEditorMentionExtension.name,
    TimeEditorChannelMentionExtension.name,
    TimeEditoHashtagMentionExtension.name,
    TimeEditorSlashCommandExtension.name,
];

export function isContentBlockEmpty(block?: JSONContent): boolean {
    if (!block || !block.type) {
        return true;
    }

    if (NO_TEXT_CONTENT_EXTENSIONS.includes(block.type)) {
        return false;
    }

    if ('text' in block) {
        return !block.text?.trim();
    }

    return block.content ? block.content.every((_block) => isContentBlockEmpty(_block)) : true;
}
