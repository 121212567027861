export enum LEVEL {
    ONE = 'one',
    TWO = 'two',
    THREE = 'three',
    FOUR = 'four',
    FIVE = 'five',
    SIX = 'six',
}

export enum THEME {
    DAY = 'day',
    NIGHT = 'night',
}
