import isEqual from '@tinkoff/utils/is/equal';
import pick from '@tinkoff/utils/object/pick';

import {type Draft} from '../types';

const equalKeys: Array<keyof Draft> = ['draftId', 'fileInfos', 'message', 'props', 'quote'];

export function areDraftsEqual(firstDraft: Draft, secondDraft: Draft) {
    return isEqual(pick(equalKeys, firstDraft), pick(equalKeys, secondDraft));
}

export function makeEmptyDraft(props: Pick<Draft, 'draftId' | 'teamId' | 'channelId' | 'userId' | 'type'>): Draft {
    return {
        ...props,
        message: '',
        fileInfos: [],
        timestamp: Date.now(),
    };
}
