import store from 'stores/redux_store';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';
import {getCurrentChannelId} from 'mattermost-redux/selectors/entities/channels';
import {viewChannel, getChannelStats, actionsToMarkChannelAsUnread} from 'mattermost-redux/actions/channels';
import {receivedPost, getProfilesAndStatusesForPosts} from 'mattermost-redux/actions/posts';

import {isAppFocused} from 'features/app_activity/selector/is_app_focused';
import {sendPostNotification} from 'features/notifications/actions/send_post_notification';
import {getMentionsFromMessageProps} from 'features/notifications/actions/send_post_notification/utils';
import type {Meta} from 'features/notifications/actions/send_post_notification/types';
import type {PostEditedEvent} from 'features/posts/types/post_edited_event';

import {batchActions} from 'utils/batch_actions';

export function handlePostEditedEvent(event: PostEditedEvent) {
    const state = store.getState();
    const post = JSON.parse(event.data.post);
    const newMentions = getMentionsFromMessageProps(event.data);

    store.dispatch(receivedPost(post, true));
    getProfilesAndStatusesForPosts([post], store.dispatch, store.getState);
    const currentChannelId = getCurrentChannelId(state);

    if (currentChannelId === event.broadcast.channel_id) {
        store.dispatch(getChannelStats(currentChannelId));
        if (isAppFocused(state)) {
            store.dispatch(viewChannel(currentChannelId));
        }
    }

    const currentUserId = getCurrentUserId(state);
    if (newMentions.includes(currentUserId)) {
        const actions = actionsToMarkChannelAsUnread(
            store.getState, event.broadcast.team_id, post.channel_id, newMentions, false, post.root_id === '');

        store.dispatch(batchActions(actions));
        store.dispatch(
            sendPostNotification({
                meta: event.data as Meta,
                post,
            }),
        );
    }
}
