import {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';

/* TODO: Как только появиться реализация токенов:
 * - переписать на хук, для передачи с данными для сессии.
 * ссылка на задачу: https://jira.tcsbank.ru/browse/TIME-7985
 */

export const FALLBACK_TIMEOUT = 500;
const DEFAULT_APP_LINK = 'timeapp://';

export function useOpenDesktopDeeplink(fallback: () => void): [isOpen: boolean, openDeepLink: () => void] {
    const [isOpen, setOpen] = useState(false);
    const didBlured = useRef(false);
    const {pathname} = useLocation();

    const fallbackTimerId = useRef<NodeJS.Timeout>();

    const onBlurOnce = useCallback(() => {
        setOpen(true);
        didBlured.current = true;
        window.removeEventListener('blur', onBlurOnce);
    }, []);

    useEffect(() => {
        window.addEventListener('blur', onBlurOnce);

        return () => {
            window.removeEventListener('blur', onBlurOnce);
            if (fallbackTimerId.current) {
                clearTimeout(fallbackTimerId.current);
            }
        };
    }, [onBlurOnce]);

    const openDeepLink = useCallback(() => {
        fallbackTimerId.current = setTimeout(() => !didBlured.current && fallback(), FALLBACK_TIMEOUT);
        window.location.assign(`${DEFAULT_APP_LINK}${window.location.host}${pathname}?protocol=${window.location.protocol.split(':')[0]}`);
    }, [pathname, fallback]);

    return [isOpen, openDeepLink];
}
