import {Client4} from 'mattermost-redux/client';
import {TimeApiClient} from 'packages/request';

type GetGroupMemberCountResult = {
    total_member_count: number;
};

type GetGroupMemberCount = (groupId: string, signal?: AbortSignal) => Promise<GetGroupMemberCountResult>;

export const getGroupMemberCount: GetGroupMemberCount = async (groupId, signal) => {
    const {data} = await TimeApiClient.get<GetGroupMemberCountResult>(
        `${Client4.getUrl()}/api/v4/groups/${groupId}/members?page=0&per_page=0`,
        {signal},
    );
    return data;
};
