import noop from '@tinkoff/utils/function/noop';
import {createContext} from 'react';

export type MenuContextType = {
    isParentMenuShown: boolean;
    openedSubMenuItem: symbol | null;
    onOpenSubMenuItem: (itemId: symbol) => void;
    onCloseSubMenuItem: () => void;
}

export const MenuContext = createContext<MenuContextType>({isParentMenuShown: false, openedSubMenuItem: null, onOpenSubMenuItem: noop, onCloseSubMenuItem: noop});
