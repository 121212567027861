import {createSlice} from '@reduxjs/toolkit';

import {type Team} from '@mattermost/types/teams';

import {loadSidebarForTeam} from '../actions/load_sidebar_for_team';

export const sidebarForTeamLoadingSlice = createSlice({
    name: 'sidebar/loading',
    initialState: {} as Record<Team['id'], boolean>,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadSidebarForTeam.pending, (state, action) => {
            state[action.meta.arg.teamId] = true;
        });
        builder.addCase(loadSidebarForTeam.fulfilled, (state, action) => {
            state[action.meta.arg.teamId] = false;
        });
        builder.addCase(loadSidebarForTeam.rejected, (state, action) => {
            state[action.meta.arg.teamId] = false;
        });
    },
});
