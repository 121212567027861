import {createBrowserHistory} from 'history';

import {isDesktopApp} from 'utils/user_agent';

const b = createBrowserHistory({basename: window.basename});
const isDesktop = isDesktopApp();

type BrowserHistoryPushReturnDesktopAppMessageData = {
    type: 'browser-history-push-return';
    message: {
        pathName?: string;
    };
};

const nativeBrowserHistoryPush = b.push;

function isBrowserHistoryPushReturnDesktopAppMessageEvent(
    event: MessageEvent,
): event is MessageEvent<BrowserHistoryPushReturnDesktopAppMessageData> {
    return event?.data?.type === 'browser-history-push-return';
}

function isSameOriginEvent(event: MessageEvent) {
    const origin = event?.origin;
    return origin === window?.location?.origin;
}

/**
 * Desktop app sends its navigation to us
 *
 * @see https://gitlab.tcsbank.ru/messenger/desktop-app/-/blob/master/src/main/preload/mattermost.js#L250-258
 */
window.addEventListener('message', (event) => {
    if (!isSameOriginEvent(event)) {
        return;
    }

    if (!isBrowserHistoryPushReturnDesktopAppMessageEvent(event)) {
        return;
    }

    const pathName = event.data?.message?.pathName;

    if (pathName) {
        nativeBrowserHistoryPush(pathName);
    }
});

/**
 * Sends navigation event to desktop app
 *
 * @see https://gitlab.tcsbank.ru/messenger/desktop-app/-/blob/master/src/main/preload/mattermost.js#L145-149
 */
function sendBrowserHistoryPushMessageToDesktopApp(path?: string) {
    window.postMessage(
        {
            type: 'browser-history-push',
            message: {
                path,
            },
        },
        window.location.origin,
    );
}

b.push = (location, state) => {
    if (isDesktop) {
        sendBrowserHistoryPushMessageToDesktopApp(typeof location === 'string' ? location : location.pathname);
    } else {
        nativeBrowserHistoryPush(location, state);
    }
};

export const browserHistory = b;
