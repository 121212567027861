import {DraftType} from './constants';

import type {ChannelDraftKey, ChannelDraft, ThreadDraft, ThreadDraftKey} from './types';

export function getChannelDraftKey(id: string): ChannelDraftKey {
    return `${DraftType.CHANNEL}-${id}` as const;
}

export function getChannelDraftIdFromKey(key: ChannelDraftKey) {
    return key.replace(`${DraftType.CHANNEL}-`, '');
}

export function getThreadDraftKey(id: string): ThreadDraftKey {
    return `${DraftType.THREAD}-${id}` as const;
}

export function getThreadDraftIdFromKey(key: ThreadDraftKey) {
    return key.replace(`${DraftType.THREAD}-`, '');
}

export function isChannelDraft(key: string, value: any): value is ChannelDraft {
    return key.startsWith(DraftType.CHANNEL);
}

export function isChannelDraftKey(key: string): key is ChannelDraftKey {
    return key.startsWith(DraftType.CHANNEL);
}

export function isThreadDraft(key: string, value: any): value is ThreadDraft {
    return key.startsWith(DraftType.THREAD);
}

export function isThreadDraftKey(key: string): key is ThreadDraftKey {
    return key.startsWith(DraftType.THREAD);
}

export function getDraftIdFromKey(key: string) {
    if (isChannelDraftKey(key)) {
        return getChannelDraftIdFromKey(key);
    }

    if (isThreadDraftKey(key)) {
        return getThreadDraftIdFromKey(key);
    }

    return null;
}
