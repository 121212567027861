import React from 'react';
import classNames from 'classnames';

type Props = Omit<React.HTMLAttributes<HTMLSpanElement>, 'width' | 'height'> & {size?: number};

export default function HashTagIcon({size = 16, className, ...props}: Props) {
    return (
        <span className={classNames('icon time-icon', className)} {...props} data-testid='hash-tag-icon'>
            <svg
                style={{width: size, height: size, minWidth: size, minHeight: size}}
                viewBox='0 0 16 16'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M10.505 5.5625L10.9325 3.395C10.9925 3.095 10.745 2.75 10.3775 2.75C10.1 2.75 9.87502 2.9525 9.82252 3.23L9.36502 5.5625H7.58752L8.01503 3.395C8.07502 3.095 7.82752 2.75 7.46002 2.75C7.18252 2.75 6.95752 2.9525 6.90502 3.23L6.44752 5.5625H4.76002C4.45252 5.5625 4.19752 5.8175 4.19752 6.125C4.19752 6.4325 4.45252 6.6875 4.76002 6.6875H6.23002L5.71252 9.3125H4.02502C3.71752 9.3125 3.46252 9.5675 3.46252 9.875C3.46252 10.1825 3.71752 10.4375 4.02502 10.4375H5.49502L5.06752 12.605C5.00752 12.905 5.25502 13.25 5.62252 13.25C5.90002 13.25 6.12502 13.0475 6.17752 12.77L6.63502 10.4375H8.41252L7.98502 12.605C7.92502 12.905 8.17252 13.25 8.54002 13.25C8.81753 13.25 9.04252 13.0475 9.09502 12.77L9.55252 10.4375H11.24C11.5475 10.4375 11.8025 10.1825 11.8025 9.875C11.8025 9.5675 11.5475 9.3125 11.24 9.3125H9.77002L10.2875 6.6875H11.975C12.2825 6.6875 12.5375 6.4325 12.5375 6.125C12.5375 5.8175 12.2825 5.5625 11.975 5.5625H10.505ZM6.85252 9.3125L7.37002 6.6875H9.14752L8.63003 9.3125H6.85252Z' />
            </svg>
        </span>
    );
}
