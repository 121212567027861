import {useIntl} from 'react-intl';

import {LockScreenLayout} from '../layout';

import {BackButton} from 'components/back_button';

import {SIGNIN_ROUTE} from 'utils/route';
import {browserHistory} from 'utils/browser_history';

import styles from './styles.module.css';

export const LockScreenForNoLicensesToSignIn = () => {
    const {formatMessage} = useIntl();

    return (
        <div className={styles.container}>
            <LockScreenLayout
                icon={<span>&#128274;</span>}
                heading={formatMessage({
                    id: 'error.license.noLicense_to_sign_in.title',
                    defaultMessage: 'Failed to sign in',
                })}
                body={formatMessage({
                    id: 'error.license.noLicense_to_sign_in.description',
                    defaultMessage: 'No licenses available — contact support service',
                })}
                header={<BackButton onPress={() => browserHistory.push(SIGNIN_ROUTE)} />}
            />
        </div>
    );
};
