import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const CodeTagsIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            fill='currentColor'
            d='m14.6 16.6 4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4Zm-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4Z'
        />
    </svg>
));
