import {connect} from 'react-redux';

import {getTeammateNameDisplaySetting} from 'mattermost-redux/selectors/entities/preferences';
import {getCurrentUser, getUsersByUsername} from 'mattermost-redux/selectors/entities/users';
import {getAllGroupsForReferenceByName} from 'mattermost-redux/selectors/entities/groups';

import {type GlobalState} from 'types/store';

import type {UserProfile} from '@mattermost/types/users';
import type {Group} from 'mattermost-redux/types/groups';

import {displayUsername} from 'mattermost-redux/utils/user_utils';

import {Client4} from 'mattermost-redux/client';
import {isGroupMember, isGroupMembersViewAvailable} from 'features/groups_mention/selectors';

import AtMention from './at_mention';

function getUserFromMentionName(usersByUsername: Record<string, UserProfile>, mentionName: string) {
    let mentionNameToLowerCase = mentionName.toLowerCase();

    while (mentionNameToLowerCase.length > 0) {
        if (usersByUsername?.[mentionNameToLowerCase]) {
            return usersByUsername[mentionNameToLowerCase];
        }

        // Repeatedly trim off trailing punctuation in case this is at the end of a sentence
        if ((/[._-]$/).test(mentionNameToLowerCase)) {
            mentionNameToLowerCase = mentionNameToLowerCase.substring(0, mentionNameToLowerCase.length - 1);
        } else {
            break;
        }
    }

    return null;
}

function getGroupFromMentionName(groupsByName: Record<string, Group>, mentionName: string) {
    const mentionNameTrimmed = mentionName.toLowerCase().replace(/[._-]*$/, '');
    return groupsByName?.[mentionNameTrimmed] || {};
}

function mapStateToProps(state: GlobalState, ownProps: {
    mentionName?: string;
    disableGroupHighlight?: boolean;
}) {
    const {mentionName} = ownProps;
    const usersByUsername = getUsersByUsername(state);
    const groupsByName = getAllGroupsForReferenceByName(state);

    const userFromMentionName = getUserFromMentionName(usersByUsername, mentionName || '');
    const groupFromMentionName = getGroupFromMentionName(groupsByName, mentionName || '');
    const currentUser = getCurrentUser(state);
    const teammateNameDisplay = getTeammateNameDisplaySetting(state);

    return {
        teammateNameDisplay,
        groupFromMentionNameAllowReference: groupFromMentionName?.allow_reference,
        groupFromMentionNameName: groupFromMentionName?.name,
        groupFromMentionNameId: groupFromMentionName?.id,
        isMentionUserCurrentUser: userFromMentionName?.id === currentUser?.id,
        mentionUserDisplayUsername: displayUsername(userFromMentionName, teammateNameDisplay),
        mentionUserProfilePicture: Client4.getProfilePictureUrl(userFromMentionName?.id || '', userFromMentionName?.last_picture_update || 0),
        mentionUserId: userFromMentionName?.id || '',
        mentionUserUsername: userFromMentionName?.username || '',
        disableGroupHighlight: Boolean(currentUser?.isGuest || ownProps.disableGroupHighlight),
        isGroupMembersViewAvailable: isGroupMembersViewAvailable(state),
        isGroupMember: isGroupMember(state, groupFromMentionName?.id || ''),
    };
}

export default connect(mapStateToProps)(AtMention);
