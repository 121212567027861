import React, {} from 'react';
import type {SuggestionProps} from '@tiptap/suggestion';

import classNames from 'classnames';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {sendToStatist} from '@time-webkit/statist';

import {MentionList} from '../../../../components/mention-list';

import type {EmojiMention} from '../../types';

import styles from './styles.module.css';

type MentionListProps = {
    getMentions: (query: string) => Promise<EmojiMention[]>;
} & Pick<SuggestionProps<EmojiMention>, 'query' | 'command' | 'decorationNode'>;

const Handle: React.FunctionComponent = (props) => {
    const typographyCls = useTypography({
        size: TypographySize.BodyL,
    });

    return <span className={typographyCls}>{props.children}</span>;
};

const MentionOption = ({handle, url}: EmojiMention) => {
    return <Handle><span className={classNames('emoticon', styles.icon)} style={{backgroundImage: `url(${url})`}} />{':' + handle + ':'}</Handle>;
};

/* 8px top padding, 4 list items and just enough of 5th */
const LIST_MAX_HEIGHT = 8 + (36 * 4) + (36 * 0.6);

export const EmojiList: React.VoidFunctionComponent<MentionListProps> = (props) => {
    const {query, getMentions, command, decorationNode} = props;
    const onSelect = (_: EmojiMention, index: number) => {
        sendToStatist('editor.searchEmoji.resultTap', {
            resultNumber: index + 1,
        });
    };

    return (
        <MentionList
            query={query}
            command={command}
            decorationNode={decorationNode}
            getMentions={getMentions}
            renderMentionOption={(item: EmojiMention) => <MentionOption {...item} />}
            onSelect={onSelect}
            maxHeight={LIST_MAX_HEIGHT}
        />
    );
};
