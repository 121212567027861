import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const AccountMultipleIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref} data-testid='account-multiple-icon'>
        <path
            fill='currentColor'
            d='m16.3 16.2-2.5-1.5c1-1.4 1.6-3.3 1.6-5.2C15.4 6.2 13.2 4 10 4S4.6 6.2 4.6 9.5c0 1.9.6 3.8 1.6 5.2l-2.5 1.5c-1 .5-1.7 1.6-1.7 2.7C2 20.6 3.4 22 5 22h10a3 3 0 0 0 3-3.1c0-1.1-.7-2.2-1.7-2.7ZM6.6 9.5C6.6 7.1 8.1 6 10 6s3.4 1.1 3.4 3.5c0 1.5-.5 3.1-1.4 4.2-.5.7-1.2 1.1-2.1 1.1-.9 0-1.5-.4-2.1-1.1a8.3 8.3 0 0 1-1.2-4.2ZM15 20H5c-.6 0-1-.5-1-1.1v-.3l.3-.5.3-.1 3.1-1.9c.7.4 1.4.6 2.3.6.9 0 1.6-.2 2.3-.6l3.1 1.9c.4.2.6.5.6 1s-.5 1-1 1Zm3.9-6.9c.3-1.2.5-2.4.5-3.6 0-.4 0-3.1-1.9-5.5-.8-1-2.5-2-3.5-2-.7 0-1.3.1-1.8.3 1.3.4 2.5 1.1 3.4 2 1 1.1 1.6 2.5 1.8 4.1l.1 1.1c0 1.6-.3 3.1-.9 4.5l.8.5 1.4 1.1c.5.6.9 1.2 1.1 2l.2 1.4-.1.9a3 3 0 0 0 2-2.1l.1-.8a5 5 0 0 0-3.2-3.9Z'
        />
    </svg>
));
