import React from 'react';
import {FormattedMessage} from 'react-intl';

import {type Post} from 'mattermost-redux/types/posts';
import {type UserProfile} from 'mattermost-redux/types/users';

import {sendAddToChannelEphemeralPost} from 'actions/global_actions';
import {Constants} from 'utils/constants';
import AtMention from 'components/at_mention';

import {type Channel} from 'mattermost-redux/types/channels';

interface Actions {
    addChannelMember: (channelId: string, userId: string, rootId: string) => void;
    removePost: (post: Post) => void;
}

export interface Props {
    currentUser: UserProfile;
    post: Post;
    channel: Channel;
    userIds: string[];
    usernames: string[];
    canAddChannelMembers: boolean;
    actions: Actions;
}

interface State {
    expanded: boolean;
}

export default class PostAddChannelMember extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            expanded: false,
        };
    }

    handleAddChannelMember = () => {
        const {currentUser, post, userIds, usernames} = this.props;

        let createAt = post.create_at;
        userIds.forEach((userId, index) => {
            createAt++;
            this.props.actions.addChannelMember(post.channel_id, userId, post.root_id);
            const userNameToAdd = usernames[index];
            if (userNameToAdd) {
                sendAddToChannelEphemeralPost(currentUser, userNameToAdd, userId, post.channel_id, post.root_id, createAt);
            }
        });

        this.props.actions.removePost(post);
    }

    expand = () => {
        this.setState({expanded: true});
    }

    generateAtMentions(usernames = [] as string[]) {
        if (usernames.length === 1) {
            return (
                <AtMention
                    mentionName={usernames[0]}
                    channelId={this.props.post.channel_id}
                />
            );
        } else if (usernames.length > 1) {
            function andSeparator(key: number) {
                return (
                    <FormattedMessage
                        key={key}
                        id={'post_body.check_for_out_of_channel_mentions.link.and'}
                        defaultMessage={' and '}
                    />
                );
            }

            function commaSeparator(key: number) {
                return <span key={'comma_' + key}>{', '}</span>;
            }

            if (this.state.expanded || usernames.length <= 3) {
                return (
                    <span>
                        {
                            usernames.map((username) => {
                                return (
                                    <AtMention
                                        key={username}
                                        mentionName={username}
                                        channelId={this.props.post.channel_id}
                                    />
                                );
                            }).reduce((acc, el, idx, arr) => {
                                if (idx === 0) {
                                    return [el];
                                } else if (idx === arr.length - 1) {
                                    return [...acc, andSeparator(idx), el];
                                }

                                return [...acc, commaSeparator(idx), el];
                            }, [] as JSX.Element[])
                        }
                    </span>
                );
            }
            const otherUsers = [...usernames];
            const firstUserName = otherUsers.shift();
            const lastUserName = otherUsers.pop();
            return (
                <span>
                    <AtMention
                        key={firstUserName}
                        mentionName={firstUserName}
                        channelId={this.props.post.channel_id}
                    />
                    {commaSeparator(1)}
                    <a
                        className='PostBody_otherUsersLink'
                        onClick={this.expand}
                    >
                        <FormattedMessage
                            id={'post_body.check_for_out_of_channel_mentions.others'}
                            defaultMessage={'{numOthers} others'}
                            values={{
                                numOthers: otherUsers.length,
                            }}
                        />
                    </a>
                    {andSeparator(1)}
                    <AtMention
                        key={lastUserName}
                        mentionName={lastUserName}
                        channelId={this.props.post.channel_id}
                    />
                </span>
            );
        }

        return '';
    }

    render() {
        const {channel, usernames, canAddChannelMembers} = this.props;

        const outOfChannelAtMentions = this.generateAtMentions(usernames);

        return (
            <p>
                {outOfChannelAtMentions}
                {' '}
                <FormattedMessage
                    id='post_body.check_for_out_of_channel_mentions.message'
                    defaultMessage='{count, plural, one {is not a member} other {are not members}} of the channel and {count, plural, one {does not see} other {do not see}} the {count, plural, one {mention} other {mentions}}'
                    values={{count: usernames.length}}
                />
                {'. '}
                {!canAddChannelMembers && (
                    <>
                        <FormattedMessage
                            id='post_body.check_for_out_of_channel_mentions.can_not_add_members'
                            defaultMessage='{count, plural, one {User} other {Users}} can view all message history, when {count, plural, one {is} other {are}} added to the channel'
                            values={{count: usernames.length}}
                        />
                        {'.'}
                    </>
                )}
                {canAddChannelMembers && (
                    <>
                        <FormattedMessage
                            id='post_body.check_for_out_of_channel_mentions.add_members_intro'
                            defaultMessage='You can'
                        />
                        {' '}
                        <a
                            className='PostBody_addChannelMemberLink'
                            onClick={this.handleAddChannelMember}
                        >
                            {channel.type === Constants.PRIVATE_CHANNEL && (
                                <FormattedMessage
                                    id='post_body.check_for_out_of_channel_mentions.add_members_private_channel'
                                    defaultMessage='add {count, plural, one {this user} other {these users}} to the private channel'
                                    values={{count: usernames.length}}
                                />
                            )}
                            {channel.type === Constants.OPEN_CHANNEL && (
                                <FormattedMessage
                                    id='post_body.check_for_out_of_channel_mentions.add_members_open_channel'
                                    defaultMessage='add {count, plural, one {this user} other {these users}} to this channel'
                                    values={{count: usernames.length}}
                                />
                            )}
                        </a>
                        {', '}
                        <FormattedMessage
                            id='post_body.check_for_out_of_channel_mentions.add_members_outro'
                            defaultMessage='then they can view all message history'
                            values={{count: usernames.length}}
                        />
                        {'.'}
                    </>
                )}
            </p>
        );
    }
}
