import React from 'react';

import {useSelector} from 'react-redux';

import {useAppDispatch} from 'stores/redux_store';

import {useDraftsStorage} from './storage';

import {receivedInitialDrafts, failedReceiveInitialDrafts} from './logic/slice';
import {type Draft, type Drafts} from './types';
import {getDraftsInit} from './logic/selectors';
import {DraftsStoreStates} from './constants';

export function useDrafts() {
    const dispatch = useAppDispatch();
    const draftsState = useSelector(getDraftsInit);

    const onDraftsInit = React.useCallback(
        (drafts) => {
            if (drafts) {
                const draftsMap = Object.entries(drafts) as Array<[key: keyof Drafts, value: Draft]>;

                dispatch(
                    receivedInitialDrafts(
                        draftsMap.map(([key, value]) => ({
                            key,
                            value,
                        })),
                    ),
                );
            } else {
                dispatch(receivedInitialDrafts({} as any));
            }
        },
        [dispatch],
    );

    const handleDraftsError = React.useCallback(() => {
        dispatch(failedReceiveInitialDrafts());
    }, [dispatch]);

    useDraftsStorage(onDraftsInit, handleDraftsError);

    return {loading: draftsState === DraftsStoreStates.LOADING};
}
