import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Channel} from '@mattermost/types/channels';
import type {ThunkConfig} from 'stores/redux_store';
import {getChannelChannelCategories} from '../selectors/get_channel_channel_categories';
import {isDirectLikeChannel} from '../utils/isDirectLikeChannel';

import {getChannelsChannelCategoriesForTeam} from '../selectors/get_channels_channel_category_for_team';

import {type ChannelCategory} from '@mattermost/types/channel_categories';

import {addDirectLikeChannelToInitialCategory} from './add_direct_like_channel_to_initial_category';
import {addChannelToChannelCategory} from './add_channel_to_channel_category';
import {receivedChannelCategory} from './received_channel_category';

type Payload = {
    channel: Channel;
    setOnServer?: boolean;
};

/**
 * Adds a newly-joined or newly-created channel to its either the Channels or Direct Messages category based on the type of channel.
 * New DM and GM channels are added to the Direct Messages category on each team.
 *
 * Unless `setOnServer` is true, this only affects the categories on this client.
 * If it is set to true, this updates categories on the server too.
 */
export const addChannelToInitialCategory = createAsyncThunk<boolean, Payload, ThunkConfig>(
    'sidebar/actions/addChannelToInitialCategory',
    async (payload, thunkAPI) => {
        const {channel, setOnServer = false} = payload;
        const state = thunkAPI.getState();
        const dispatch = thunkAPI.dispatch;

        if (isDirectLikeChannel(channel)) {
            dispatch(addDirectLikeChannelToInitialCategory(channel));
            return true;
        }

        const existingCategories = getChannelChannelCategories(state, channel);

        /**
         * Add the new channel to the Channels category on the channel's team
         *
         * @TODO: тут непонятно почему так НАПИСАНО выше. Возможно, добавить
         */
        if (existingCategories.length) {
            return false;
        }

        const channelsCategory = getChannelsChannelCategoriesForTeam(state, channel?.team_id);

        if (!channelsCategory) {
            /**
             * No categories were found for this team, so the categories for this team haven't been loaded yet.
             * The channel will have been added to the category by the server, so we'll get it once the categories
             * are actually loaded.
             */
            return false;
        }

        if (setOnServer) {
            const result = await dispatch(addChannelToChannelCategory({category: channelsCategory, channel})).unwrap();

            return result;
        }

        const newChannelIds = channelsCategory.channel_ids.filter((id) => id !== channel.id);

        const channelsCategoryWithNewChannel: ChannelCategory = {
            ...channelsCategory,
            channel_ids: [channel.id].concat(newChannelIds),
        };

        dispatch(receivedChannelCategory(channelsCategoryWithNewChannel));

        return true;
    },
);
