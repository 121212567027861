import ReactDayPicker, {type DayPickerProps} from 'react-day-picker';

import React from 'react';

import {LocaleUtils} from './utils';

type Props = Omit<DayPickerProps, 'localeUtils' | 'locale'>

export const DayPicker: React.FC<Props> = (props) => {
    return (
        <ReactDayPicker
            {...props}
            localeUtils={LocaleUtils}
        />
    );
};
