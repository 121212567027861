import {isSystemEmoji, getEmojiImageUrl} from 'mattermost-redux/utils/emoji_utils';

import type {EmojiMention, MatchedEmoji} from './types';

function mapEmojiToEmojiMention(matchedEmoji: MatchedEmoji): EmojiMention {
    return {
        handle: matchedEmoji.name,
        label: matchedEmoji.name,
        id: matchedEmoji.name,
        emojiId: isSystemEmoji(matchedEmoji.emoji) ? matchedEmoji.name : matchedEmoji.emoji.id,
        url: getEmojiImageUrl(matchedEmoji.emoji),
    };
}

export function transformAutocompleteEmojiResponse(response: MatchedEmoji[]): EmojiMention[] {
    return response.map(mapEmojiToEmojiMention);
}
