import TipTapMentionExtension, {MentionOptions} from '@tiptap/extension-mention';
import {ReactRenderer} from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import {listCommandAutocompleteSuggestions} from 'mattermost-redux/api/teams/listCommandAutocompleteSuggestions';
import {PluginKey} from 'prosemirror-state';

import {Constants} from 'utils/constants';

import {SearchTimeMeasurer, sendToStatist} from '@time-webkit/statist';

import {ChannelMentionList} from './components/mention-list';
import {TIMEOUT} from './constants';
import {transformListCommandAutocompleteSuggestionsResponse} from './transformers';

const emptyMentions: any[] = [];

const suggestionKey = new PluginKey('suggestion-TimeEditorSlashCommandExtension');

const searchTimeMeasurer = new SearchTimeMeasurer('editor.searchCommand.result');

export const TimeEditorSlashCommandExtension = TipTapMentionExtension.extend<
{
    teamId: string;
    channelId: string;
    rootId: string;
    withinThread: boolean;
} & MentionOptions
>({
    name: 'TimeEditorSlashCommandExtension',
    addStorage() {
        return {
            abortController: null,
            menu: null,
            error: null,
        };
    },

    addProseMirrorPlugins() {
        const options = this.options;
        const storage = this.storage;

        const getCommands = async (query: string) => {
            const prevAbortController = storage?.abortController;

            prevAbortController?.abort();

            if (!query) {
                return emptyMentions;
            }

            searchTimeMeasurer.measure(query);
            const controller = new AbortController();

            storage.abortController = controller;

            const data = await listCommandAutocompleteSuggestions(
                {
                    user_input: query,
                    team_id: options.teamId,
                    channel_id: options.channelId,
                    root_id: options.rootId,
                },
                {
                    signal: controller.signal,
                    timeout: TIMEOUT,
                    cache: {
                        interpretHeader: false,
                    },
                },
            ).then(({data}) => {
                searchTimeMeasurer.measure(query, data.length);
                return transformListCommandAutocompleteSuggestionsResponse(data);
            });

            return data;
        };

        return [
            // eslint-disable-next-line new-cap
            Suggestion({
                editor: this.editor,
                pluginKey: suggestionKey,
                char: '/',
                allowSpaces: false,
                startOfLine: true,
                command: ({editor, range, props}) => {
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    const overrideSpace = nodeAfter?.text?.startsWith(' ');

                    if (overrideSpace) {
                        range.to += 1;
                    }

                    try {
                        editor.
                            chain().
                            focus().
                            insertContentAt(range, [
                                {
                                    type: this.name,
                                    attrs: props,
                                },
                                {
                                    type: 'text',
                                    text: ' ',
                                },
                            ]).
                            run();

                        window.getSelection()?.collapseToEnd();
                        // eslint-disable-next-line no-empty
                    } catch {}
                },
                allow: ({state, range}) => {
                    const $from = state.doc.resolve(range.from);
                    const type = state.schema.nodes[this.name];
                    const allow = Boolean($from.parent.type.contentMatch.matchType(type)) && state.doc.textContent[0] === '/';

                    return allow;
                },
                render() {
                    return {
                        onStart(props) {
                            sendToStatist('editor.searchCommand.tap', {
                                sourceTap: options.withinThread ? 'thread' : 'post',
                            });
                            const component = new ReactRenderer(ChannelMentionList, {
                                editor: props.editor,
                                props: {
                                    ...props,
                                    getMentions: getCommands,
                                },
                            });

                            document.body.appendChild(component.element);

                            storage.menu = component;
                        },
                        onUpdate(props) {
                            const component = storage.menu as ReactRenderer;
                            component?.updateProps({
                                ...props,
                                getMentions: getCommands,
                            });
                        },
                        onKeyDown(props) {
                            const component = storage.menu;

                            if (props.event.key === Constants.KeyCodes.ESCAPE[0]) {
                                if (component?.element) {
                                    document.body.removeChild(component?.element);
                                }
                                component?.destroy();
                                storage.menu = null;

                                return true;
                            }

                            return false;
                        },
                        onExit() {
                            const component = storage.menu as ReactRenderer;
                            if (component?.element) {
                                document.body.removeChild(component?.element);
                            }
                            component?.destroy();
                            storage.menu = null;
                            searchTimeMeasurer.dispose();
                        },
                    };
                },
            }),
        ];
    },
}).configure({
    suggestion: {
        char: '/',
    },
});
