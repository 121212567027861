import {useState, type MouseEvent, type CSSProperties} from 'react';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import classNames from 'classnames';

import {PencilOutlineIcon} from '@mattermost/compass-icons/components';

import {CustomStatusDuration, type UserCustomStatus} from '@mattermost/types/users';
import EmojiIcon from 'components/widgets/icons/emoji_icon';
import {ModalIdentifiers} from 'utils/constants';
import {type ModalData} from 'types/actions';

import CustomStatusEmoji from './custom_status_emoji';
import ExpiryTime from './expiry_time';

import CustomStatusModal from './custom_status_modal';

interface Props {
    userId: string;
    customStatus?: UserCustomStatus;
    isCustomStatusEnabled: boolean;
    isCustomStatusExpired: boolean;
    currentUserTimezone?: string;
    center?: boolean;
    editable?: boolean;
    onEdit?: () => void;
    actions: {
        openModal: <P>(modalData: ModalData<P>) => void;
    };
}

const Wrapper = styled.div`
    padding: 8px;
    margin: -8px;
    border-radius: 8px;
    min-height: 32px;
    position: relative;

    &.editable {
        cursor: pointer;

        .add-icon,
        .edit-icon {
            fill: rgba(var(--center-channel-color-rgb), 0.56);
        }

        .edit-icon {
            max-width: 16px;
            min-width: 16px;
            max-height: 16px;
            min-height: 16px;
        }

        .add-icon {
            margin-top: -8px !important;
        }

        .set-status {
            color: rgba(var(--center-channel-color-rgb), 0.64);
        }

        &.onlyEmoji {
            .emoticon {
                margin-top: -2px;
            }
        }

        &:hover {
            background: rgba(var(--center-channel-color-rgb), 0.08);

            .add-icon,
            .edit-icon {
                fill: var(--center-channel-color);
            }

            .set-status {
                color: var(--center-channel-color);
            }
        }
    }
`;

const Container = styled.div`
    margin-left: 24px;

    .emoticon {
        margin: 2px 0 0 -24px;
        position: absolute;

        &:last-child {
            position: static;
            margin: 0 0 0 -24px;
        }
    }

    &.onlyEmoji {
        .emoticon {
            position: static;
            margin: 0 8px 0 -24px;
        }
        .add-icon {
            margin-right: 3px;
        }
    }

    &.center {
        text-align: center;
        margin-right: 24px;
        .emoticon:last-child {
            margin: 0;
        }
    }
`;

const Text = styled.div`
    display: inline-block;

    &.center {
        margin: 0 24px;
        text-align: center;
    }

    + * {
        margin-top: 4px;
    }
`;

const Expire = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: rgba(var(--center-channel-color-rgb), 0.64);
    white-space: nowrap;

    .emoticon {
        margin-top: 0;
    }

    .emoticon + & {
        display: inline-block;
        text-align: left;
        margin: 0 24px;
    }

    .emoticon + .custom-status-expiry {
        display: inline-block;
    }
`;

const statusTextStyles: CSSProperties = {wordBreak: 'break-word'};

const CustomStatus = (props: Props) => {
    const {
        userId,
        customStatus,
        isCustomStatusEnabled,
        isCustomStatusExpired,
        currentUserTimezone,
        center,
        editable,
        onEdit,
        actions,
    } = props;

    const hasStatus = (customStatus?.text || customStatus?.emoji) && !isCustomStatusExpired;
    const show = isCustomStatusEnabled && (hasStatus || editable);

    const [hover, setHover] = useState(false);
    const onMouseEnter = () => setHover(true);
    const onMouseLeave = () => setHover(false);

    const showCustomStatusModal = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        onEdit?.();
        actions.openModal({
            modalId: ModalIdentifiers.CUSTOM_STATUS,
            dialogType: CustomStatusModal,
        });
    };

    if (!show) {
        return null;
    }

    const expiryContent = !isCustomStatusExpired && customStatus?.expires_at && customStatus.duration !== CustomStatusDuration.DONT_CLEAR && (
        <ExpiryTime
            time={customStatus.expires_at}
            timezone={currentUserTimezone}
            className='custom-status-expiry'
        />
    );

    let emoticon = (
        <CustomStatusEmoji
            userID={userId}
            showTooltip={false}
        />
    );
    let statusText = !isCustomStatusExpired && customStatus?.text ? <span style={statusTextStyles}>{customStatus.text}</span> : null;
    if (editable) {
        if (hasStatus) {
            if (hover) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore className
                emoticon = <PencilOutlineIcon className='emoticon edit-icon' />;
            }
        } else {
            emoticon = <EmojiIcon className='emoticon add-icon' />;
        }
        if (!statusText && !expiryContent) {
            statusText = (
                <span className='set-status'>
                    <FormattedMessage
                        id='user_profile.setCustomStatus'
                        defaultMessage='Set Custom Status'
                    />
                </span>
            );
        }
    }

    const className = classNames({center, editable, onlyEmoji: isCustomStatusExpired || (!customStatus?.text && !expiryContent)});

    return (
        <Wrapper
            className={className}
            onMouseEnter={editable ? onMouseEnter : undefined}
            onMouseLeave={editable ? onMouseLeave : undefined}
            onClick={editable ? showCustomStatusModal : undefined}
        >
            <Container className={className}>
                {statusText ? (
                    <Text className={className}>
                        {emoticon}
                        {statusText}
                    </Text>
                ) : null}
                {expiryContent ? (
                    <Expire>
                        {!statusText && emoticon}
                        {expiryContent}
                    </Expire>
                ) : null}
                {!statusText && !expiryContent ? emoticon : null}
            </Container>
        </Wrapper>
    );
};

export default CustomStatus;
