import type {Editor} from '@tiptap/react';
import React from 'react';

import type {Features} from './types';

type EditorContextT = { editor?: Editor; features: Features; maxCharacters: number; charactersCount: number };

export const EditorContext = React.createContext<EditorContextT | null>(null);

export function useEditorContext() {
    return React.useContext(EditorContext || {features: {}}) as EditorContextT;
}
