import type {Channel} from '@mattermost/types/channels';
import {getAllChannels} from 'mattermost-redux/selectors/entities/channels';
import {createThunkAction} from 'stores/create_thunk_action';
import type {ServerThread} from '../types/threads';

import {receivedThreads} from './received_threads';

export const receivedServerThreads = (serverThreads: ServerThread[]) =>
    createThunkAction('threads/actions/receivedRawThreads', (dispatch, getState) => {
        const state = getState();

        const allChannels = getAllChannels(state);

        dispatch(
            receivedThreads(
                serverThreads
                    .filter((thread) => {
                        const channelId = thread.post.channel_id;

                        // @TODO: у нас не может быть канала
                        // видимо, это баг бэкенда, поэтому отфильтровываем такие треды
                        const channel: Channel | undefined = allChannels[channelId];

                        return Boolean(channel?.id);
                    })
                    .map((thread) => {
                        const channelId = thread.post.channel_id;

                        // здесь мы уже отфильтровали треды у которых нет канала
                        const channel = allChannels[channelId]!;

                        const teamId = channel.team_id;

                        return {
                            ...thread,
                            team_id: teamId,
                            channel_id: channelId,
                        };
                    }),
            ),
        );
    });
