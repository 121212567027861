import {markThreadLinkClicked} from './markThreadLinkClicked';
import {markChannelLinkClicked} from './markChannelLinkClicked';

const THREAD_PARAM = 'thread-post';

const markClicked = (url: string, channelDetail: {channelType: string; channelId?: string; channelName: string}) => {
    if (!window.perf10t) {
        return;
    }

    const query = new URLSearchParams(url.split('?')[1]);
    const isThreadPost = query.get(THREAD_PARAM);

    if (isThreadPost) {
        return markThreadLinkClicked({detail: {sourceType: 'push'}});
    }

    return markChannelLinkClicked({
        detail: {
            channelType: channelDetail.channelType,
            channelId: channelDetail.channelId || 'unknown',
            channelName: channelDetail.channelName,
            sourceType: 'push',
        },
    });
};

const buildSearchParams = (isThreadPost: boolean): string => {
    const searchParams = new URLSearchParams();

    if (isThreadPost) {
        searchParams.append(THREAD_PARAM, 'true');
    }

    return searchParams.toString();
};

export const pushNotifications = {
    markClicked,
    buildSearchParams,
};
