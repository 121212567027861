import omit from '@tinkoff/utils/object/omit';

import {Client4} from 'mattermost-redux/client';
import type {User} from 'features/users';
import type {Team} from '@mattermost/types/teams';
import type {AnyThread} from '../types/extended';
import type {ServerThread} from '../types/threads';

type Payload = {

    /**
     * The ID of the user. This can also be "me" which will point to the current user.
     */
    userId: User['id'] | 'me';

    /**
     * The ID of the team in which the thread is.
     */
    teamId: Team['id'];

    /**
     * The ID of the thread to follow
     */
    threadId: AnyThread['id'];

    extended?: boolean;
};

/**
 * Get a thread followed by the user
 *
 * Get a thread
 *
 * Must be logged in as the user or have `edit_other_users` permission.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetUserThread
 * @example "/users/{user_id}/teams/{team_id}/threads/{thread_id}"
 */
export const getUserThread = async (payload: Payload) => {
    const {teamId, userId, threadId, extended = false} = payload;

    const requester = Client4.requester;

    const options = omit(['url'], Client4.getTimeApiClientOptions({}));

    const url = `${Client4.getUrl()}/api/v4/users/${userId}/teams/${teamId}/threads/${threadId}`;

    const fetchedUserThread = await requester.get<ServerThread>(url, {
        ...options,
        params: {extended},
    });

    return fetchedUserThread.data;
};
