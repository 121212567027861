export enum DraftType {
    CHANNEL = 'channel-draft',
    THREAD = 'thread-draft',
    TEAM = 'team-drafts',
}

export const DRAFTS_ROUTE = '/:team/drafts';

export enum DraftsStoreStates {
    LOADING,
    LOADED,
    FAILED,
}
