// ported from https://gitlab.tcsbank.ru/messenger/time-server/-/blob/f7b9c85e39adc2156cb998758fccce78edf8de15/model/search_params.go#L155
import type {ParseSearchFlagsResult, SearchWord, Flag} from '../types';

const searchFlags = ['from', 'channel', 'in', 'before', 'after', 'on', 'ext'];
const searchTermPuncStart = /^[^\p{L}\d\s#"]+/gu;
const searchTermPuncEnd = /[^\p{L}\d\s*"]+$/gu;
const hashtagStart = /^#{2,}/gu;

export const parseSearchFlags = (words: string[]): ParseSearchFlagsResult => {
    const searchWords: SearchWord[] = [];
    const flags: Flag[] = [];

    let skipNextWord = false;
    for (let idx = 0; idx < words.length; idx++) {
        let word = words[idx]!;

        if (skipNextWord) {
            skipNextWord = false;
            continue;
        }

        let isFlag = false;

        const colonIdx = word.indexOf(':');
        if (colonIdx !== -1) {
            let flagName: string;
            let exclude: boolean;
            if (word.startsWith('-')) {
                flagName = word.slice(1, colonIdx);
                exclude = true;
            } else {
                flagName = word.slice(0, colonIdx);
                exclude = false;
            }

            const value = word.slice(colonIdx + 1);
            for (const searchFlag of searchFlags) {
                if (flagName.toLowerCase() === searchFlag.toLowerCase()) {
                    if (value !== '') {
                        flags.push({
                            name: searchFlag,
                            value,
                            exclude,
                        });
                        isFlag = true;
                    } else if (idx < words.length - 1) {
                        flags.push({
                            name: searchFlag,
                            value: words[idx + 1]!,
                            exclude,
                        });
                        skipNextWord = true;
                        isFlag = true;
                    }

                    if (isFlag) {
                        break;
                    }
                }
            }
        }

        if (!isFlag) {
            let exclude = false;
            if (word.startsWith('-')) {
                exclude = true;
            }

            // trim off surrounding punctuation (note that we leave trailing asterisks to allow wildcards)
            word = word.replace(searchTermPuncStart, '');
            word = word.replace(searchTermPuncEnd, '');

            // and remove extra pound #s
            word = word.replace(hashtagStart, '#');

            if (word !== '') {
                searchWords.push({
                    value: word,
                    exclude,
                });
            }
        }
    }

    return {searchWords, flags};
};
