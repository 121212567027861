import type {AutocompleteSuggestion} from '@mattermost/types/autocomplete';

import type {CommandMention} from './types';

function mapChannelToChannelMention(command: AutocompleteSuggestion): CommandMention {
    return {
        displayName: command.Description,
        handle: `${command.Suggestion} ${command.Hint}`.trim(),
        id: command.Suggestion,
    };
}

export function transformListCommandAutocompleteSuggestionsResponse(response: AutocompleteSuggestion[]): CommandMention[] {
    return response.map(mapChannelToChannelMention);
}
