import type {ActionResult, GetStateFunc} from 'mattermost-redux/types/actions';
import type {Channel} from '@mattermost/types/channels';
import type {AppDispatch} from 'stores/redux_store';
import {fetchUsersByIds} from 'features/users/actions/fetch_users_by_ids';
import {makeGetChannel} from 'mattermost-redux/selectors/entities/channels';
import {asyncDelay} from 'utils/asyncDelay';

import type {Payload} from './types';
import {TIMEOUT} from './constants';
import {shouldSendNotification} from './should_send_notification';
import {sendNotification} from './send_notification';

const getChannel = makeGetChannel();

export function sendPostNotification(payload: Payload) {
    return async (dispatch: AppDispatch, getState: GetStateFunc): Promise<ActionResult> => {
        const state = getState();
        const {post, meta} = payload;

        const postChannel = getChannel(state, {id: post.channel_id}) as Channel | undefined;

        const shouldSend = shouldSendNotification(state, post, meta, postChannel);

        if (!shouldSend.send) {
            return {data: false, error: shouldSend.reason};
        }

        /**
         * Если у нас нет фоллбэка от бэкенда, то нам нужен пользователь
         * Но мы не хотим сильно задерживать показ нотификации
         * поэтому ждем совсем немного
         */
        if (!meta.sender_name) {
            await Promise.race([
                dispatch(
                    fetchUsersByIds({
                        userIds: [post.user_id],
                    }),
                ),
                asyncDelay(TIMEOUT),
            ]);
        }

        const updatedState = getState();

        try {
            await sendNotification(updatedState, post, postChannel, meta);
        } catch (error) {
            return {data: false, error};
        }

        return {data: true};
    };
}

export function sendPostsNotification(payload: Payload[]) {
    return async (dispatch: AppDispatch, getState: GetStateFunc): Promise<ActionResult> => {
        const state = getState();

        const postsWithMeta = payload.filter(({post, meta}) => {
            const channel = getChannel(state, {id: post.channel_id}) as Channel | undefined;

            return shouldSendNotification(state, post, meta, channel).send;
        });

        if (!postsWithMeta.length) {
            return {data: false, error: 'empty postsWithMeta'};
        }

        const userIdsToLoad = postsWithMeta.filter((p) => !p.meta.sender_name).map((p) => p.post.user_id);

        /**
         * Если у нас нет фоллбэка от бэкенда, то нам нужен пользователь
         * Но мы не хотим сильно задерживать показ нотификации
         * поэтому ждем совсем немного
         */
        if (userIdsToLoad.length) {
            await Promise.race([
                dispatch(
                    fetchUsersByIds({
                        userIds: userIdsToLoad,
                    }),
                ),
                asyncDelay(TIMEOUT),
            ]);
        }

        const updatedState = getState();

        try {
            for (const {post, meta} of postsWithMeta) {
                const channel = getChannel(state, {id: post.channel_id}) as Channel | undefined;

                sendNotification(updatedState, post, channel, meta);
            }

            return {data: true};
        } catch (error) {
            return {data: false, error};
        }
    };
}
