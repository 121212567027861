import classNames from 'classnames';
import {useIntl} from 'react-intl';

import {Heading, HeadingLevel} from '@time-webkit/all/atoms/heading';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';
import {Link} from '@time-webkit/all/atoms/link';

import {Spinner} from '@time-webkit/all/atoms/spinner';

import styles from './deep_link_transition.module.css';

type Props = {
    onContinue?: () => void;
};

export function DeepLinkTransition({onContinue}: Props) {
    const {formatMessage} = useIntl();
    const [titleClassName, textClassName] = useTypography([
        {size: TypographySize.BodyXL},
        {size: TypographySize.BodyL},
    ]);

    return (
        <div className={styles.outerWrapper}>
            <div className={styles.innerWrapper}>
                <Heading
                    className={styles.heading}
                    level={HeadingLevel.THIRD}
                >
                    {formatMessage({
                        id: 'signup.redirecting_screen.header',
                        defaultMessage: 'Joining Time',
                    })}
                </Heading>
                <p className={classNames(titleClassName, styles.body)}>
                    <span className={classNames(textClassName, styles.text)}>
                        {formatMessage({
                            id: 'signup.redirecting_screen.under_header.1',
                            defaultMessage: 'If the app doesn’t open, you can',
                        })}
                    </span>
                    <Link
                        as='button'
                        type='button'
                        size='large'
                        variant='flat'
                        onClick={onContinue}
                    >
                        {formatMessage({
                            id: 'signup.redirecting_screen.under_header.2',
                            defaultMessage: 'continue in a browser',
                        })}
                    </Link>
                </p>
            </div>
        </div>
    );
}

export function DeepLinkTransitionLoader() {
    return (
        <div className={styles.centered_container}>
            <Spinner
                size={24}
                color='rgb(var(--time-webkit-color-main-rgb))'
            />
        </div>
    );
}
