/* eslint-disable max-lines */
import type {Update} from '@reduxjs/toolkit';
import {createSlice, isAnyOf} from '@reduxjs/toolkit';

import type {Post} from '@mattermost/types/posts';

import {PostTypes, TeamTypes, UserTypes} from 'mattermost-redux/action_types';
import {ActionTypes} from 'utils/constants';

import {getAllUserThreadsBeforeThread} from '../actions/get_user_threads_before_thread';
import {setSelectedTab} from '../actions/set_selected_tab';
import {setSelectedThreadId} from '../actions/set_selected_thread_id';

import {threadsAdapter} from '../entity_adapters/thread_entity_adapter';
import type {ServerThread} from '../types/threads';
import type {Team, TeamUnread} from '@mattermost/types/teams';
import {updateThreadLastViewedAt} from '../actions/update_thread_last_viewed_at';
import {markThreadManuallyUnread} from '../actions/mark_thread_manually_unread';
import {updateThreadToastStatus} from '../actions/update_thread_toast_status';
import {getUserUnreadThreads} from '../actions/get_user_unread_threads';
import {getHasUnreadThreadsByTeamId} from '../actions/get_has_unread_threads_by_team_id';
import {handleThreadsReadChanged} from '../actions/handle_threads_read_changed';
import {markAllThreadsReadInTeam} from '../actions/mark_all_threads_read_in_team';
import {changeThreadFollow} from '../actions/change_thread_follow';
import {receivedThread} from '../actions/received_thread';
import {receivedThreadsFromPosts} from '../actions/received_threads_from_posts';
import type {ClientThread} from '../types/extended';
import {removeChannelThreads} from '../actions/remove_channel_threads';
import {removeThread} from '../actions/remove_thread';
import {removeTeamThreads} from '../actions/remove_team_threads';
import {receivedThreads} from '../actions/received_threads';
import {getLatestUserThreads} from '../actions/get_latest_user_threads';
import {markThreadFollowed} from '../actions/mark_thread_followed';
import {removePostsFromState} from 'features/posts/actions/remove_posts_except_channel';
import {getLatestUserUnreadThreads} from '../actions/get_latest_user_unread_threads';
import {unmarkThreadManuallyUnread} from '../actions/unmark_thread_manually_unread';
import {clearGlobalThreadsList} from '../actions/clear_threads_actions';

export const userThreadsSlice = createSlice({
    name: 'userThreads',
    initialState: {

        /**
         * Contains all the threads
         */
        threads: threadsAdapter.getInitialState(),
        unreadThreads: {
            loading: false,
            list: [] as Array<ClientThread['id']>,
            hasNextPage: true,
        },
        allThreads: {
            loading: false,
            list: [] as Array<ClientThread['id']>,
            hasNextPage: true,
        },

        /**
         * List of a threads followed by user
         */
        followedThreads: [] as Array<ServerThread['id']>,

        /**
         * Selected thread in threads section
         */
        selectedThreadId: '' as ServerThread['id'],

        /**
         * Selected tab in threads section
         */
        selectedTab: 'all' as 'all' | 'unread',

        /**
         * A map of unread mention count in teams
         */
        unreadMentionsCountInTeam: {} as Record<Team['id'], number>,

        /**
         * A map of has unread threads in teams
         */
        hasUnreadThreadsCountInTeam: {} as Record<Team['id'], boolean>,

        /**
         * A list of manually read threads
         */
        manuallyReadThreadIds: [] as Array<ClientThread['id']>,

        /**
         * a state for storing thread status
         */
        toastStatus: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(clearGlobalThreadsList, (state) => {
            state.allThreads.list = [];
            state.unreadThreads.list = [];
        });

        builder.addCase(setSelectedThreadId, (state, {payload}) => {
            state.selectedThreadId = payload;
        });

        builder.addCase(removePostsFromState, (state, {payload}) => {
            const {postIds} = payload;
            threadsAdapter.removeMany(state.threads, postIds);

            state.followedThreads = state.followedThreads.filter((threadId) => !postIds.includes(threadId));
        });
        builder.addCase(getAllUserThreadsBeforeThread.pending, (state) => {
            state.allThreads.loading = true;
        });
        builder.addCase(getLatestUserThreads.pending, (state) => {
            state.allThreads.loading = true;
        });
        builder.addCase(getUserUnreadThreads.pending, (state) => {
            state.unreadThreads.loading = true;
        });
        builder.addCase(getLatestUserUnreadThreads.pending, (state) => {
            state.unreadThreads.loading = true;
        });

        builder.addCase(getAllUserThreadsBeforeThread.rejected, (state) => {
            state.allThreads.loading = false;
        });

        builder.addCase(getLatestUserThreads.rejected, (state) => {
            state.allThreads.loading = false;
        });

        builder.addCase(receivedThreads, (state, {payload}) => {
            threadsAdapter.upsertMany(state.threads, payload);
        });

        builder.addCase(receivedThread, (state, {payload}) => {
            threadsAdapter.upsertOne(state.threads, payload);
        });

        builder.addCase(getUserUnreadThreads.rejected, (state) => {
            state.unreadThreads.loading = false;
        });

        builder.addCase(setSelectedTab, (state, {payload}) => {
            state.selectedTab = payload;
            state.selectedThreadId = '';
        });

        builder.addCase(getHasUnreadThreadsByTeamId.fulfilled, (state, {payload, meta}) => {
            const teamId = meta.arg.teamId;

            state.hasUnreadThreadsCountInTeam[teamId] = payload.has_unread_treads;
            state.unreadMentionsCountInTeam[teamId] = payload.total_unread_mentions;
        });

        builder.addCase(TeamTypes.SELECT_TEAM, (state) => {
            state.allThreads = {...userThreadsSlice.getInitialState().allThreads};
            state.unreadThreads = {...userThreadsSlice.getInitialState().unreadThreads};
            state.toastStatus = userThreadsSlice.getInitialState().toastStatus;
        });

        builder.addCase(removeTeamThreads, (state, {payload}) => {
            const {teamId} = payload;

            const threadIdsToDelete = threadsAdapter
                .getSelectors()
                .selectAll(state.threads)
                .filter((thread) => thread.team_id === teamId)
                .map(({id}) => id);

            threadsAdapter.removeMany(state.threads, threadIdsToDelete);

            if (threadIdsToDelete.includes(state.selectedThreadId)) {
                state.selectedThreadId = '';
            }

            state.hasUnreadThreadsCountInTeam[teamId] = false;
            state.unreadMentionsCountInTeam[teamId] = 0;
        });

        builder.addCase(removeThread, (state, {payload}) => {
            const {threadId, teamId} = payload;
            const {selectById} = threadsAdapter.getSelectors();

            if (state.selectedThreadId === threadId) {
                state.selectedThreadId = '';
            }

            const thread = selectById(state.threads, threadId);
            if (!thread) {
                return;
            }

            if (teamId) {
                /**
                 * в случае с передачей teamId при удалении также
                 * пересчитываем упоминания в обсуждениях для команды,
                 * например, если при запросе обсуждения сервер вернёт 404,
                 * то обсуждение будет не просто удалено, а удалено
                 * с обновлением значений упоминаний
                 */
                const nextMentionsCount = Math.max(
                    (state.unreadMentionsCountInTeam[teamId] ?? 0) - thread.unread_mentions,
                    0,
                );
                state.unreadMentionsCountInTeam[teamId] = nextMentionsCount;
                state.hasUnreadThreadsCountInTeam[teamId] = nextMentionsCount > 0;
            }

            threadsAdapter.removeOne(state.threads, threadId);
        });

        builder.addCase(updateThreadLastViewedAt, (state, action) => {
            const {lastViewedAt, threadId} = action.payload;

            threadsAdapter.updateOne(state.threads, {
                id: threadId,
                changes: {
                    last_viewed_at: lastViewedAt,
                },
            });

            state.manuallyReadThreadIds = state.manuallyReadThreadIds.filter((id) => id !== threadId);
        });

        builder.addCase(markThreadManuallyUnread, (state, action) => {
            const {threadId, lastViewedAt} = action.payload;
            state.manuallyReadThreadIds.push(threadId);

            threadsAdapter.updateOne(state.threads, {
                id: threadId,
                changes: {
                    last_viewed_at: lastViewedAt,
                },
            });
        });

        builder.addCase(unmarkThreadManuallyUnread, (state, action) => {
            const {threadId} = action.payload;
            state.manuallyReadThreadIds = state.manuallyReadThreadIds.filter((id) => id !== threadId);
        });

        builder.addCase(ActionTypes.SELECT_POST, (state) => {
            state.toastStatus = false;
        });
        builder.addCase(updateThreadToastStatus, (state, action) => {
            const {status} = action.payload;
            state.toastStatus = Boolean(status);
        });

        builder.addCase(UserTypes.LOGOUT_SUCCESS, () => {
            return userThreadsSlice.getInitialState();
        });

        builder.addCase(handleThreadsReadChanged, (state, {payload}) => {
            const {
                teamId,
                thread = {} as ServerThread,
                lastViewedAt,
                threadId,
                prevUnreadMentions = 0,
                newUnreadMentions = 0,
                newUnreadReplies = 0,
            } = payload;

            const currentTotalUnreadMentionsInThreadsCount = state.unreadMentionsCountInTeam[teamId] || 0;

            const unreadMentionsDiff = newUnreadMentions - prevUnreadMentions;

            const newTotalUnreadMentionsInThreadsCount = currentTotalUnreadMentionsInThreadsCount + unreadMentionsDiff;

            state.unreadMentionsCountInTeam[teamId] = Math.max(0, newTotalUnreadMentionsInThreadsCount);

            const selectThreadById = threadsAdapter.getSelectors().selectById.bind(null, state.threads);

            const prevThread = selectThreadById(threadId);

            if (prevThread?.id) {
                threadsAdapter.updateOne(state.threads, {
                    id: threadId,
                    changes: {
                        ...thread,
                        last_viewed_at: lastViewedAt,
                        unread_mentions: newUnreadMentions,
                        unread_replies: newUnreadReplies,
                    },
                });
            }
        });

        builder.addCase(markAllThreadsReadInTeam, (state, {payload}) => {
            const {teamId} = payload;

            const updates: Array<Update<ClientThread>> = threadsAdapter
                .getSelectors()
                .selectAll(state.threads)
                .filter((thread) => {
                    // У тредов в личках и групповых каналов нет team_id так как
                    // лички и групповые каналы не привязаны к команде
                    return teamId === thread.team_id || thread.team_id === '';
                })
                .map((thread) => {
                    return {
                        id: thread.id,
                        changes: {
                            unread_mentions: 0,
                            unread_replies: 0,

                            // @TODO: стоит ли обновлять тут дату просмотра?
                            last_viewed_at: Date.now(),
                        },
                    };
                });

            threadsAdapter.updateMany(state.threads, updates);

            state.unreadMentionsCountInTeam[teamId] = 0;
            state.hasUnreadThreadsCountInTeam[teamId] = false;
        });

        builder.addCase(changeThreadFollow, (state, {payload}) => {
            const {following, threadId, selectedThreadId} = payload;

            if (following && !state.followedThreads.includes(threadId)) {
                state.followedThreads.push(threadId);
            }

            if (!following && state.followedThreads.includes(threadId)) {
                state.followedThreads = state.followedThreads.filter((id) => id !== threadId);
            }

            if (!following) {
                state.manuallyReadThreadIds = state.manuallyReadThreadIds.filter((id) => id !== threadId);
            }

            if (!following && selectedThreadId !== threadId) {
                threadsAdapter.removeOne(state.threads, threadId);
            }
        });

        builder.addCase(markThreadFollowed, (state, {payload}) => {
            if (state.followedThreads.includes(payload)) {
                return;
            }

            state.followedThreads.push(payload);
        });

        builder.addCase(removeChannelThreads, (state, {payload}) => {
            const {channelId, fallbackTeamId} = payload;

            const threadsToDelete = threadsAdapter
                .getSelectors()
                .selectAll(state.threads)
                .filter((thread) => {
                    const channelIdFromPost = thread.post.channel_id;
                    const channelIdFromThread = thread.channel_id;

                    if (channelIdFromThread) {
                        return channelIdFromThread === channelId;
                    }

                    return channelIdFromPost === channelId;
                });

            if (!threadsToDelete.length) {
                return;
            }

            const threadIdsToDelete = threadsToDelete.map(({id}) => id);

            threadsAdapter.removeMany(state.threads, threadIdsToDelete);

            for (const thread of threadsToDelete) {
                const teamId = thread.team_id || fallbackTeamId;

                const currentUnreadMentionsInThreadsCount = state.unreadMentionsCountInTeam[teamId] || 0;

                state.unreadMentionsCountInTeam[teamId] = Math.max(
                    currentUnreadMentionsInThreadsCount - thread.unread_mentions,
                    0,
                );
            }
        });

        builder.addCase(PostTypes.RECEIVED_NEW_POST, (state, action) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const post = action.data as Post;

            const selectThreadById = threadsAdapter.getSelectors().selectById.bind(null, state.threads);

            const thread = selectThreadById(post.root_id);

            if (post.root_id && thread) {
                const participants = thread.participants || [];

                const nextThread = {...thread};

                if (post.user_id && !participants.find((user) => user.id === post.user_id)) {
                    nextThread.participants = [...participants, {id: post.user_id}];
                }

                if (post.reply_count) {
                    nextThread.reply_count = post.reply_count || nextThread.reply_count;
                }

                nextThread.last_reply_at = post.create_at;

                threadsAdapter.updateOne(state.threads, {
                    id: thread.id,
                    changes: {
                        ...nextThread,
                    },
                });
            }
        });

        builder.addCase(receivedThreadsFromPosts, (state, {payload}) => {
            const selectThreadById = threadsAdapter.getSelectors().selectById.bind(null, state.threads);

            const newFollowedThreadIds: string[] = [];
            const newThreads: ClientThread[] = [];

            for (const post of payload) {
                if (post.is_following === true) {
                    newFollowedThreadIds.push(post.id);
                }

                const prevThread = selectThreadById(post.id);

                newThreads.push({
                    ...post,
                    reply_count: post.reply_count,
                    last_reply_at: post.last_reply_at as number,
                    participants: post.participants ?? prevThread?.participants ?? [],
                    post,
                    unread_mentions: prevThread?.unread_mentions ?? 0,
                    unread_replies: prevThread?.unread_replies ?? 0,
                    last_viewed_at: prevThread?.last_viewed_at ?? 0,
                    id: post.id,
                    team_id: post.team_id,
                    channel_id: post.channel_id,
                });
            }

            if (newThreads.length) {
                threadsAdapter.upsertMany(state.threads, newThreads);
            }

            if (newFollowedThreadIds.length) {
                state.followedThreads.push(...newFollowedThreadIds);
            }
        });

        builder.addCase(TeamTypes.RECEIVED_MY_TEAM_UNREADS, (state, action) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const teamsUnreads = action.data as TeamUnread[];

            teamsUnreads.forEach((teamUnreads) => {
                if (typeof teamUnreads.thread_count !== 'undefined') {
                    state.hasUnreadThreadsCountInTeam[teamUnreads.team_id] = Math.max(teamUnreads.thread_count, 0) > 0;
                }

                if (typeof teamUnreads.thread_mention_count !== 'undefined') {
                    state.unreadMentionsCountInTeam[teamUnreads.team_id] = Math.max(
                        teamUnreads.thread_mention_count,
                        0,
                    );
                }
            });
        });

        builder.addMatcher(
            isAnyOf(
                getAllUserThreadsBeforeThread.fulfilled,
                getLatestUserThreads.fulfilled,
                getLatestUserUnreadThreads.fulfilled,
                getUserUnreadThreads.fulfilled,
            ),
            (state, action) => {
                const {payload, meta} = action;
                const teamId = meta.teamId;

                const receivedNewIds = payload.threads.map((thread) => thread.id);

                // Мы тут точно знаем что пользователь следит за этими следами
                state.followedThreads.push(...receivedNewIds.filter((id) => !state.followedThreads.includes(id)));

                state.hasUnreadThreadsCountInTeam[teamId] = payload.total_unread_threads > 0;
                state.unreadMentionsCountInTeam[teamId] = payload.total_unread_mentions;

                if (
                    getUserUnreadThreads.fulfilled.match(action) ||
                    getLatestUserUnreadThreads.fulfilled.match(action)
                ) {
                    state.unreadThreads.loading = false;
                    state.unreadThreads.list.push(
                        ...receivedNewIds.filter((id) => !state.unreadThreads.list.includes(id)),
                    );
                    state.unreadThreads.hasNextPage = payload.threads.length === meta.pageSize;
                } else {
                    state.allThreads.loading = false;
                    state.allThreads.list.push(...receivedNewIds.filter((id) => !state.allThreads.list.includes(id)));
                    state.allThreads.hasNextPage = payload.threads.length === meta.pageSize;
                }
            },
        );
    },
});
