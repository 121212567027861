import React, {type ComponentProps, type SyntheticEvent} from 'react';
import {Overlay} from 'react-bootstrap';
import classnames from 'classnames';

import {Avatar} from '@time-webkit/all/atoms/avatar';

import type {UserStatus} from 'packages/ui-kit/src/types/general';

import ProfilePopover from 'components/profile_popover';
import StatusIcon from 'components/status_icon';

import './profile_picture.scss';

type Props = {

    /**
     * id родительского элемента, нужно указать, если UserProfile располагается внутри модалки
     */
    appendId?: string;
    userId?: string;
    showProfilePopover?: boolean;
    hasMention?: boolean;
    isBusy?: boolean;
    isEmoji?: boolean;
    isRHS?: boolean;
    profileSrc?: string;
    size?: ComponentProps<typeof Avatar>['size'];
    src: string;
    status?: UserStatus;
    channelId?: string;
    username?: string;
    wrapperClass?: string;
    overwriteIcon?: string;
    overwriteName?: string;
    statusClass?: string;
    isBot?: boolean;
    fromWebhook?: boolean;
    fromAutoResponder?: boolean;
    popoverPlacement?: string;
    onProfilePopoverOpen?: () => void;
    onProfilePopoverClose?: () => void;
    testId?: string;
};

type State = {
    show: boolean;
}

export default class ProfilePicture extends React.PureComponent<Props, State> {
    public static get defaultProps(): Partial<Props> {
        return {
            size: 36,
            isRHS: false,
            isEmoji: false,
            hasMention: false,
            wrapperClass: '',
            popoverPlacement: 'right',
        };
    }

    triggerRef = React.createRef<HTMLButtonElement>();

    constructor(props: Props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    private hideProfilePopover = (e?: SyntheticEvent<HTMLElement>) => {
        if (e?.target && this.triggerRef?.current?.contains(e.target as Element)) {
            return;
        }

        this.setState({show: false});
        this.props.onProfilePopoverClose?.();
    }

    private showProfilePopover = (e?: SyntheticEvent) => {
        if (e?.target && this.triggerRef?.current?.contains(e.target as Element)) {
            this.setState((prevState) => ({show: !prevState.show}));
            if (this.state.show) {
                this.props.onProfilePopoverClose?.();
            } else {
                this.props.onProfilePopoverOpen?.();
            }
        }
    }

    get renderAvatar() {
        const profileIconClass = `profile-icon profile-icon--${this.props.size} ${this.props.isEmoji ? 'emoji' : ''}`;

        return (
            <span className={profileIconClass} data-testid={this.props.testId}>
                {this.props.src && (
                    <Avatar
                        username={this.props.username}
                        size={this.props.size}
                        src={this.props.src}
                    />
                )}
            </span>
        );
    }

    public render() {
        // profileSrc will, if possible, be the original user profile picture even if the icon
        // for the post is overriden, so that the popup shows the user identity
        const profileSrc = (typeof this.props.profileSrc === 'string' && this.props.profileSrc !== '') ? this.props.profileSrc : this.props.src;

        const hideStatus = this.props.isBot || this.props.fromAutoResponder || this.props.fromWebhook;
        const statusIconSizeClass = `status-icon-${this.props.size}`;

        if (this.props.showProfilePopover && this.props.userId) {
            return (
                <button
                    ref={this.triggerRef}
                    className={`status-wrapper style--none ${this.props.wrapperClass}`}
                    tabIndex={-1}
                    onClick={this.showProfilePopover}
                >
                    <Overlay
                        placement={this.props.popoverPlacement}
                        show={this.state.show}
                        rootClose={true}
                        target={this.triggerRef?.current as Element}
                        onHide={this.hideProfilePopover}
                    >
                        <ProfilePopover
                            appendId={this.props.appendId}
                            userId={this.props.userId}
                            src={profileSrc}
                            hide={this.hideProfilePopover}
                            isRHS={this.props.isRHS}
                            channelId={this.props.channelId}
                            hasMention={this.props.hasMention}
                            overwriteIcon={this.props.overwriteIcon}
                            overwriteName={this.props.overwriteName}
                            fromWebhook={this.props.fromWebhook}
                            hideStatus={hideStatus}
                        />
                    </Overlay>
                    {this.renderAvatar}
                    <StatusIcon className={statusIconSizeClass} status={this.props.status}/>
                </button>
            );
        }

        return (
            <span className={`status-wrapper style--none ${this.props.wrapperClass}`}>
                {this.renderAvatar}
                <StatusIcon
                    status={this.props.status}
                    className={classnames(this.props.statusClass, statusIconSizeClass)}
                />
            </span>
        );
    }
}
