import store from 'stores/redux_store';
import {getChannel} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentUser} from 'mattermost-redux/selectors/entities/common';
import {ChannelTypes} from 'mattermost-redux/action_types';
import {isDmChannel} from 'features/sidebar/utils/isDmChannel';
import {isGmChannel} from 'features/sidebar/utils/isGmChannel';
import {getPostThread, postDeleted, receivedPost} from 'mattermost-redux/actions/posts';
import {deleteActivityItems} from 'features/activity/actions';
import {getPostsInThreadIds} from 'features/threads/selectors/get_posts_for_root_post';
import {batchActions} from 'utils/batch_actions';
import {selectThreadById} from 'features/threads/selectors/select_thread_by_id';
import {fetchUserThread} from 'features/threads/actions/fetch_user_thread';
import {getChannelAndMyMember, getChannelStats} from 'mattermost-redux/actions/channels';
import type {DeleteEvent} from 'features/posts/types/delete_event';
import {getMentionsFromMessageProps} from 'features/notifications/actions/send_post_notification/utils';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {isThreadOpened} from 'features/threads/selectors/is_thread_opened';
import {closeRightHandSide, cleanSelectedPost} from 'actions/views/rhs';
import {getSelectedPostId} from 'selectors/rhs';

export async function handlePostDeleteEvent(message: DeleteEvent) {
    const post = JSON.parse(message.data.post);
    let state = store.getState();
    let channel = getChannel(state, post.channel_id);
    const user = getCurrentUser(state);
    const currentTeamId = getCurrentTeamId(state);

    const actions = [];
    const hasRootId = Boolean(post.root_id);

    // Для эфемерных сообщений channel_id может отсутствовать
    if (!channel && post.channel_id) {
        /**
         * Канал может быть не загружен, если пользователь никогда не
         * заходил в команду, в которой его упоминали и удаляли сообщение
         */
        await store.dispatch(getChannelAndMyMember(post.channel_id));
        state = store.getState();
        channel = getChannel(state, post.channel_id);
    }

    if (channel) {
        actions.push(
            {
                type: ChannelTypes.DECREMENT_UNREAD_MSG_COUNT,
                data: {
                    teamId: channel.team_id,
                    channelId: channel.id,
                    amount: 1,
                    amountRoot: hasRootId ? 0 : 1,
                },
            });

        actions.push(
            {
                type: ChannelTypes.DECREMENT_TOTAL_MSG_COUNT,
                data: {
                    channelId: channel.id,
                    amount: 1,
                    amountRoot: hasRootId ? 0 : 1,
                },
            },
        );

        const mentionedUserIds = getMentionsFromMessageProps(message.data);
        const hasBeenMentioned = typeof user !== 'undefined' && mentionedUserIds.includes(user.id);
        if (isDmChannel(channel) || isGmChannel(channel) || hasBeenMentioned) {
            actions.push({
                type: ChannelTypes.DECREMENT_UNREAD_MENTION_COUNT,
                data: {
                    teamId: channel.team_id,
                    channelId: channel.id,
                    amount: 1,
                    amountRoot: hasRootId ? 0 : 1,
                },
            });
        }
    }

    actions.push(postDeleted(post));
    actions.push(deleteActivityItems(new Set([post.id].concat(getPostsInThreadIds(state, post.id)))));

    if (isThreadOpened(state, post.id)) {
        actions.push(closeRightHandSide());
    } else if (post.id === getSelectedPostId(state)) {
        actions.push(cleanSelectedPost());
    }

    store.dispatch(batchActions(actions));
    window.postMessage({type: 'post_deleted', postId: post.id}, window.location.origin);

    // update thread when a comment is deleted and CRT is on
    if (hasRootId && user) {
        const thread = selectThreadById(state, post.root_id);
        if (thread) {
            await store.dispatch(
                fetchUserThread({
                    teamId: channel?.team_id || currentTeamId,
                    userId: user.id,
                    threadId: post.root_id,
                    extended: true,
                }),
            );
        } else {
            const res = await store.dispatch(getPostThread(post.root_id));

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (res.error?.status_code && res.error?.status_code === 404) {
                // Не смогли получить тред и он удален...кажется?
                return;
            }

            // https://time-sentry.tinkoff.ru/organizations/sentry/issues/1470/
            if (!res.data) {
                return;
            }

            const {order, posts} = res.data;
            const rootPostId = order[0];

            if (rootPostId) {
                const rootPost = posts[rootPostId];

                if (rootPost) {
                    store.dispatch(receivedPost(rootPost));
                }
            }
        }
    }

    if (post.is_pinned) {
        store.dispatch(getChannelStats(post.channel_id));
    }
}
