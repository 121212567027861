import {Extension} from '@tiptap/core';
import marked from 'packages/marked';

// Renderer для корректного отображения сообщения, после добавления отрисовки переносов в marked.
// https://gitlab.tcsbank.ru/messenger/webapp/-/merge_requests/917
// https://jira.tcsbank.ru/browse/TIME-6147
class Renderer extends marked.Renderer {
    public code(...args: Parameters<marked.Renderer['code']>) {
        return super.code(...args).trimEnd();
    }

    public blockquote(quote: string) {
        return '<blockquote>' + quote + '</blockquote>';
    }

    public paragraph(text: string) {
        return text.split('\n').map((i) => `<p>${i}</p>`).join('');
    }

    public heading(...args: Parameters<marked.Renderer['heading']>) {
        return super.heading(...args).trimEnd();
    }

    public hr() {
        return super.hr().trimEnd();
    }

    public list(body: string, ordered: boolean, start: number) {
        const type = ordered ? 'ol' : 'ul';

        let out = '<' + type;
        if (start && start !== 1) {
            out += 'start="' + start + '"';
        }
        out += '>' + body + '</' + type + '>';

        return out;
    }

    public listitem(text: string) {
        return super.listitem(text).trimEnd();
    }

    public extendedbr() {
        return '<p></p>';
    }
}

export const parseHtmlFromMarkdown = (markdown: string) => marked(markdown, {
    renderer: new Renderer({
        breaks: true,
    }),
});

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        markdown: {
            insertMarkdown: (mrkdwn: string) => ReturnType;
            setMarkdown: (mrkdwn: string, emitUpdate: boolean) => ReturnType;
        };
    }
}

export const TimeWebkitMarkdownExtension = Extension.create({
    name: 'timeWebkitMarkdown',

    addCommands() {
        return {
            insertMarkdown: (inputMarkdown: string) => ({commands}) => {
                const html = parseHtmlFromMarkdown(inputMarkdown);

                return commands.insertContent(html, {parseOptions: {preserveWhitespace: 'full'}});
            },
            setMarkdown: (inputMarkdown: string, emitUpdate: boolean) => ({commands}) => {
                const html = parseHtmlFromMarkdown(inputMarkdown);

                return commands.setContent(html, emitUpdate, {preserveWhitespace: 'full'});
            },
        };
    },
});
