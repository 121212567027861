import {AxiosRequestConfig} from 'axios';

import type {Group} from '@mattermost/types/groups';

import {makeGetRequest} from '../request';

import {composeGroupsRoute} from './helpers';

type GetGroupsRequestParams = {

    /**
     * The page to select.
     *
     * @default 0
     */
    page?: number;

    /**
     * The number of groups per page.
     *
     * @default 60
     */
    per_page?: number;

    /**
     * String to pattern match the name and `display_name` field.
     * Will return all groups whose `name` and `display_name` field match any of the text.
     */
    q?: string;

    /**
     * Boolean which adds the member_count attribute to each group JSON object
     */
    include_member_count?: boolean;

    /**
     * Team GUID which is used to return all the groups not associated to this team
     *
     * If you use `not_associated_to_team`, you must be a team admin for that particular team (permission to manage that team).
     */
    not_associated_to_team?: string;

    /**
     * Group GUID which is used to return all the groups not associated to this channel
     *
     * If you `use not_associated_to_channel`, you must be a channel admin for that particular channel (permission to manage that channel).
     */
    not_associated_to_channel?: string;

    /**
     * Only return groups that have been modified since the given Unix timestamp (in milliseconds).
     * All modified groups, including deleted and created groups, will be returned.
     */
    since?: number;

    /**
     * Boolean which filters the group entries with the `allow_reference` attribute set.
     *
     * @default false
     */
    filter_allow_reference?: boolean;

    group_source?: 'custom' | 'ldap';

    /**
     * Filters the groups to the intersect of the set associated
     * to the parent team and those returned by the query.
     *
     * If the parent team is not group-constrained or if `not_associated_to_channel`
     * is not set then this option is ignored.
     */
    filter_parent_team_permitted?: boolean;

    /**
     * Filters the groups to the intersect of the set returned by the query and
     * those that have the given user as a member.
     */
    filter_has_member?: string;
};

/**
 * Retrieve a list of all groups not associated to a particular channel or team.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetGroups
 */
export function getGroups(args: GetGroupsRequestParams, options: AxiosRequestConfig<never> = {}) {
    const params: GetGroupsRequestParams = {
        not_associated_to_channel: args.not_associated_to_channel,
        not_associated_to_team: args.not_associated_to_team,
        filter_allow_reference: args.filter_allow_reference || false,
        include_member_count: args.include_member_count,
        page: args.page || 0,
        per_page: args.per_page || 60,
        q: args.q,
        since: args.since,
    };
    const route = composeGroupsRoute('');

    return makeGetRequest<Group[]>(route, {
        ...options,
        params,
    });
}
