import {type DialogElement} from 'mattermost-redux/types/integrations';

type DialogError = {
    id: string;
    defaultMessage: string;
    values?: any;
};
export function checkDialogElementForError(elem: DialogElement, value: any): DialogError | undefined | null {
    if (!value && !elem.optional) {
        return {
            id: 'interactive_dialog.error.required',
            defaultMessage: 'Fill in the field',
        };
    }

    const type = elem.type;

    if (type === 'text' || type === 'textarea') {
        if (value && value.length < elem.min_length) {
            return {
                id: 'interactive_dialog.error.too_short',
                defaultMessage: 'You must add at least {minLength} {minLength, plural, one {character} few {characters} other {characters}}',
                values: {minLength: elem.min_length},
            };
        }

        if (value && elem.max_length > 0 && value.length > elem.max_length) {
            return {
                id: 'interactive_dialog.error.too_long',
                defaultMessage: 'You can add up to {maxLength} {maxLength, plural, one {character} few {characters} other {characters}}',
                values: {maxLength: elem.max_length},
            };
        }

        if (elem.subtype === 'email') {
            if (value && !value.match(/^\S+@\S+\.\S+$/)) {
                return {
                    id: 'interactive_dialog.error.bad_email',
                    defaultMessage: 'Check email format',
                };
            }
        }

        if (elem.subtype === 'number') {
            if (value && isNaN(value)) {
                return {
                    id: 'interactive_dialog.error.bad_number',
                    defaultMessage: 'Use only numbers',
                };
            }
        }

        if (elem.subtype === 'url') {
            if (value && !value.includes('http://') && !value.includes('https://')) {
                return {
                    id: 'interactive_dialog.error.bad_url',
                    defaultMessage: 'The link should start with http:// or https://',
                };
            }
        }
    } else if (type === 'radio') {
        const options = elem.options;

        if (typeof value !== 'undefined' && Array.isArray(options) && !options.some((e) => e.value === value)) {
            return {
                id: 'interactive_dialog.error.invalid_option',
                defaultMessage: 'Select one of the options',
            };
        }
    }

    return null;
}

// If we're returned errors that don't match any of the elements we have,
// ignore them and complete the dialog

export function checkIfErrorsMatchElements(errors: {
    [x: string]: DialogError;
} = {}, elements: DialogElement[] = []) {
    for (const name in errors) {
        if (!errors.hasOwnProperty(name)) {
            continue;
        }
        for (const elem of elements) {
            if (elem.name === name) {
                return true;
            }
        }
    }

    return false;
}
