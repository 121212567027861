import {ErrorPageTypes} from 'utils/constants';

import type {UserProfile} from 'mattermost-redux/types/users';

import type {ClientConfig, ClientLicense} from 'mattermost-redux/types/config';

import {isGuestPlus} from 'mattermost-redux/utils/user_utils';

export const notFoundParams = {
    type: ErrorPageTypes.PAGE_NOT_FOUND,
};

const mfaPaths = ['/mfa/setup', '/mfa/confirm'];

const mfaAuthServices = ['', 'email', 'ldap'];

export function checkIfMFARequired(
    user: UserProfile | undefined,
    license: ClientLicense,
    config: Partial<ClientConfig>,
    path: string,
): boolean {
    const isMFALicence = license.MFA === 'true';
    const isGuestUser = user && isGuestPlus(user.roles);

    if (user && !user.mfa_active && isMFALicence && config.MultifactorAuthenticationType !== 'SMS' && mfaPaths.indexOf(path) === -1 && mfaAuthServices.indexOf(user.auth_service) !== -1) {
        if (isGuestUser && config.GuestAccountsEnforceMultifactorAuthentication === 'true' && config.GuestAccountsEnableMultifactorAuthentication === 'true') {
            return true;
        }

        if (!isGuestUser && config.EnforceMultifactorAuthentication === 'true' && config.EnableMultifactorAuthentication === 'true') {
            return true;
        }
    }

    return false;
}

export const SIGNIN_ROUTE = '/auths';
