import {type FC, type ReactNode, useEffect, useLayoutEffect} from 'react';
import {useEditor, EditorContent} from '@tiptap/react';
import type {Editor as IEditor} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import classNames from 'classnames';

import {Extension, extensions} from '@tiptap/core';

import {type MarkdownSerializer} from 'prosemirror-markdown';

import {TimeEditoHashtagMentionExtension} from 'features/hashtags/extension';

import {urlRegex} from 'utils/paste';

import {getSelectedText} from 'utils/utils';

import {Slice, Fragment, DOMParser} from 'prosemirror-model';

import type {Features, Props} from './types';
import {EditorContext, useEditorContext} from './context';
import {
    TimeWebkitBlockquoteExtension,
    TimeWebkitBulletListExtension,
    TimeWebkitHardBreakExtension,
    TimeWebkitHeadingExtension,
    TimeWebkitLinkExtension,
    TimeWebkitListItemExtension,
    TimeWebkitOrderedListExtension,
    TimeWebkitParagraphExtension,
    TimeWebkitTextExtension,
} from './extensions/editor-custom-extensions';
import editorStyles from './editor.module.css';
import {FormattingBar} from './components/formatting-bar';
import {ActionsBar} from './components/actions-bar';
import {TimeEditorMentionExtension} from './extensions/mention';

import {keymap} from './keydownHandler';
import {TimeWebappEditorKeymapExtension} from './extensions/keymap';

import {TimeWebkitMarkdownExtension, parseHtmlFromMarkdown} from './markdown-extension';
import {TimeEditorChannelMentionExtension} from './extensions/channel';
import {TimeEditorSlashCommandExtension} from './extensions/slash-command';
import {getMarkdownSerializer} from './utils';
import {TimeWebkitEditorPlaceholderPlugin} from './extensions/placeholder';
import {TimeWebkitCodeBlockExtension} from './extensions/code-block';
import {TimeWebkitEmojiExtension} from './extensions/emoji-search';
import {LimitCounter} from './components/limit-counter';

const Content: FC<{
    bodySlot?: ReactNode;
    actionsSlot?: ReactNode;
}> = (props) => {
    const {actionsSlot, bodySlot} = props;
    const {editor, maxCharacters, charactersCount} = useEditorContext();

    return (
        <div className={classNames(editorStyles.editorWrapper, editor?.isFocused && editorStyles.isFocused)}>
            <FormattingBar />
            {editor ? (
                <div className={editorStyles.limitCounterWrapper}>
                    <EditorContent
                        className={editorStyles.editorOuterWrapper}
                        editor={editor}
                    />
                    <LimitCounter
                        max={maxCharacters}
                        current={charactersCount}
                        className={editorStyles.limitCounter}
                    />
                </div>
            ) : null}
            <div className={editorStyles.bodySlot}>{bodySlot}</div>
            <ActionsBar>{actionsSlot}</ActionsBar>
        </div>
    );
};

function cleanMarkdown(text: string): string {
    return text
        .replace(/\\-{1}\s/gm, '- ')
        .replace(/\\\n{1}/gm, '\n')
        .replace(/\\`{1}/gm, '`')
        .replace(/\\_{1}/gm, '_');
}

function getNodesFromHtmlString(html: string) {
    const innerHtml = html.trim();
    if (!innerHtml) {
        return null;
    }

    const template = document.createElement('template');
    template.innerHTML = innerHtml;
    const result = template.content.children;

    return result;
}

const coreExtensions = [
    extensions.ClipboardTextSerializer,
    extensions.Commands,
    extensions.Editable,
    extensions.FocusEvents,
    extensions.Tabindex,
];

const EMPTY_ARRAY: any[] = [];

declare global {
    interface Window {
        __EDITOR__: IEditor | null;
    }
}

export const Editor = (props: Props) => {
    const {
        className,
        autofocus,
        features = {},
        onEnterPress,
        onArrowUpPress,
        onReplyLastPost,
        onReactLastPost,
        channelId,
        teamId,
        ctrlSend,
        codeBlockOnCtrlEnter,
        charactersCount,
        maxCharacters,
        placeholder,
        predefinedMentions = EMPTY_ARRAY,
        predefinedChannels = EMPTY_ARRAY,
        predefinedHashtags = EMPTY_ARRAY,
        rootId,
        setEditor,
        fileAttachmentSlot,
        sendMenuSlot,
        onContentChange,
        onCaretPositionChange,
        draftMessage,
        onEscPress,
        withinThread = false,
        useCustomGroupMentions,
        useLDAPGroupMentions,
    } = props;
    const {codeBlock, emoji, mention} = features as Features;

    const hasMentions = mention?.isActive;

    const editorExtensions = [
        ...coreExtensions,
        TimeWebappEditorKeymapExtension.configure({ctrlSend, codeBlockOnCtrlEnter}),
        StarterKit.configure({
            heading: false,
            paragraph: false,
            blockquote: false,
            orderedList: false,
            bulletList: false,
            hardBreak: false,
            listItem: false,
            horizontalRule: false,
            text: false,
            codeBlock: false,
        }),
        TimeWebkitTextExtension,
        TimeWebkitHeadingExtension,
        TimeWebkitParagraphExtension,
        TimeWebkitCodeBlockExtension.configure({ctrlSend: ctrlSend || codeBlockOnCtrlEnter}),
        TimeWebkitBlockquoteExtension.configure({
            ctrlSend,
        }),
        TimeWebkitOrderedListExtension,
        TimeWebkitBulletListExtension,
        TimeWebkitLinkExtension,
        TimeWebkitListItemExtension.configure({
            ctrlSend,
        }),
        Extension.create({
            addProseMirrorPlugins() {
                return [
                    keymap({
                        'Shift-ArrowUp': () => {
                            return Boolean(onReplyLastPost?.());
                        },
                        'Shift-Mod-\\': () => {
                            return Boolean(onReactLastPost?.());
                        },
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        Enter: (state, __, ___, event) => {
                            const isIntoCodeBlock = state.selection.$from.parent.type.name === TimeWebkitCodeBlockExtension.name;
                            return !(ctrlSend || (codeBlockOnCtrlEnter && isIntoCodeBlock)) && Boolean(onEnterPress?.(event as unknown as React.KeyboardEvent<Element>));
                        },
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        ArrowUp: (_, __, ___, event) => {
                            return Boolean(onArrowUpPress?.(event as unknown as React.KeyboardEvent<Element>));
                        },
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        'Mod-Enter': (state, __, ___, event) => {
                            const isIntoCodeBlock = state.selection.$from.parent.type.name === TimeWebkitCodeBlockExtension.name;
                            return Boolean(ctrlSend || (codeBlockOnCtrlEnter && isIntoCodeBlock)) && Boolean(onEnterPress?.(event as unknown as React.KeyboardEvent<Element>));
                        },
                        Escape: () => {
                            onEscPress?.();
                            return true;
                        },
                    }),
                ];
            },
        }),
        TimeWebkitEditorPlaceholderPlugin.configure({
            placeholder,
        }),
        TimeWebkitMarkdownExtension,
        TimeWebkitHardBreakExtension.configure({
            ctrlSend,
        }),
    ];

    if (hasMentions) {
        editorExtensions.push(
            TimeEditorMentionExtension.configure({
                channelId,
                teamId,
                predefinedMentions,
                withinThread,
                useGroupMentions: useCustomGroupMentions || useLDAPGroupMentions,
            }),
        );
    }

    editorExtensions.push(
        TimeEditorChannelMentionExtension.configure({
            teamId,
            predefinedChannels,
            withinThread,
        }),
    );

    editorExtensions.push(
        TimeEditoHashtagMentionExtension.configure({
            teamId,
            predefinedHashtags,
            withinThread,
        }),
    );

    editorExtensions.push(
        TimeEditorSlashCommandExtension.configure({
            teamId,
            channelId,
            rootId,
            withinThread,
        }),
    );

    editorExtensions.push(
        TimeWebkitEmojiExtension.configure({withinThread}),
    );

    const editor = useEditor(
        {
            editorProps: {
                attributes: {
                    class: classNames(editorStyles.editor, className),
                    id: 'post_textbox',
                },
                handleKeyDown: (_, e) => {
                    if (!(e.altKey || e.shiftKey || e.ctrlKey || e.metaKey)) {
                        e.cancelBubble = true;
                    }
                },
                clipboardTextParser: (text, $context, plainText, view) => {
                    const dom = document.createElement('div');

                    const html = parseHtmlFromMarkdown(text);
                    const nodes = getNodesFromHtmlString(html);

                    if (nodes) {
                        dom.append(...nodes);
                    }

                    return DOMParser.fromSchema(view.state.schema).parseSlice(dom, {
                        preserveWhitespace: true,
                        context: $context,
                    });
                },
                handlePaste: (view, clip) => {
                    const selection = view.state.tr.selection;
                    if (selection.from === selection.to) {
                        return false;
                    }

                    const selected = getSelectedText();
                    const urlRe = urlRegex();
                    if (selected && urlRe.test(selected)) {
                        const text = clip.clipboardData?.getData('text/plain');
                        if (!text) {
                            return false;
                        }

                        const marks = [];
                        const mark = view.state.schema.marks.link;
                        if (mark) {
                            const href = text.trim();
                            marks.push(mark.create({title: href, href}));
                        }
                        const node = view.state.schema.text(text, marks);
                        const tr = view.state.tr.replaceRange(
                            selection.from, selection.to, (new Slice(Fragment.from(node), 0, 0)));
                        view.dispatch(tr);
                        return true;
                    }

                    return false;
                },
            },
            onTransaction(props) {
                onCaretPositionChange?.(props.editor.state.selection.anchor || 0);
            },
            onUpdate(props) {
                const serializer: MarkdownSerializer = getMarkdownSerializer(props.editor.schema);
                const node = props.editor.schema.nodeFromJSON(props.editor.getJSON());
                const mdContent = serializer.serialize(node, {
                    tightLists: false,
                });

                const newContent = cleanMarkdown(mdContent);

                onContentChange?.(newContent);
            },
            extensions: editorExtensions,
            enableCoreExtensions: false,
            parseOptions: {
                preserveWhitespace: 'full',
            },
        },
        [
            channelId,
            teamId,
            ctrlSend,
            codeBlockOnCtrlEnter,
            predefinedMentions,
            predefinedChannels,
            rootId,
            onReactLastPost,
            onReplyLastPost,
            onArrowUpPress,
            onEnterPress,
            onCaretPositionChange,
            onContentChange,
            useCustomGroupMentions,
            useLDAPGroupMentions,
        ],
    );
    if (editor) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        editor.getMarkdown = () => {
            const serializer: MarkdownSerializer = getMarkdownSerializer(editor.schema);
            const node = editor.schema.nodeFromJSON(editor.getJSON());

            const mdContent = serializer.serialize(node, {
                tightLists: false,
            });

            const newContent = cleanMarkdown(mdContent);

            return newContent;
        };
    }

    useLayoutEffect(() => {
        // Выставляем сообщение из драфта только если окно набора сообщения пустое
        if (!editor?.isEmpty) {
            return;
        }

        if (!draftMessage.length) {
            return;
        }

        editor?.chain().setMarkdown(draftMessage, false).focus().run();
    }, [draftMessage, editor]);

    useEffect(() => {
        setEditor?.(editor);
    }, [editor, setEditor]);

    // @TODO: убрать после вывода из фиче флага
    useLayoutEffect(() => {
        // eslint-disable-next-line no-underscore-dangle
        window.__EDITOR__ = editor;

        return () => {
            // eslint-disable-next-line no-underscore-dangle
            window.__EDITOR__ = null;
        };
    }, [editor]);

    if (autofocus) {
        editor?.commands.focus();
    }

    return (
        <EditorContext.Provider
            value={{
                editor: editor || undefined,
                charactersCount,
                maxCharacters,
                features: {
                    codeBlock,
                    emoji,
                    mention,
                },
            }}
        >
            <Content
                bodySlot={fileAttachmentSlot}
                actionsSlot={sendMenuSlot}
            />
        </EditorContext.Provider>
    );
};
