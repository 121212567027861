import {createAsyncThunk} from '@reduxjs/toolkit';

import {isAxiosError} from 'axios';

import type {Team} from '@mattermost/types/teams';

import type {User} from 'features/users';

import type {AppDispatch} from 'stores/redux_store';
import {getUserThread} from '../api/get_user_thread';

import type {ClientThread} from '../types/extended';

import {reportErrorToSentry} from 'utils/sentry';

import {handleThreadArrived} from './handle_thread_arrived';
import {changeThreadFollow} from './change_thread_follow';
import {removeThread} from './remove_thread';

type Payload = {
    userId: User['id'];
    teamId: Team['id'];
    threadId: ClientThread['id'];
    extended?: boolean;
};

export const fetchUserThread = createAsyncThunk(
    'threads/actions/fetchUserThread',
    async (payload: Payload, thunkAPI) => {
        const {teamId, threadId, userId, extended = true} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        try {
            const thread = await getUserThread({
                teamId,
                threadId,
                userId,
                extended,
            });

            await dispatch(
                handleThreadArrived({
                    teamId,
                    thread,
                }),
            );

            /**
             * getUserThread может отдать успешно тред только в случае
             * если пользователь является участником треда и подписан на него
             * поэтому мы здесь смело можем пометить что он следует за этим тредом
             */
            dispatch(changeThreadFollow({
                following: true,
                threadId,
            }));

            return thread;
            // eslint-disable-next-line no-empty
        } catch (error: unknown) {
            let shouldReportError = true;
            if (isAxiosError(error)) {
                if (error.response?.status === 404) {
                    /**
                     * Если запрашиваемого обсуждения нет на сервере
                     * то удаляем его и на клиенте
                     */
                    dispatch(removeThread({threadId, teamId}));
                    shouldReportError = false;
                }
            }

            if (shouldReportError) {
                reportErrorToSentry(error);
            }
        }
    },
);
