import TipTapMentionExtension, {MentionOptions} from '@tiptap/extension-mention';
import {ReactRenderer} from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import {autocompleteEmoji} from 'mattermost-redux/api/emoji/autocompleteEmoji';
import {PluginKey} from 'prosemirror-state';

import {Constants} from 'utils/constants';

import store from 'stores/redux_store';

import {EmojiTypes} from 'mattermost-redux/action_types';

import {getEmojiMap, getRecentEmojis, getUserSkinTone, isCustomEmojiEnabled} from 'selectors/emojis';
import {emojiMatchesSkin} from 'utils/emoticons';
import {compareEmojis} from 'utils/emoji_utils';

import {isSystemEmoji} from 'mattermost-redux/utils/emoji_utils';

import type {GlobalState} from 'types/store';

import {sendToStatist, SearchTimeMeasurer} from '@time-webkit/statist';

import {transformAutocompleteEmojiResponse} from './transformers';
import {EmojiList} from './components/emoji-list';
import type {EmojiMention, MatchedEmoji} from './types';

export const TIMEOUT = 3 * 1000; // 3s

const emptyEmojis: EmojiMention[] = [];

const searchTimeMeasurer = new SearchTimeMeasurer('editor.searchEmoji.result');

export const TimeWebkitEmojiExtension = TipTapMentionExtension.extend<{
    withinThread: boolean;
} & MentionOptions>({
    name: 'TimeWebkitEmojiExtension',
    addStorage() {
        return {
            abortController: null,
            error: null,
        };
    },

    addAttributes() {
        return {
            label: {
                default: null,
                parseHTML: (element) => element.getAttribute('aria-label'),
                renderHTML: (attributes) => {
                    return {
                        'aria-label': attributes.label,
                    };
                },
            },
            role: {
                default: 'img',
                renderHTML: () => ({
                    role: 'img',
                }),
                parseHTML: (element) => element.getAttribute('role'),
            },
            url: {
                default: null,
                renderHTML: (attributes) => ({
                    'data-url': attributes.url,
                }),
                parseHTML: (element) => element.getAttribute('data-url'),
            },
            id: {
                default: null,
                renderHTML: (attributes) => ({
                    'data-id': attributes.id,
                }),
                parseHTML: (element) => element.getAttribute('data-id'),
            },
        };
    },

    renderHTML({node, HTMLAttributes}) {
        return ['span', HTMLAttributes, `:${node.attrs.label}:`];
    },

    renderText({node}) {
        return `:${node.attrs.label}:`;
    },

    // addNodeView() {
    //     eslint-disable-next-line new-cap
    // return ReactNodeViewRenderer(EditorEmoji);
    // },

    addProseMirrorPlugins() {
        const storage = this.storage;
        const options = this.options;

        const getEmojis = async (query: string) => {
            const prevAbortController = storage?.abortController;

            prevAbortController?.abort();

            if (!query) {
                return emptyEmojis;
            }

            searchTimeMeasurer.measure(query);
            if (isCustomEmojiEnabled(store.getState() as GlobalState)) {
                const controller = new AbortController();
                storage.abortController = controller;
                const {data} = await autocompleteEmoji(
                    {
                        name: query,
                    },
                    {
                        signal: controller.signal,
                        timeout: TIMEOUT,
                    },
                );

                store.dispatch({
                    type: EmojiTypes.RECEIVED_CUSTOM_EMOJIS,
                    data,
                });
            }

            /**
             * Код идентичен коду старого редактора
             * перенести тесты в случае удаления старого редактора
             * @see EmoticonProvider.findAndSuggestEmojis
             */
            const state = store.getState() as GlobalState;
            const EMOJI_CATEGORY_SUGGESTION_BLOCKLIST = ['skintone'];
            const recentMatched: MatchedEmoji[] = [];
            const matched: MatchedEmoji[] = [];
            const skintone = getUserSkinTone(state);
            const emojiMap = getEmojiMap(state);
            const recentEmojis = getRecentEmojis(state);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            for (const [name, emoji] of emojiMap) {
                if (EMOJI_CATEGORY_SUGGESTION_BLOCKLIST.includes(emoji.category)) {
                    continue;
                }

                if (isSystemEmoji(emoji)) {
                    for (const alias of emoji.short_names) {
                        if (alias.indexOf(query) !== -1) {
                            const matchedArray = recentEmojis.includes(alias) || recentEmojis.includes(name) ? recentMatched : matched;

                            // if the emoji has skin, only add those that match with the user selected skin.
                            if (emojiMatchesSkin(emoji, skintone)) {
                                matchedArray.push({name: alias, emoji});
                            }
                            break;
                        }
                    }
                } else if (name.indexOf(query) !== -1) {
                    // This is a custom emoji so it only has one name
                    if (emojiMap.hasSystemEmoji(name)) {
                        // System emojis take precedence over custom ones
                        continue;
                    }

                    const matchedArray = recentEmojis.includes(name) ? recentMatched : matched;
                    matchedArray.push({name, emoji});
                }
            }

            const sortEmojisHelper = (matchedEmojiA: MatchedEmoji, matchedEmojiB: MatchedEmoji) => {
                return compareEmojis({...matchedEmojiA.emoji, name: matchedEmojiA.name}, {...matchedEmojiB.emoji, name: matchedEmojiB.name}, query);
            };

            recentMatched.sort(sortEmojisHelper);
            matched.sort(sortEmojisHelper);
            const foundEmojis = [...recentMatched, ...matched];

            searchTimeMeasurer.measure(query, foundEmojis.length);
            return transformAutocompleteEmojiResponse(foundEmojis);
        };
        return [
            // eslint-disable-next-line new-cap
            Suggestion({
                editor: this.editor,
                allowSpaces: false,
                pluginKey: new PluginKey('suggestion-TimeWebkitEmojiExtension'),
                char: ':',
                command: ({editor, range, props}) => {
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    const overrideSpace = nodeAfter?.text?.startsWith(' ');

                    if (overrideSpace) {
                        range.to += 1;
                    }

                    try {
                        editor.
                            chain().
                            focus().
                            insertContentAt(range, [
                                {
                                    type: this.name,
                                    attrs: props,
                                },
                                {
                                    type: 'text',
                                    text: ' ',
                                },
                            ]).
                            run();

                        window.getSelection()?.collapseToEnd();
                        // eslint-disable-next-line no-empty
                    } catch {}
                },
                allow: ({state, range}) => {
                    const $from = state.doc.resolve(range.from);
                    const type = state.schema.nodes[this.name];
                    return Boolean($from.parent.type.contentMatch.matchType(type));
                },
                render: () => ({
                    onStart(props) {
                        sendToStatist('editor.searchEmoji.tap', {
                            sourceTap: options.withinThread ? 'thread' : 'post',
                        });
                        const component = new ReactRenderer(EmojiList, {
                            editor: props.editor,
                            props: {
                                ...props,
                                getMentions: getEmojis,
                            },
                        });

                        document.body.appendChild(component.element);

                        storage.menu = component;
                    },
                    onUpdate(props) {
                        const component = storage.menu as ReactRenderer;
                        component?.updateProps({
                            ...props,
                            getMentions: getEmojis,
                        });
                    },
                    onKeyDown(props) {
                        const component = storage.menu;

                        if (props.event.key === Constants.KeyCodes.ESCAPE[0]) {
                            if (component?.element) {
                                document.body.removeChild(component?.element);
                            }
                            component?.destroy();
                            storage.menu = null;

                            return true;
                        }

                        return false;
                    },
                    onExit() {
                        const component = storage.menu as ReactRenderer;
                        if (component?.element) {
                            document.body.removeChild(component?.element);
                        }
                        component?.destroy();
                        storage.menu = null;
                        searchTimeMeasurer.dispose();
                    },
                }),
            }),
        ];
    },
}).configure({
    suggestion: {
        char: ':',
    },
});
