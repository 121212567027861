import styled from 'styled-components';
import classNames from 'classnames';

import CopyIcon from 'components/widgets/icons/copy_icon';
import useCopyText from '../common/hooks/useCopyText';

const CopyButton = styled.button`
    width: 28px;
    height: 28px;
    color: rgba(var(--center-channel-color-rgb), 0.56);
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: none;
    transition: background 0.3s;

    &.compact {
         width: 24px;
         height: 24px;
         margin: -8px 0;
    }

    &:hover {
        background: rgba(var(--center-channel-color-rgb), 0.08);
    }

    &.success {
        background: var(--denim-status-online);
        color: var(--button-color);
    }

    .time-list-item & {
        visibility: hidden;
    }

    .time-list-item:hover & {
        visibility: visible;
    }
`;

type Props = {
    appendId?: string;
    value: string;
    compact?: boolean;
}
const CopyToClipboardButton = ({value, compact, appendId}: Props) => {
    const {onClick, copiedRecently} = useCopyText({text: value, appendId, successCopyTimeout: 1_000});

    return (
        <CopyButton
            className={classNames({success: copiedRecently, compact})}
            onClick={onClick}
        >
            {copiedRecently ? <i role='img' aria-label='Success' className={'icon icon-check'} /> : <CopyIcon />}
        </CopyButton>
    );
};

export default CopyToClipboardButton;
