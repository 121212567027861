import {Client4} from 'mattermost-redux/client';

export function getChannelsRoute() {
    return Client4.getTeamsRoute();
}

export function composeTeamsRoute(route: string) {
    const baseRoute = getChannelsRoute();

    return baseRoute + route;
}
