import type {PreferenceType} from '@mattermost/types/preferences';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {createThunkAction} from 'stores/create_thunk_action';
import {loadDmCategoryForTeam} from '../actions/load_dm_category_for_team';
import {loadSidebarForTeam} from '../actions/load_sidebar_for_team';
import {SidebarLogger} from '../logger';
import {getDMChannelShowPreference} from '../selectors/get_dm_channel_show_preference';
import {getGMChannelShowPreference} from '../selectors/get_gm_channel_show_preference';
import {isFilteredSidebarEnabled} from '../selectors/is_filtered_sidebar_enabled';
import type {DirectChannelShowPreference, GroupChannelShowPreference} from '../types';
import {type PreferencesChangedEvent} from '../types/preferences_changed';
import {isDirectChannelShowPreference} from '../utils/isDirectChannelShowPreference';
import {isGroupChannelShowPreference} from '../utils/isGroupChannelShowPreference';
import {isLimitVisibleDmsGmsPreference} from '../utils/isLimitVisibleDmsGmsPreference';
import {parsePreferencesChangedEvent} from '../utils/parsePreferencesChangedEvent';

function findDirectChannelShowPreference(preferences: PreferenceType[]): DirectChannelShowPreference[] {
    const directChannelShowChangePreferences = preferences.filter(isDirectChannelShowPreference);

    return directChannelShowChangePreferences;
}

function findGroupChannelShowPreference(preferences: PreferenceType[]): GroupChannelShowPreference[] {
    const groupChannelShowChangePreferences = preferences.filter(isGroupChannelShowPreference);

    return groupChannelShowChangePreferences;
}

export const handlePreferencesChangedEvent = (msg: PreferencesChangedEvent) =>
    createThunkAction('sidebar/wsEventsHandlers/handlePreferencesChanged', async (dispatch, getState) => {
        const state = getState();
        const parsedMessage = parsePreferencesChangedEvent(msg);
        const preferences = parsedMessage.data.preferences;
        const directChannelShowPreferences = findDirectChannelShowPreference(preferences);
        const groupChannelShowPreferences = findGroupChannelShowPreference(preferences);
        const currentTeamId = getCurrentTeamId(state);

        /**
         * Два следующих условия выполняют следующую логику:
         * Так как появился канал/каналы который теперь нужно "показать"/"скрыть",
         * нам нужно перезапросить сайдбар
         *
         * @TODO: сделать более умное изменение
         */

        const hasDirectChangedPreferences = directChannelShowPreferences.some((preference) => {
            const existingPreferenceValue = getDMChannelShowPreference(state, preference.name, false);

            if (existingPreferenceValue === false && (!preference.value || preference.value === 'false')) {
                return false;
            }

            if (existingPreferenceValue === true && preference.value === 'true') {
                return false;
            }

            return true;
        });

        const hasGroupChangedPreferences = groupChannelShowPreferences.some((preference) => {
            const existingPreferenceValue = getGMChannelShowPreference(state, preference.name, false);

            if (existingPreferenceValue === false && (!preference.value || preference.value === 'false')) {
                return false;
            }

            if (existingPreferenceValue === true && preference.value === 'true') {
                return false;
            }

            return true;
        });

        const shouldReloadSidebar = hasDirectChangedPreferences || hasGroupChangedPreferences;

        if (shouldReloadSidebar) {
            SidebarLogger.info({
                event: 'sidebar-reload',
                directPreferenceChanged: hasDirectChangedPreferences,
                groupPreferenceChanged: hasGroupChangedPreferences,
            });

            dispatch(
                loadSidebarForTeam({
                    teamId: currentTeamId,
                }),
            );
        }

        /**
         * При изменении лимита ограничения показа каналов в категории ЛС
         * следует перезапросить эту категорию ЛС
         * Но, только если бэкенд фильтрует ЛС сам, так как в ином случае фронтенд
         * и так обладает достаточно информацией и перезапрос категории не нужен
         */
        if (!isFilteredSidebarEnabled(state)) {
            return;
        }

        const limitVisibleDmsGmsPreference = preferences.find(isLimitVisibleDmsGmsPreference);

        if (limitVisibleDmsGmsPreference?.name && !shouldReloadSidebar) {
            SidebarLogger.info({
                event: 'load-dm-category-for-team',
                reason: 'limit-changed',
                newValue: limitVisibleDmsGmsPreference.value,
            });

            dispatch(loadDmCategoryForTeam({teamId: currentTeamId}));
        }
    });
