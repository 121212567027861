import TipTapMentionExtension, {MentionOptions} from '@tiptap/extension-mention';
import {ReactRenderer} from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import {autocompleteChannelsForTeam} from 'mattermost-redux/api/teams/autocompleteChannelsForTeam';
import {PluginKey} from 'prosemirror-state';

import {Constants} from 'utils/constants';
import {SearchTimeMeasurer, sendToStatist} from '@time-webkit/statist';

import {ChannelMentionList} from './components/mention-list';
import {TIMEOUT} from './constants';
import {prioritizeChannelMentionsByQuery, transformAutocompleteChannelsForTeamResponse} from './transformers';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        channelMention: {

            /**
             * Set a mention node
             */
            setChannelMention: () => ReturnType;
        };
    }
}

const emptyMentions: any[] = [];

const suggestionKey = new PluginKey('suggestion-timeEditorChannelMentionExtension');

const searchTimeMeasurer = new SearchTimeMeasurer('editor.searchChannel.result');

export const TimeEditorChannelMentionExtension = TipTapMentionExtension.extend<
{
    teamId: string;
    predefinedChannels: any[];
    withinThread: boolean;
} & MentionOptions
>({
    name: 'timeEditorChannelMentionExtension',
    addStorage() {
        return {
            abortController: null,
            menu: null,
            error: null,
            hasButtonBeenPressed: false,
        };
    },

    addCommands() {
        return {
            setChannelMention:
                () =>
                    ({chain}) => {
                        this.storage.hasButtonBeenPressed = true;
                        return chain().insertContent(' ~').focus().run();
                    },
        };
    },

    addProseMirrorPlugins() {
        const options = this.options;
        const storage = this.storage;

        const getChannels = async (query: string) => {
            const prevAbortController = storage?.abortController;

            prevAbortController?.abort();

            if (typeof query === 'undefined') {
                return emptyMentions;
            }

            searchTimeMeasurer.measure(query);
            const controller = new AbortController();

            storage.abortController = controller;

            const data = await autocompleteChannelsForTeam(
                {
                    name: query,
                    team_id: options.teamId,
                },
                {
                    signal: controller.signal,
                    timeout: TIMEOUT,
                    id: `getChannels-${query}`,
                    cache: {
                        interpretHeader: false,
                        ttl: 1000 * 10,
                    },
                },
            ).then(({data}) => {
                searchTimeMeasurer.measure(query, data.length);
                return transformAutocompleteChannelsForTeamResponse(data);
            });

            const predefinedChannels = this.options.predefinedChannels.filter(({handle}) => handle?.includes(query));

            return prioritizeChannelMentionsByQuery(predefinedChannels.concat(data), query);
        };

        return [
            // eslint-disable-next-line new-cap
            Suggestion({
                editor: this.editor,
                pluginKey: suggestionKey,
                char: '~',
                allowSpaces: false,
                command: ({editor, range, props}) => {
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    const overrideSpace = nodeAfter?.text?.startsWith(' ');

                    if (overrideSpace) {
                        range.to += 1;
                    }

                    try {
                        editor.
                            chain().
                            focus().
                            insertContentAt(range, [
                                {
                                    type: this.name,
                                    attrs: props,
                                },
                                {
                                    type: 'text',
                                    text: ' ',
                                },
                            ]).
                            run();

                        window.getSelection()?.collapseToEnd();
                        // eslint-disable-next-line no-empty
                    } catch {}
                },
                allow: ({state, range}) => {
                    const $from = state.doc.resolve(range.from);
                    const type = state.schema.nodes[this.name];
                    const allow = Boolean($from.parent.type.contentMatch.matchType(type));

                    return allow;
                },
                render() {
                    return {
                        onStart(props) {
                            sendToStatist('editor.searchChannel.tap', {
                                sourceTap: options.withinThread ? 'thread' : 'post',
                                sourceSearch: storage.hasButtonBeenPressed ? 'button' : 'type',
                            });
                            storage.hasButtonBeenPressed = false;
                            const component = new ReactRenderer(ChannelMentionList, {
                                editor: props.editor,
                                props: {
                                    ...props,
                                    getMentions: getChannels,
                                },
                            });

                            document.body.appendChild(component.element);

                            storage.menu = component;
                        },
                        onUpdate(props) {
                            const component = storage.menu as ReactRenderer;
                            component?.updateProps({
                                ...props,
                                getMentions: getChannels,
                            });
                        },
                        onKeyDown(props) {
                            const component = storage.menu;

                            if (props.event.key === Constants.KeyCodes.ESCAPE[0]) {
                                if (component?.element) {
                                    document.body.removeChild(component?.element);
                                }
                                component?.destroy();
                                storage.menu = null;

                                return true;
                            }

                            return false;
                        },
                        onExit() {
                            const component = storage.menu as ReactRenderer;
                            if (component?.element) {
                                document.body.removeChild(component?.element);
                            }
                            component?.destroy();
                            storage.menu = null;
                            searchTimeMeasurer.dispose();
                        },
                    };
                },
            }),
        ];
    },
}).configure({
    suggestion: {
        char: '~',
    },
});
