import React from 'react';
import classnames from 'classnames';
import {useListState} from '@react-stately/list';
import {useListBox, useOption} from '@react-aria/listbox';

import type {AriaOptionProps} from '@react-aria/listbox';

import type {ListState} from '@react-stately/list';

import {mergeProps} from '@react-aria/utils';
import {useFocusRing} from '@react-aria/focus';
import {useHover} from '@react-aria/interactions';

import {TypographySize, useTypography} from '../../../hooks/typography';

import type {Props} from './types';
import styles from './styles.module.css';

const Option = <Item extends Record<string, any>>(props: { state: ListState<Item>; item: Item } & AriaOptionProps) => {
    const {item, state} = props;
    const ref = React.useRef<HTMLLIElement>(null);
    const {optionProps, isSelected, isPressed, isDisabled} = useOption({...props, key: item.key}, state, ref);
    const {isFocusVisible, focusProps} = useFocusRing();
    const {hoverProps, isHovered} = useHover({
        isDisabled,
    });

    const typographyCls = useTypography({
        size: TypographySize.BodyL,
    });

    return (
        <li
            className={classnames(
                styles.option,
                typographyCls,
                isFocusVisible && styles.optionFocus,
                isSelected && styles.optionSelected,
                isPressed && styles.optionSelected,
                isHovered && styles.optionHover,
            )}
            {...mergeProps(optionProps, focusProps, hoverProps)}
            ref={ref}
        >
            {item.rendered}
        </li>
    );
};

export const ListBox = React.forwardRef<HTMLUListElement, Props<any>>((props, passedRef) => {
    const ref = React.useRef<HTMLUListElement>(null);
    const listBoxRef = (passedRef || ref) as React.RefObject<HTMLUListElement>;
    const {className, state: passedState, style, ...listProps} = props;
    const innerState = useListState(listProps);
    const listState = passedState || innerState;
    const {listBoxProps} = useListBox(listProps, listState, listBoxRef);

    return (
        <ul
            className={classnames(styles.wrapper, className)}
            style={style}
            {...listBoxProps}
            ref={listBoxRef}
        >
            {[...listState.collection].map((item) => (
                <Option key={item.key} item={item} state={listState} />
            ))}
        </ul>
    );
});
