import {getCurrentTeamName, getTeamById} from 'mattermost-redux/selectors/entities/teams';
import {type GlobalState} from 'types/store';
import {type Post} from 'mattermost-redux/types/posts';

export const getPostPermalinkUrl = (state: GlobalState, postId: Post['id'], teamId: string | undefined, query?: string) => {
    const currentTeamName = getCurrentTeamName(state);
    const passedTeamName = getTeamById(state, teamId || '')?.name;

    return `/${passedTeamName || currentTeamName}/pl/${postId}${query ? `?${query}` : ''}`;
};

