import classNames from 'classnames';

import type {UserStatus} from 'packages/ui-kit/src/types/general';

type Props = {
    className?: string;
    status?: UserStatus;
};

export default function StatusIcon({status, className = ''}: Props) {
    if (!status) {
        return null;
    }

    return <span className={classNames('status-icon', 'status-' + status, className)} />;
}
