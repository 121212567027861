import {defaultMarkdownSerializer, MarkdownSerializer, MarkdownSerializerState} from 'prosemirror-markdown';
import {Mark, Node, Schema} from 'prosemirror-model';

// Эта функция из prosemirror-markdown
// Используется в дефолтной реализации сериалайзера для marks.link
function isPlainURL(link: Mark, parent: Node, index: number) {
    if (link.attrs.title || !(/^\w+:/).test(link.attrs.href)) {
        return false;
    }
    const content = parent.child(index);
    if (!content.isText || content.text !== link.attrs.href || content.marks[content.marks.length - 1] !== link) {
        return false;
    }
    return index === parent.childCount - 1 || !link.isInSet(parent.child(index + 1).marks);
}

const nodesToClose = ['blockquote', 'orderedList', 'bulletList'];
class TimeMarkdownSerializerState extends MarkdownSerializerState {
    closed: Node | null = null;

    // По дефолту prosemirror-markdown использует двойной перенос \n\n для закрытия блока форматирования,
    // что является избыточным для всех нод, кроме цитат и списков.
    closeBlock(node: Node): void {
        if (nodesToClose.includes(node.type.name)) {
            this.closed = node;
        } else {
            this.write('\n');
        }
    }
}

export function getMarkdownSerializer(schema: Schema) {
    const serializer: MarkdownSerializer = defaultMarkdownSerializer;
    serializer.marks.bold = {open: '**', close: '**', mixable: true, expelEnclosingWhitespace: true};
    serializer.marks.italic = {open: '*', close: '*', mixable: true, expelEnclosingWhitespace: true};
    serializer.marks.strike = {open: '~~', close: '~~', mixable: true, expelEnclosingWhitespace: true};
    serializer.marks.link = {
        open(state, mark, parent, index) {
            const isPlain = isPlainURL(mark, parent, index);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.inAutolink = isPlain;

            // В дефолтном сериалайзере мы возвращаем '<' вместо пустой строки
            return isPlain ? '' : '[';
        },
        close(state, mark) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const {inAutolink} = state;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state.inAutolink = undefined;

            // В дефолтном сериалайзере мы возвращаем '>' вместо пустой строки
            return inAutolink ? '' : '](' + mark.attrs.href.replace(/[\\(\\)"]/g, '\\$&') + (mark.attrs.title ? ` "${mark.attrs.title.replace(/"/g, '\\"')}"` : '') + ')';
        },
        mixable: true,
    };

    Object.entries(schema.nodes).forEach(([name, tiptapNode]) => {
        if (!tiptapNode.spec.toText) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        serializer.nodes[name] = (state: MarkdownSerializerState, node: Node, parent?: Node, index?: number) => {
            state.write(tiptapNode.spec.toText({node, pos: -1, parent, index}));
            if (!tiptapNode.spec.inline) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                state.closeBlock(node);
            }
        };
    });

    serializer.nodes.bulletList = serializer.nodes.bullet_list;
    serializer.nodes.codeBlock = serializer.nodes.code_block;
    serializer.nodes.hardBreak = serializer.nodes.hard_break;
    serializer.nodes.codeBlock = (state, node) => {
        // Make sure the front matter fences are longer than any dash sequence within it
        const backticks = node.textContent.match(/`{3,}/gm);
        const fence = backticks ? (backticks.sort().slice(-1)[0] + '`') : '```';

        state.write(fence + (node.attrs.language || '') + '\n');
        state.text(node.textContent, false);
        state.ensureNewLine();
        state.write(fence);
        state.closeBlock(node);
    };
    serializer.nodes.emoji = (state, node) => {
        state.text(`:${node.text!}:`, !state.inAutolink);
    };

    //serializer.nodes['heading']
    serializer.nodes.horizontalRule = serializer.nodes.horizontal_rule;

    //serializer.nodes['image']
    serializer.nodes.listItem = serializer.nodes.list_item;
    serializer.nodes.orderedList = serializer.nodes.ordered_list;

    serializer.nodes.paragraph = (state, node, parent) => {
        state.renderInline(node);

        if (parent.isBlock) {
            state.closeBlock(node);
        }
    };

    // Метод сериализации класса MarkdownSerializer.
    // Переопределяем, чтобы использовать класс TimeMarkdownSerializerState вместо дефолтного MarkdownSerializerState
    serializer.serialize = (content: Node, options: {tightLists?: boolean} = {}) => {
        Object.assign(serializer.options, options);
        const state = new TimeMarkdownSerializerState(serializer.nodes, serializer.marks, serializer.options);
        state.renderContent(content);
        return state.out.trimEnd();
    };

    //serializer.nodes['text']
    return serializer;
}

const THIN_SPACE = ' ';

export function formatLimitCounter(value: number | string) {
    return value.toString().replace(/(\S)(?=(\S{3})+(?!\S))/g, `$1${THIN_SPACE}`);
}
