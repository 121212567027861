import type {DayPickerProps} from 'react-day-picker';

import {formatDate, formatWeekdayByNumber, getFirstDayOfWeek} from 'utils/datetime';

export const LocaleUtils: DayPickerProps['localeUtils'] = {
    getFirstDayOfWeek,
    formatDay: (date: Date) => formatDate(date, 'eeee dd MMMM, yyyy'),
    formatMonthTitle: (date: Date) => {
        const formattedDate = formatDate(date, 'LLLL yyyy');

        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },
    formatWeekdayShort: (weekday: 0 | 1 | 2 | 3 | 4 | 5 | 6) => {
        const formattedWeekday = formatWeekdayByNumber(weekday, 'short');

        return formattedWeekday.charAt(0).toUpperCase() + formattedWeekday.slice(1);
    },
    formatWeekdayLong: (weekday: 0 | 1 | 2 | 3 | 4 | 5 | 6) => formatWeekdayByNumber(weekday, 'wide'),
} as DayPickerProps['localeUtils'];
