import {createAction} from '@reduxjs/toolkit';

import type {Team} from '@mattermost/types/teams';

import type {ClientThread} from '../types/extended';

type Payload = {
    threadId: ClientThread['id'];
    teamId?: Team['id'];
};

export const removeThread = createAction<Payload>('threads/action/removeThread');
