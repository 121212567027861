import React, {useEffect, useState, useCallback, type CSSProperties} from 'react';

import {useIntl} from 'react-intl';

import {DayPickerInput, type NavbarElementProps, type DayModifiers} from 'components/day_picker';
import MenuWrapper from 'components/widgets/menu/menu_wrapper';
import Menu from 'components/widgets/menu/menu';
import Timestamp from 'components/timestamp';
import {
    getRoundedTime as getRoundedTimeUtil,
    startOf,
    add,
    isBefore,
    setDateSecondsAndMillisecondsToZero,
    getCurrentDateTimeForTimezone,
    getDateTimeForTimezone,
    isSameDayOfMonth,
    getDiff,
    getBrowserUtcOffset,
    getTimezoneOffset,
} from 'utils/datetime';

const CUSTOM_STATUS_TIME_PICKER_INTERVALS_IN_MINUTES = 30;
const MENU_STYLES: CSSProperties = {position: 'relative', maxHeight: 'inherit'};

const Navbar: React.FC<Partial<NavbarElementProps>> = (navbarProps: Partial<NavbarElementProps>) => {
    const {
        onPreviousClick,
        onNextClick,
        className,
    } = navbarProps;
    const styleLeft: React.CSSProperties = {
        float: 'left',
        fontSize: 18,
    };
    const styleRight: React.CSSProperties = {
        float: 'right',
        fontSize: 18,
    };

    return (
        <div className={className}>
            <button
                className='style--none'
                style={styleLeft}
                onClick={(e) => {
                    e.preventDefault();
                    if (onPreviousClick) {
                        onPreviousClick();
                    }
                }}
            >
                <i
                    className='fa fa-angle-left'
                    aria-hidden='true'
                />
            </button>
            <button
                className='style--none'
                style={styleRight}
                onClick={(e) => {
                    e.preventDefault();
                    if (onNextClick) {
                        onNextClick();
                    }
                }}
            >
                <i
                    className='fa fa-angle-right'
                    aria-hidden='true'
                />
            </button>
        </div>
    );
};

export function getRoundedTime(value: Date) {
    return getRoundedTimeUtil(value, CUSTOM_STATUS_TIME_PICKER_INTERVALS_IN_MINUTES, 'minutes');
}

const getTimeInIntervals = (startTime: Date, timezone?: string): Date[] => {
    const interval = CUSTOM_STATUS_TIME_PICKER_INTERVALS_IN_MINUTES;
    let time = startTime;
    const nextDay = startOf(add(startTime, 1, 'days'), 'day');
    const intervals: Date[] = [];
    while (isBefore(time, nextDay)) {
        intervals.push(time);
        time = setDateSecondsAndMillisecondsToZero(add(time, interval, 'minutes'));
    }
    const sortedIntervals = intervals.sort((a, b) => getDiff(a, b, timezone, 'minutes'));

    const utcIntervals = sortedIntervals.map((interval) =>
        new Date((interval.getTime() + getBrowserUtcOffset()) - getTimezoneOffset(timezone, interval)));
    return utcIntervals;
};

type Props = {
    time: Date;
    handleChange: (date: Date) => void;
    timezone?: string;
}

const DateTimeInputContainer: React.FC<Props> = (props: Props) => {
    const {time, handleChange, timezone} = props;
    const utcTime = new Date((time.getTime() + getBrowserUtcOffset()) - getTimezoneOffset(timezone, time));
    const [timeOptions, setTimeOptions] = useState<Date[]>([]);
    const {formatMessage} = useIntl();

    const setTimeAndOptions = () => {
        const currentTime = getCurrentDateTimeForTimezone(timezone);
        let startTime = startOf(time, 'day');
        if (isSameDayOfMonth(time, currentTime)) {
            startTime = getRoundedTime(currentTime);
        }
        setTimeOptions(getTimeInIntervals(startTime, timezone));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(setTimeAndOptions, [time]);

    const handleDayChange = (day: Date, modifiers: DayModifiers) => {
        if (modifiers.today) {
            const currentTime = getCurrentDateTimeForTimezone(timezone);
            const roundedTime = getRoundedTime(currentTime);
            handleChange(roundedTime);
        } else {
            const dayWithTimezone = getDateTimeForTimezone(day, timezone);
            handleChange(startOf(dayWithTimezone, 'day'));
        }
    };

    const handleTimeChange = useCallback((time: Date, e: React.MouseEvent) => {
        e.preventDefault();
        const localeTime = new Date((time.getTime() - getBrowserUtcOffset()) + getTimezoneOffset(timezone, time));
        handleChange(localeTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleChange]);

    const currentTime = getCurrentDateTimeForTimezone(timezone);
    const modifiers = {
        today: currentTime,
    };

    return (
        <div className='dateTime'>
            <div className='dateTime__date'>
                <span className='dateTime__input-title'>{formatMessage({id: 'custom_status.expiry.date_picker.title', defaultMessage: 'Date'})}</span>
                <span className='dateTime__date-icon'>
                    <i className='icon-calendar-outline'/>
                </span>
                <DayPickerInput
                    value={time}
                    onDayChange={handleDayChange}
                    inputProps={{
                        readOnly: true,
                        className: 'dateTime__input',
                    }}
                    dayPickerProps={{
                        navbarElement: <Navbar/>,
                        fromMonth: currentTime,
                        modifiers,
                        disabledDays: {
                            before: currentTime,
                        },
                    }}
                />
            </div>
            <div className='dateTime__time'>
                <MenuWrapper
                    className='dateTime__time-menu'
                >
                    <div>
                        <span className='dateTime__input-title'>{formatMessage({id: 'custom_status.expiry.time_picker.title', defaultMessage: 'Time'})}</span>
                        <span className='dateTime__time-icon'>
                            <i className='icon-clock-outline'/>
                        </span>
                        <div
                            className='dateTime__input'
                        >
                            <Timestamp
                                useRelative={false}
                                useDate={false}
                                value={utcTime}
                            />
                        </div>
                    </div>
                    <Menu
                        ariaLabel={formatMessage({id: 'time_dropdown.choose_time', defaultMessage: 'Choose a time'})}
                        id='expiryTimeMenu'
                        customStyles={MENU_STYLES}
                    >
                        <Menu.Group>
                            {Array.isArray(timeOptions) && timeOptions.map((option, index) => (
                                <Menu.ItemAction
                                    onClick={handleTimeChange.bind(this, option)}
                                    key={index}
                                    text={
                                        <Timestamp
                                            useRelative={false}
                                            useDate={false}
                                            value={option}
                                        />
                                    }
                                />
                            ))}
                        </Menu.Group>
                    </Menu>
                </MenuWrapper>
            </div>
        </div>
    );
};

export default DateTimeInputContainer;
