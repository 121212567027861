import {createSelector} from '@reduxjs/toolkit';

import {type ChannelModeration} from '@mattermost/types/channels';

import type {RootState} from 'stores/redux_store';

const EMPTY_CHANNEL_MODERATIONS: ChannelModeration[] = [];

export const getModerationsForChannelId = createSelector(
    (state: RootState) => state.channelPermissions.channelPermissions,
    (_: RootState, channelId: string) => channelId,
    (permissions, channelId) => permissions[channelId] || EMPTY_CHANNEL_MODERATIONS,
);
