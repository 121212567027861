import {createSlice, type PayloadAction, createEntityAdapter} from '@reduxjs/toolkit';

import type {GlobalState} from 'types/store';
import {TiMeDraftsLogger} from '../logger';

import {type Draft, type Drafts, type ChannelDraft, type ThreadDraft} from '../types';
import {
    getChannelDraftIdFromKey,
    getThreadDraftIdFromKey,
    isChannelDraft,
    isChannelDraftKey,
    isThreadDraft,
    isThreadDraftKey,
} from '../utils';

import {DraftsStoreStates} from '../constants';

import {areDraftsEqual} from './utils';

export const channelsDraftsAdapter = createEntityAdapter<Draft>({
    selectId: (draft) => draft.draftId,
    sortComparer: (a, b) => (a.timestamp > b.timestamp ? 1 : -1),
});

export const threadsDraftsAdapter = createEntityAdapter<Draft>({
    selectId: (draft) => draft.draftId,
    sortComparer: (a, b) => (a.timestamp > b.timestamp ? 1 : -1),
});

const actionsLogger = TiMeDraftsLogger.child({
    name: 'store-actions',
});

const appDraftsSlice = createSlice({
    name: 'drafts',
    initialState: {
        threadsDrafts: threadsDraftsAdapter.getInitialState(),
        channelsDrafts: channelsDraftsAdapter.getInitialState(),
        init: DraftsStoreStates.LOADING,
    },
    reducers: {
        receivedDraft(state, action: PayloadAction<{key: keyof Drafts; value: Drafts[keyof Drafts]}>) {
            const {key, value: newDraft} = action.payload;

            actionsLogger.debug('receivedDraft', action.payload);

            if (isChannelDraft(key, newDraft)) {
                const existingDraft = state.channelsDrafts.entities[newDraft.draftId];

                if (!existingDraft) {
                    channelsDraftsAdapter.addOne(state.channelsDrafts, newDraft);
                }

                if (existingDraft && !areDraftsEqual(existingDraft, newDraft)) {
                    channelsDraftsAdapter.upsertOne(state.channelsDrafts, newDraft);
                }
            }

            if (isThreadDraft(key, newDraft)) {
                const existingDraft = state.threadsDrafts.entities[newDraft.draftId];

                if (!existingDraft) {
                    threadsDraftsAdapter.addOne(state.threadsDrafts, newDraft);
                }

                if (existingDraft && !areDraftsEqual(existingDraft, newDraft)) {
                    threadsDraftsAdapter.upsertOne(state.threadsDrafts, newDraft);
                }
            }
        },
        removedDraft(state, action: PayloadAction<keyof Drafts>) {
            const key = action.payload;

            actionsLogger.debug('removedDraft', action.payload);

            if (isChannelDraftKey(key)) {
                const draftId = getChannelDraftIdFromKey(key);
                channelsDraftsAdapter.removeOne(state.channelsDrafts, draftId);
            }

            if (isThreadDraftKey(key)) {
                const draftId = getThreadDraftIdFromKey(key);
                threadsDraftsAdapter.removeOne(state.threadsDrafts, draftId);
            }
        },
        receivedDrafts(state, action: PayloadAction<Array<{key: keyof Drafts; value: Drafts[keyof Drafts]}>>) {
            const channelsDrafts: ChannelDraft[] = [];
            const threadsDrafts: ThreadDraft[] = [];
            actionsLogger.debug('receivedDrafts', action.payload);

            action.payload.forEach(({key, value}) => {
                if (isChannelDraft(key, value)) {
                    channelsDrafts.push(value);
                }

                if (isThreadDraft(key, value)) {
                    threadsDrafts.push(value);
                }
            });

            channelsDraftsAdapter.setAll(state.channelsDrafts, channelsDrafts);
            threadsDraftsAdapter.setAll(state.threadsDrafts, threadsDrafts);
        },
        receivedInitialDrafts(state, action: PayloadAction<Array<{key: keyof Drafts; value: Drafts[keyof Drafts]}>>) {
            const channelsDrafts: ChannelDraft[] = [];
            const threadsDrafts: ThreadDraft[] = [];
            actionsLogger.debug('receivedInitialDrafts', action.payload);

            action.payload.forEach(({key, value}) => {
                if (isChannelDraft(key, value)) {
                    channelsDrafts.push(value);
                }

                if (isThreadDraft(key, value)) {
                    threadsDrafts.push(value);
                }
            });

            channelsDraftsAdapter.setAll(state.channelsDrafts, channelsDrafts);
            threadsDraftsAdapter.setAll(state.threadsDrafts, threadsDrafts);
            state.init = DraftsStoreStates.LOADED;
        },
        failedReceiveInitialDrafts(state) {
            state.init = DraftsStoreStates.FAILED;
        },
    },
});

export const {receivedDraft, receivedDrafts, receivedInitialDrafts, removedDraft, failedReceiveInitialDrafts} = appDraftsSlice.actions;
export const draftsReducer = appDraftsSlice.reducer;
export const draftsReducerName = appDraftsSlice.name;
export type DraftsReducerState = ReturnType<typeof appDraftsSlice['getInitialState']>;
export const draftsInitialState = appDraftsSlice.getInitialState();

export const selectSelf = (state: GlobalState) => state[appDraftsSlice.name];

export default appDraftsSlice.reducer;
