import {findChildren} from '@tiptap/core';

import highlight from 'highlight.js/lib/core';
import type {Root, Options, AutoOptions} from 'lowlight';
import type {Span, Text} from 'lowlight/lib/core';
import {Node as ProsemirrorNode} from 'prosemirror-model';
import {Plugin, PluginKey} from 'prosemirror-state';
import {Decoration, DecorationSet} from 'prosemirror-view';

type Lowlight = {
    highlight: (
        language: string,
        value: string,
        options?: Options | undefined
    ) => Root;
    highlightAuto: (
        value: string,
        options?: AutoOptions | undefined
    ) => Root;
    listLanguages: () => string[];
};

function parseNodes(nodes: Array<Span | Text>, className: string[] = []): Array<{ text: string; classes: string[] }> {
    return nodes.
        map((node) => {
            const classes = [
                ...className,
                ...('properties' in node && node.properties ? node.properties.className : []),
            ];

            if ('children' in node && node.children) {
                return parseNodes(node.children, classes);
            }

            return {
                text: (node as Text).value,
                classes,
            };
        }).
        flat();
}

function getHighlightNodes(result: Root) {
    return result.children || [];
}

function registered(aliasOrLanguage: string) {
    return Boolean(highlight.getLanguage(aliasOrLanguage));
}

function getDecorations({
    doc,
    name,
    lowlight,
    defaultLanguage,
    highlightAuto,
}: {
    doc: ProsemirrorNode;
    name: string;
    lowlight: Lowlight;
    defaultLanguage: string | null | undefined;
    highlightAuto: boolean | undefined;
}) {
    const decorations: Decoration[] = [];

    findChildren(doc, (node) => node.type.name === name).
        forEach((block) => {
            let from = block.pos + 1;
            const language = block.node.attrs.language || defaultLanguage;
            const languages = lowlight.listLanguages();
            const languageDetected = language && (languages.includes(language) || registered(language));

            if (!languageDetected && !highlightAuto) {
                return;
            }

            const nodes = languageDetected ? getHighlightNodes(lowlight.highlight(language, block.node.textContent)) : getHighlightNodes(lowlight.highlightAuto(block.node.textContent));

            parseNodes(nodes).forEach((node) => {
                const to = from + node.text.length;

                if (node.classes.length) {
                    const decoration = Decoration.inline(from, to, {
                        class: node.classes.join(' '),
                    });

                    decorations.push(decoration);
                }

                from = to;
            });
        });

    return DecorationSet.create(doc, decorations);
}

export function lowlightPlugin({
    name,
    lowlight,
    defaultLanguage,
    highlightAuto,
}: {
    name: string;
    lowlight: Lowlight;
    defaultLanguage?: string | null | undefined;
    highlightAuto?: boolean;
}) {
    const lowlightPlugin: Plugin = new Plugin({
        key: new PluginKey('lowlight'),

        state: {
            init: (_, {doc}) => getDecorations({
                doc,
                name,
                lowlight,
                defaultLanguage,
                highlightAuto,
            }),
            apply: (transaction, decorationSet, oldState, newState) => {
                const oldNodeName = oldState.selection.$head.parent.type.name;
                const newNodeName = newState.selection.$head.parent.type.name;
                const oldNodes = findChildren(oldState.doc, (node) => node.type.name === name);
                const newNodes = findChildren(newState.doc, (node) => node.type.name === name);

                if (
                    transaction.docChanged &&

          // Apply decorations if:
          (

                    // selection includes named node,
              [oldNodeName, newNodeName].includes(name) ||

            // OR transaction adds/removes named node,
            newNodes.length !== oldNodes.length ||

            // OR transaction has changes that completely encapsulte a node
            // (for example, a transaction that affects the entire document).
            // Such transactions can happen during collab syncing via y-prosemirror, for example.
            transaction.steps.some((step) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return step.from !== undefined &&

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                step.to !== undefined &&
                oldNodes.some((node) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    return node.pos >= step.from &&

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    node.pos + node.node.nodeSize <= step.to;
                });
            })
          )
                ) {
                    return getDecorations({
                        doc: transaction.doc,
                        name,
                        lowlight,
                        defaultLanguage,
                        highlightAuto,
                    });
                }

                return decorationSet.map(transaction.mapping, transaction.doc);
            },
        },

        props: {
            decorations(state) {
                return lowlightPlugin.getState(state);
            },
        },
    });

    return lowlightPlugin;
}
