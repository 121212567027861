import {createListenerMiddleware, isAnyOf} from '@reduxjs/toolkit';

import throttle from '@tinkoff/utils/function/throttle';

import {type AppDispatch, type RootState} from 'stores/redux_store';

import {removeDraft, saveDraft} from '../controller';

import {receivedDraft, removedDraft} from './slice';

const draftsPersistHandler = createListenerMiddleware<RootState, AppDispatch>();

const saveDraftThrottled = throttle(500, saveDraft);

draftsPersistHandler.startListening({
    matcher: isAnyOf(receivedDraft, removedDraft),
    effect: (action) => {
        if (receivedDraft.match(action)) {
            saveDraftThrottled(action.payload.key, action.payload.value);
        }

        if (removedDraft.match(action)) {
            removeDraft(action.payload);
        }
    },
});

export const draftsPersistMiddleware = draftsPersistHandler.middleware;
