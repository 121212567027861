import {batchActions} from 'utils/batch_actions';

import {
    fetchMyChannelsAndMembers,
    getChannelByNameAndTeamName,
    getChannelStats,
} from 'mattermost-redux/actions/channels';
import {logout, loadMe} from 'mattermost-redux/actions/users';
import {Preferences} from 'mattermost-redux/constants';
import {getConfig, getLicense, isPerformanceDebuggingEnabled} from 'mattermost-redux/selectors/entities/general';
import {getCurrentTeamId, getMyTeams, getTeam, getMyTeamMember, getTeamMemberships, canSelectOrCreateTeam, getTeamByName} from 'mattermost-redux/selectors/entities/teams';
import {getBool} from 'mattermost-redux/selectors/entities/preferences';
import {getCurrentUser, getCurrentUserId} from 'mattermost-redux/selectors/entities/users';
import {getCurrentChannelStats, getCurrentChannelId, getMyChannelMember, getRedirectChannelNameForTeam, getChannelsNameMapInTeam, getAllDirectChannels, getChannelMessageCount} from 'mattermost-redux/selectors/entities/channels';
import type {Channel, ChannelMembership} from 'mattermost-redux/types/channels';
import type {UserProfile} from 'mattermost-redux/types/users';
import type {ActionFunc, DispatchFunc, GetStateFunc} from 'mattermost-redux/types/actions';
import type {Team} from 'mattermost-redux/types/teams';
import {calculateUnreadCount} from 'mattermost-redux/utils/channel_utils';

import {browserHistory} from 'utils/browser_history';
import {handleNewPost} from 'actions/post_actions';
import {stopPeriodicStatusUpdates} from 'actions/status_actions';
import {closeRightHandSide, closeMenu as closeRhsMenu, updateRhsState} from 'actions/views/rhs';
import {clearUserCookie} from 'actions/views/cookie';
import {close as closeLhs} from 'actions/views/lhs';
import * as WebsocketActions from 'actions/websocket_actions';
import {getCurrentLocale} from 'selectors/i18n';
import {getIsRhsOpen, getPreviousRhsState, getRhsState} from 'selectors/rhs';
import BrowserStore from 'stores/browser_store';
import store from 'stores/redux_store';
import LocalStorageStore from 'stores/local_storage_store';
import WebSocketClient from 'client/web_websocket_client';

import type {GlobalState} from 'types/store';

import {ActionTypes, PostTypes, RHSStates, ModalIdentifiers} from 'utils/constants';
import {filterAndSortTeamsByDisplayName} from 'utils/team_utils';
import * as Utils from 'utils/utils';
import {getTimestamp} from 'utils/datetime';
import SubMenuModal from '../components/widgets/menu/menu_modals/submenu_modal/submenu_modal';

import {selectChannel} from 'features/channels/actions/select_channel';

import {setAccessDenied} from '../features/fatalErrors';

import {checkIfMFARequired, SIGNIN_ROUTE} from 'utils/route';

import * as UserAgent from 'utils/user_agent';

import {openModal} from './views/modals';

const dispatch = store.dispatch;
const getState = store.getState;

export function emitChannelClickEvent(channel: Channel) {
    function switchToChannel(chan: Channel) {
        const state = getState();
        const userId = getCurrentUserId(state);
        const teamId = chan.team_id || getCurrentTeamId(state);
        const isRHSOpened = getIsRhsOpen(state);
        const isPinnedPostsShowing = getRhsState(state) === RHSStates.PIN;
        const isChannelFilesShowing = getRhsState(state) === RHSStates.CHANNEL_FILES;
        const isChannelInfoShowing = getRhsState(state) === RHSStates.CHANNEL_INFO;
        const member = getMyChannelMember(state, chan.id);
        const previousRhsState = getPreviousRhsState(state);
        dispatch(getChannelStats(chan.id));

        const penultimate = LocalStorageStore.getPreviousChannelName(userId, teamId, state);
        if (penultimate !== chan.name) {
            LocalStorageStore.setPenultimateChannelName(userId, teamId, penultimate, state);
            LocalStorageStore.setPreviousChannelName(userId, teamId, chan.name, state);
        }

        const actions: Array<ReturnType<typeof updateRhsState>> = [];

        if (isRHSOpened) {
            if (isPinnedPostsShowing) {
                actions.push(updateRhsState(RHSStates.PIN, chan.id, previousRhsState));
            }

            if (isChannelFilesShowing) {
                actions.push(updateRhsState(RHSStates.CHANNEL_FILES, chan.id, previousRhsState));
            }

            if (isChannelInfoShowing) {
                actions.push(updateRhsState(RHSStates.CHANNEL_INFO, chan.id, previousRhsState));
            }
        }

        dispatch(
            batchActions([
                ...actions,
                selectChannel(chan.id),
                {
                    type: ActionTypes.SELECT_CHANNEL_WITH_MEMBER,
                    data: chan.id,
                    channel: chan,
                    member: member || {},
                },
                setLastUnreadChannel(state, chan),
            ]),
        );
    }

    switchToChannel(channel);
}

function setLastUnreadChannel(state: GlobalState, channel: Channel) {
    const member = getMyChannelMember(state, channel.id);
    const messageCount = getChannelMessageCount(state, channel.id);

    let hadMentions = false;
    let hadUnreads = false;
    if (member && messageCount) {
        const unreadCount = calculateUnreadCount(messageCount, member);

        hadMentions = unreadCount.mentions > 0;
        hadUnreads = unreadCount.showUnread && unreadCount.messages > 0;
    }

    return {
        type: ActionTypes.SET_LAST_UNREAD_CHANNEL,
        channelId: channel.id,
        hadMentions,
        hadUnreads,
    };
}

export const clearLastUnreadChannel = {
    type: ActionTypes.SET_LAST_UNREAD_CHANNEL,
    channelId: '',
};

export function updateNewMessagesAtInChannel(channelId: string, lastViewedAt = Date.now()) {
    return {
        type: ActionTypes.UPDATE_CHANNEL_LAST_VIEWED_AT,
        channel_id: channelId,
        last_viewed_at: lastViewedAt,
    };
}

export function emitCloseRightHandSide() {
    dispatch(closeRightHandSide());
}

export function showMobileSubMenuModal(elements: any[], actionProps?: any, postId?: any) { // TODO Use more specific type
    const submenuModalData = {
        modalId: ModalIdentifiers.MOBILE_SUBMENU,
        dialogType: SubMenuModal,
        dialogProps: {
            elements,
            actionProps,
            postId,
        },
    };

    dispatch(openModal(submenuModalData));
}

export function sendEphemeralPost(message: string, channelId?: string, parentId?: string, userId?: string): ActionFunc {
    return (doDispatch: DispatchFunc, doGetState: GetStateFunc) => {
        const timestamp = getTimestamp();
        const post = {
            id: Utils.generateId(),
            user_id: userId || '0',
            channel_id: channelId || getCurrentChannelId(doGetState()),
            message,
            type: PostTypes.EPHEMERAL,
            create_at: timestamp,
            update_at: timestamp,
            root_id: parentId,
            props: {},
        };

        return doDispatch(handleNewPost(post));
    };
}

export function sendAddToChannelEphemeralPost(user: UserProfile, addedUsername: string, addedUserId: string, channelId: string, postRootId = '', timestamp: number) {
    const post = {
        id: Utils.generateId(),
        user_id: user.id,
        channel_id: channelId || getCurrentChannelId(getState()),
        message: '',
        type: PostTypes.EPHEMERAL_ADD_TO_CHANNEL,
        create_at: timestamp,
        update_at: timestamp,
        root_id: postRootId,
        props: {
            username: user.username,
            addedUsername,
            addedUserId,
        },
    };

    dispatch(handleNewPost(post));
}

let lastTimeTypingSent = 0;
export function emitLocalUserTypingEvent(channelId: string, parentPostId: string) {
    const userTyping = async (actionDispatch: DispatchFunc, actionGetState: GetStateFunc) => {
        const state = actionGetState();
        const config = getConfig(state);

        if (
            isPerformanceDebuggingEnabled(state) &&
            getBool(state, Preferences.CATEGORY_PERFORMANCE_DEBUGGING, Preferences.NAME_DISABLE_TYPING_MESSAGES)
        ) {
            return {data: false};
        }

        const t = Date.now();
        const stats = getCurrentChannelStats(state);
        const membersInChannel = stats ? stats.member_count : 0;

        const timeBetweenUserTypingUpdatesMilliseconds = Utils.stringToNumber(config.TimeBetweenUserTypingUpdatesMilliseconds);
        const maxNotificationsPerChannel = Utils.stringToNumber(config.MaxNotificationsPerChannel);

        if (((t - lastTimeTypingSent) > timeBetweenUserTypingUpdatesMilliseconds) &&
            (membersInChannel < maxNotificationsPerChannel) && (config.EnableUserTypingMessages === 'true')) {
            WebSocketClient.userTyping(channelId, parentPostId);
            lastTimeTypingSent = t;
        }

        return {data: true};
    };

    return dispatch(userTyping);
}

export function emitUserLoggedOutEvent(redirectTo = SIGNIN_ROUTE, shouldSignalLogout = true, userAction = true) {
    const state = getState();

    // If the logout was intentional, discard knowledge about having previously been logged in.
    // This bit is otherwise used to detect session expirations on the login page.
    if (userAction) {
        LocalStorageStore.setWasLoggedIn(false, state);
    }

    dispatch(logout()).then(() => {
        if (shouldSignalLogout) {
            BrowserStore.signalLogout();
        }

        stopPeriodicStatusUpdates();
        WebsocketActions.close();

        clearUserCookie();

        browserHistory.push(redirectTo);
    }).catch(() => {
        browserHistory.push(redirectTo);
    });
}

export function toggleSideBarRightMenuAction() {
    return (doDispatch: DispatchFunc) => {
        doDispatch(closeRightHandSide());
        doDispatch(closeLhs());
        doDispatch(closeRhsMenu());
    };
}

export async function getTeamRedirectChannelIfIsAccesible(user: UserProfile, team: Team) {
    let state = getState();
    let channel = null;

    const myMember = getMyTeamMember(state, team.id);
    if (!myMember || Object.keys(myMember).length === 0) {
        return null;
    }

    let teamChannels = getChannelsNameMapInTeam(state, team.id);
    if (!teamChannels || Object.keys(teamChannels).length === 0) {
        // This should be executed in pretty limited scenarios (empty teams)
        await dispatch(fetchMyChannelsAndMembers(team.id)); // eslint-disable-line no-await-in-loop
        state = getState();
        teamChannels = getChannelsNameMapInTeam(state, team.id);
    }

    const channelName = LocalStorageStore.getPreviousChannelName(user.id, team.id, state);
    channel = teamChannels[channelName];

    if (typeof channel === 'undefined') {
        const dmList = getAllDirectChannels(state);
        channel = dmList.find((directChannel) => directChannel.name === channelName);
    }

    let channelMember: ChannelMembership | null | undefined;
    if (channel) {
        channelMember = getMyChannelMember(state, channel.id);
    }

    if (!channel || !channelMember) {
        // This should be executed in pretty limited scenarios (when the last visited channel in the team has been removed)
        await dispatch(getChannelByNameAndTeamName(team.name, channelName)); // eslint-disable-line no-await-in-loop
        state = getState();
        teamChannels = getChannelsNameMapInTeam(state, team.id);
        channel = teamChannels[channelName];
        channelMember = getMyChannelMember(state, channel && channel.id);
    }

    if (!channel || !channelMember) {
        const redirectedChannelName = getRedirectChannelNameForTeam(state, team.id);
        channel = teamChannels[redirectedChannelName];
        channelMember = getMyChannelMember(state, channel && channel.id);
    }

    if (channel && channelMember) {
        return channel;
    }

    return null;
}

export function redirectToSelfDirectChannel(team: Team, user: UserProfile) {
    browserHistory.push(`/${team.name}/messages/@${user.username}`);
}

export function redirectUserToUserInfoForm() {
    browserHistory.push('/installation_user_info');
}

export function openDeeplink(url: string, search: string) {
    const searchParams = new URLSearchParams(search);
    searchParams.set('protocol', window.location.protocol.replace(':', ''));

    window.location.assign(`timeapp://${window.location.host}/${url}/?${searchParams}`);
}

export async function redirectUserToDefaultTeam(fromRoot = false, preferredTeamName = '') {
    let state = getState();

    const license = getLicense(state);
    const config = getConfig(state);

    // Assume we need to load the user if they don't have any team memberships loaded or the user loaded
    let user = getCurrentUser(state);
    const shouldLoadUser = Utils.isEmptyObject(getTeamMemberships(state)) || !user;

    if (shouldLoadUser) {
        await dispatch(loadMe());
        state = getState();
        user = getCurrentUser(state);
    }

    if (!user) {
        return;
    }

    const locale = getCurrentLocale(state);
    const teamId = fromRoot ? LocalStorageStore.getCurrentTeamId(user.id, state) : LocalStorageStore.getPreviousTeamId(user.id, state);

    let myTeams = getMyTeams(state);

    if (myTeams.length === 0) {
        if (checkIfMFARequired(user, license, config, '')) {
            browserHistory.push('/mfa/setup');
            return;
        }

        if (canSelectOrCreateTeam(state)) {
            browserHistory.push('/select_team');
            return;
        }

        dispatch(setAccessDenied());
        return;
    }

    let team: Team | undefined;
    if (teamId) {
        team = getTeam(state, teamId);
    }

    if (preferredTeamName) {
        team = getTeamByName(state, preferredTeamName);
        if (!team) {
            browserHistory.push('/select_team');
            return;
        }
    }

    if (team && team.delete_at === 0) {
        const channel = await getTeamRedirectChannelIfIsAccesible(user, team);
        if (channel) {
            dispatch(selectChannel(channel.id));
            browserHistory.push(`/${team.name}/channels/${channel.name}`);

            if (fromRoot) {
                showOpenInMobileAppPage();
            }
            return;
        }
    }

    myTeams = filterAndSortTeamsByDisplayName(myTeams, locale);

    for (const myTeam of myTeams) {
        // This should execute async behavior in a pretty limited set of situations, so shouldn't be a problem
        const channel = await getTeamRedirectChannelIfIsAccesible(user, myTeam); // eslint-disable-line no-await-in-loop
        if (channel) {
            dispatch(selectChannel(channel.id));
            browserHistory.push(`/${myTeam.name}/channels/${channel.name}`);

            if (fromRoot) {
                showOpenInMobileAppPage();
            }
            return;
        }
    }

    dispatch(setAccessDenied());
}

export function showOpenInMobileAppPage() {
    const iosDownloadLink = getConfig(store.getState()).IosAppDownloadLink;
    const androidDownloadLink = getConfig(store.getState()).AndroidAppDownloadLink;

    const searchParams = new URLSearchParams(location.search);
    const toResetPasswordScreen = location.pathname === '/reset_password_complete';

    // redirect to the mobile landing page if the user hasn't seen it before
    let mobileLanding;
    if (UserAgent.isAndroidWeb()) {
        mobileLanding = androidDownloadLink;
    } else if (UserAgent.isIosWeb()) {
        mobileLanding = iosDownloadLink;
    }

    if (
        mobileLanding &&
        !searchParams.get('stay_in_browser') &&
        !toResetPasswordScreen &&
        !location.pathname.includes('/landing') &&
        !location.pathname.includes('/error')
    ) {
        browserHistory.push('/landing#' + location.pathname + location.search);
    }
}
