import React, {type ReactNode, type CSSProperties} from 'react';
import {FormattedMessage, type MessageDescriptor} from 'react-intl';
import classNames from 'classnames';

import SearchIcon from 'components/widgets/icons/search_icon';
import {NoGroupsIcon} from 'components/widgets/icons/no_groups_icon';

import {NoResultsVariant, NoResultsLayout} from './types';

interface Props {
    expanded?: boolean;
    iconGraphic?: ReactNode;
    title?: ReactNode;
    subtitle?: ReactNode;
    variant?: NoResultsVariant;
    titleValues?: Record<string, ReactNode>;
    subtitleValues?: Record<string, ReactNode>;
    style?: CSSProperties;
    layout?: NoResultsLayout;
    titleClassName?: string;
    subtitleClassName?: string;
}

const iconMap: {[key in NoResultsVariant]: React.ReactNode } = {
    [NoResultsVariant.ChannelSearch]: <SearchIcon className='no-results__icon'/>,
    [NoResultsVariant.Mentions]: <span>&#128237;</span>,
    [NoResultsVariant.FlaggedPosts]: <span>&#128450;</span>,
    [NoResultsVariant.PinnedPosts]: <span>&#128204;</span>,
    [NoResultsVariant.ChannelFiles]: <span>&#128193;</span>,
    [NoResultsVariant.ChannelFilesFiltered]: <span>&#128193;</span>,
    [NoResultsVariant.UserGroups]: <NoGroupsIcon />,
    [NoResultsVariant.UserGroupMembers]: <i className='icon icon-account-outline no-results__icon'/>,
};
const classNameMap: {[key in NoResultsVariant]: string } = {
    [NoResultsVariant.ChannelSearch]: 'no-results__variant-wrapper',
    [NoResultsVariant.UserGroups]: 'no-results__emoji-wrapper',
    [NoResultsVariant.UserGroupMembers]: 'no-results__variant-wrapper',
    [NoResultsVariant.PinnedPosts]: 'no-results__emoji-wrapper',
    [NoResultsVariant.ChannelFiles]: 'no-results__emoji-wrapper',
    [NoResultsVariant.ChannelFilesFiltered]: 'no-results__emoji-wrapper',
    [NoResultsVariant.Mentions]: 'no-results__emoji-wrapper',
    [NoResultsVariant.FlaggedPosts]: 'no-results__emoji-wrapper',
};

const titleMap: {[key in NoResultsVariant]: MessageDescriptor} = {
    [NoResultsVariant.ChannelSearch]: {
        id: 'no_results.channel_search.title',
        defaultMessage: 'No results for {channelName}',
    },
    [NoResultsVariant.Mentions]: {
        id: 'no_results.mentions.title',
        defaultMessage: 'No mentions yet',
    },
    [NoResultsVariant.FlaggedPosts]: {
        id: 'no_results.flagged_posts.title',
        defaultMessage: 'No Saved posts yet',
    },
    [NoResultsVariant.PinnedPosts]: {
        id: 'no_results.pinned_posts.title',
        defaultMessage: 'No pinned posts yet',
    },
    [NoResultsVariant.ChannelFiles]: {
        id: 'no_results.channel_files.title',
        defaultMessage: 'No files yet',
    },
    [NoResultsVariant.ChannelFilesFiltered]: {
        id: 'no_results.channel_files_filtered.title',
        defaultMessage: 'No files found',
    },
    [NoResultsVariant.UserGroups]: {
        id: 'no_results.user_groups.title',
        defaultMessage: 'No groups yet',
    },
    [NoResultsVariant.UserGroupMembers]: {
        id: 'no_results.user_group_members.title',
        defaultMessage: 'No members yet',
    },
};

const subtitleMap: {[key in NoResultsVariant]: MessageDescriptor} = {
    [NoResultsVariant.ChannelSearch]: {
        id: 'no_results.channel_search.subtitle',
        defaultMessage: 'Check the spelling or try another search.',
    },
    [NoResultsVariant.Mentions]: {
        id: 'no_results.mentions.subtitle',
        defaultMessage: 'Messages where someone mentions you or includes your trigger words are saved here.',
    },
    [NoResultsVariant.FlaggedPosts]: {
        id: 'no_results.flagged_posts.subtitle',
        defaultMessage: 'Saved messages are only visible to you. Mark messages for follow-up or save something for later by clicking the {icon} to save them here.',
    },
    [NoResultsVariant.PinnedPosts]: {
        id: 'no_results.pinned_posts.subtitle',
        defaultMessage: 'Pin important messages which are visible to the whole channel. Open the context menu on a message and choose {text} to save it here.',
    },
    [NoResultsVariant.ChannelFiles]: {
        id: 'no_results.channel_files.subtitle',
        defaultMessage: 'Files posted in this channel will show here.',
    },
    [NoResultsVariant.ChannelFilesFiltered]: {
        id: 'no_results.channel_files_filtered.subtitle',
        defaultMessage: 'This channel doesn\'t contains any file with the selected file format.',
    },
    [NoResultsVariant.UserGroups]: {
        id: 'no_results.user_groups.subtitle',
        defaultMessage: 'Groups are a custom collection of users that can be used for mentions and invites.',
    },
    [NoResultsVariant.UserGroupMembers]: {
        id: 'no_results.user_group_members.subtitle',
        defaultMessage: 'There are currently no members in this group, please add one.',
    },
};

import './no_results_indicator.scss';

const getIconGraphic = (variant: NoResultsVariant | undefined) => {
    if (!variant) {
        return null;
    }

    return (
        <div className={classNameMap[variant]}>
            {iconMap[variant]}
        </div>
    );
};

const NoResultsIndicator = ({
    expanded,
    style,
    variant,
    iconGraphic = getIconGraphic(variant),
    titleValues,
    title = variant ? (
        <FormattedMessage
            {...titleMap[variant]}
            values={titleValues}
        />
    ) : null,
    subtitleValues,
    subtitle = variant ? (
        <FormattedMessage
            {...subtitleMap[variant]}
            values={subtitleValues}
        />
    ) : null,
    layout = NoResultsLayout.Vertical,
    titleClassName,
    subtitleClassName,
}: Props) => {
    let content = (
        <div
            className={classNames('no-results__wrapper', {'horizontal-layout': layout === NoResultsLayout.Horizontal})}
            style={style}
        >
            {iconGraphic}

            <div
                className='no-results__text-container'
            >
                {title && (
                    <h3 className={classNames('no-results__title', {'only-title': !subtitle}, titleClassName)}>
                        {title}
                    </h3>
                )}

                {subtitle && (
                    <div className={classNames('no-results__subtitle', subtitleClassName)}>
                        {subtitle}
                    </div>
                )}
            </div>

        </div>
    );

    if (expanded) {
        content = (
            <div className='no-results__holder'>
                {content}
            </div>
        );
    }

    return content;
};

export default NoResultsIndicator;
