import {Client4} from 'mattermost-redux/client';

export function getUsersRoute() {
    return Client4.getUsersRoute();
}

export function composeUsersRoute(route: string) {
    const baseRoute = getUsersRoute();

    return baseRoute + route;
}
