import {FormatBoldIcon} from '@time-webkit/all/icons/format-bold';

import {FormatItalicIcon} from '@time-webkit/all/icons/format-italic';
import {FormatStrikeIcon} from '@time-webkit/all/icons/format-strike';
import {FormatHeaderIcon} from '@time-webkit/all/icons/format-header';
import {CodeTagsIcon} from '@time-webkit/all/icons/code-tags';
import {FormatQuoteOpenIcon} from '@time-webkit/all/icons/format-quote-open';
import {FormatListBulletedIcon} from '@time-webkit/all/icons/format-list-bulleted';
import {FormatListNumberedIcon} from '@time-webkit/all/icons/format-list-numbered';
import {VerticalLineIcon} from '@time-webkit/all/icons/vertical-line';

import {useEditorContext} from '../../context';

import styles from './styles.module.css';
import {FormattingButton} from './components/button';

// import {Link} from './components/link';

const Divider = () => {
    return (
        <div className={styles.divider}>
            <VerticalLineIcon />
        </div>
    );
};

export const FormattingBar = () => {
    const {editor, features} = useEditorContext();
    const {codeBlock} = features;

    return (
        <div className={styles.bar} data-qa='editor_button_style_list'>
            <FormattingButton
                isActive={editor?.isFocused && editor?.isActive('bold')}
                onPress={() => editor?.chain().focus().toggleBold().run()}
                icon={<FormatBoldIcon />}
                data-qa='editor_button_bold'
            />
            <FormattingButton
                isActive={editor?.isFocused && editor?.isActive('italic')}
                onPress={() => editor?.chain().focus().toggleItalic().run()}
                icon={<FormatItalicIcon />}
                data-qa='editor_button_italic'
            />
            <FormattingButton
                isActive={editor?.isFocused && editor?.isActive('strike')}
                onPress={() => editor?.chain().focus().toggleStrike().run()}
                icon={<FormatStrikeIcon />}
                data-qa='editor_button_strike'
            />
            <FormattingButton
                isActive={editor?.isFocused && editor?.isActive('heading')}
                onPress={() => editor?.chain().focus().toggleHeading({level: 3}).run()}
                icon={<FormatHeaderIcon />}
                data-qa='editor_button_heading'
            />
            {/* <Divider /> */}
            {/* <Link /> */}
            {codeBlock ? (
                <FormattingButton
                    isActive={editor?.isFocused && editor?.isActive('codeBlock')}
                    onPress={() => editor?.chain().focus().toggleCodeBlock().run()}
                    icon={<CodeTagsIcon />}
                    data-qa='editor_button_codeBlock'
                />
            ) : null}
            <Divider />
            <FormattingButton
                isActive={editor?.isFocused && editor?.isActive('blockquote')}
                onPress={() => editor?.chain().focus().toggleBlockquote().run()}
                icon={<FormatQuoteOpenIcon />}
                data-qa='editor_button_blockquote'
            />
            <FormattingButton
                isActive={editor?.isFocused && editor?.isActive('bulletList')}
                onPress={() => editor?.chain().focus().toggleBulletList().run()}
                icon={<FormatListBulletedIcon />}
                data-qa='editor_button_bulletList'
            />
            <FormattingButton
                isActive={editor?.isFocused && editor?.isActive('orederedList')}
                onPress={() => editor?.chain().focus().toggleOrderedList().run()}
                icon={<FormatListNumberedIcon />}
                data-qa='editor_button_orederedList'
            />
        </div>
    );
};
