import {AxiosRequestConfig} from 'axios';

import type {AutocompleteSuggestion} from '@mattermost/types/autocomplete';

import {makeGetRequest} from '../request';

import {composeTeamsRoute} from './helpers';

type ListCommandAutocompleteSuggestionsRequestParams = {

    /**
     * Team GUID
     */
    team_id: string;

    channel_id?: string;

    root_id?: string;

    /**
     * String inputted by the user.
     */
    user_input: string;
};

/**
 * List commands' autocomplete data for the team.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/ListCommandAutocompleteSuggestions
 * @example /api/v4/teams/{team_id}/commands/autocomplete_suggestions?channel_id={channel_id}&root_id=undefined&user_input=input
 */
export function listCommandAutocompleteSuggestions(
    args: ListCommandAutocompleteSuggestionsRequestParams,
    options: AxiosRequestConfig<never> = {},
) {
    const {team_id: teamId, user_input: userInput, root_id: rootId, channel_id: channelId} = args;

    const params = {
        user_input: userInput,
        root_id: rootId,
        channel_id: channelId,
    };

    const route = composeTeamsRoute(`/${teamId}/commands/autocomplete_suggestions`);

    return makeGetRequest<AutocompleteSuggestion[]>(route, {
        ...options,
        params,
    });
}
