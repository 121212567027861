import {createAsyncThunk} from '@reduxjs/toolkit';

import {DateTime} from 'luxon';

import {ChannelTypes} from 'mattermost-redux/action_types';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';

import type {ThunkConfig} from 'stores/redux_store';
import {type DirectAddedEvent} from '../types';
import {type UserCustomStatus} from '@mattermost/types/users';

import {receivedUsers, type User} from 'features/users';

import {loadCustomEmojisIfNeeded} from 'actions/loadCustomEmojisIfNeeded';

import {addChannelToInitialCategory} from './add_channel_to_initial_category';

export const handleDirectOrGroupChannelAddedEvent = createAsyncThunk<void, DirectAddedEvent, ThunkConfig>(
    'sidebar/actions/handleDirectOrGroupChannelAddedEvent',
    async (payload, thunkAPI) => {
        const {data} = payload;
        const {channel, users} = data;
        const dispatch = thunkAPI.dispatch;
        const state = thunkAPI.getState();
        const currentUserId = getCurrentUserId(state);

        dispatch({
            type: ChannelTypes.RECEIVED_CHANNEL,
            data: channel,
        });

        await dispatch(
            addChannelToInitialCategory({
                channel,
                setOnServer: false,
            }),
        );

        const otherUsers = users.filter(({id}) => id !== currentUserId);

        const customEmojisToLoad: string[] = [];

        otherUsers.forEach((user) => {
            if (user.props && user.props.customStatus) {
                const customStatus: UserCustomStatus = JSON.parse(user.props.customStatus);
                const expiresAt = customStatus.expires_at || '';
                const isExpired = !expiresAt || DateTime.fromISO(expiresAt) < DateTime.now();
                const hasCustomEmoji = Boolean(customStatus?.emoji);

                if (!isExpired && hasCustomEmoji) {
                    customEmojisToLoad.push(customStatus?.emoji);
                }
            }
        });

        dispatch(loadCustomEmojisIfNeeded(customEmojisToLoad));

        await dispatch(receivedUsers(otherUsers as User[]));
    },
);
