import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const WebhookIncomingIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg
        viewBox='0 0 24 24'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
        ref={ref}
    >
        <path d='M15.3004 11.1L13.9004 8.49996C14.2004 8.19996 14.3004 7.79996 14.4004 7.39996C14.4004 6.39996 13.6004 5.49996 12.6004 5.39997C11.6004 5.39997 10.6004 6.19996 10.6004 7.19996C10.6004 8.19996 11.4004 9.09996 12.5004 9.19996H12.7004L14.0004 11.7C14.3004 11.4 14.8004 11.3 15.3004 11.1ZM9.70038 8.59997C9.00038 6.99997 9.70038 5.19996 11.2004 4.49996C12.8004 3.79996 14.6004 4.59996 15.2004 6.19996C15.6004 7.09996 15.5004 8.09996 15.1004 8.89996L16.3004 9.59997C17.0004 8.39997 17.1004 6.89996 16.5004 5.49996C15.5004 3.19997 12.8004 1.99996 10.5004 2.99996C8.20038 3.99996 7.10038 6.69996 8.10038 8.99996C8.30038 9.59996 8.80038 10.2 9.20038 10.6L6.90038 14.4C6.50038 14.3 6.10038 14.4 5.70038 14.6C4.80038 15.1 4.40038 16.2 4.90038 17.2C5.40038 18.1 6.60038 18.5 7.50038 18C8.40038 17.5 8.80038 16.4 8.30038 15.5C8.30038 15.4 8.20038 15.4 8.20038 15.3L11.3004 10.3L11.0004 10.1C10.4004 9.79996 9.90038 9.29996 9.70038 8.59997ZM11.1004 15.6H9.80038V16.6C9.70038 17.1 9.60038 17.6 9.20038 18C8.20038 19.4 6.30038 19.8 4.90038 18.8C3.50038 17.8 3.20038 15.9 4.20038 14.5C4.80038 13.7 5.60038 13.2 6.60038 13.1V11.7C5.20038 11.8 3.90038 12.4 3.00038 13.7C1.60038 15.8 2.00038 18.7 4.10038 20.1C6.20038 21.6 9.00038 21.1 10.5004 19C10.8004 18.5 11.0004 18 11.1004 17.5C11.0004 17.2 11.0004 16.9 11.0004 16.5C11.0004 16.2 11.0004 15.9 11.1004 15.6ZM16.0004 15.5V13.5L13.0004 16.5L16.0004 19.5V17.5H20.0004V15.5H16.0004Z' />
    </svg>
));
