import type {TeamInviteWithError} from '@mattermost/types/teams';

import {type UserProfile} from 'mattermost-redux/types/users';

import {InvitationError} from './invitation_error';

export type InvitationResult = {
    readonly email: string;
    readonly error: InvitationError | null;
    readonly profile?: UserProfile;
}

export function createInvitationResult(invitation: TeamInviteWithError, profile?: UserProfile): InvitationResult {
    const error = InvitationError.fromInvitation(invitation);

    if (error?.type === 'unknown') {
        throw new Error('unknow invitation error');
    }
    return {
        email: invitation.email,
        error,
        profile,
    };
}
