import {BUTTON_SIZE, IconButton} from '@time-webkit/all/atoms/icon-button';
import React from 'react';

// import {VerticalLineIcon} from '@time-webkit/all/icons/vertical-line';
import classNames from 'classnames';

// import {ViewGridPlusOutlineIcon} from '@time-webkit/all/icons/view-grid-plus-outline';

import {AtIcon} from '@time-webkit/all/icons/at';
import {TildeIcon} from '@time-webkit/all/icons/tilde';
import {EmoticonPlusPlusOutlineIcon} from '@time-webkit/all/icons/emoticon-plus-outline';

import HashTagIcon from 'components/widgets/icons/hash_tag_icon';

import {useEditorContext} from '../../context';

import styles from './styles.module.css';

// const Divider = () => {
//     return (
//         <div className={styles.divider}>
//             <VerticalLineIcon />
//         </div>
//     );
// };

const ActionButton = (props: React.ComponentProps<typeof IconButton>) => {
    return <IconButton {...props} flat={true} className={classNames(styles.button)} size={BUTTON_SIZE.SMALL} />;
};

export const ActionsBar: React.FC = (props) => {
    const {features, editor} = useEditorContext();
    const {emoji, mention} = features;

    const handleMention = React.useCallback(() => {
        return editor?.commands.setMention();
    }, [editor]);

    const handleChannelMention = React.useCallback(() => {
        return editor?.commands.setChannelMention();
    }, [editor]);

    const handleHashtagMention = React.useCallback(() => {
        return editor?.commands.setHashtagMention();
    }, [editor]);

    return (
        <div className={styles.bar}>
            {/* <ActionButton icon={<ViewGridPlusOutlineIcon />} />
            <Divider /> */}
            {/* <ActionButton icon={<FormatLetterCaseUpperIcon />} /> */}
            {mention?.isActive ? <ActionButton onPress={handleMention} icon={<AtIcon />} /> : null}
            <ActionButton onPress={handleChannelMention} icon={<TildeIcon />} />
            <ActionButton onPress={handleHashtagMention} icon={<HashTagIcon />} />
            {emoji?.isActive ? <ActionButton icon={<EmoticonPlusPlusOutlineIcon />} /> : null}
            {props.children}
        </div>
    );
};
