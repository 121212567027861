import {Client4} from 'mattermost-redux/client';

export function getEmojiRoute() {
    return Client4.getEmojisRoute();
}

export function composeEmojiRoute(route: string) {
    const baseRoute = getEmojiRoute();

    return baseRoute + route;
}
