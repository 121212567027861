import React from 'react';
import classnames from 'classnames';

import {ELEVATION_LEVEL, ELEVATION_THEME, useElevation} from '../../../hooks/elevation';

import type {Props} from './types';
import styles from './styles.module.css';

export const Elevation: React.FunctionComponent<Props> = (props) => {
    const {level = ELEVATION_LEVEL.ONE, theme = ELEVATION_THEME.DAY} = props;
    const cls = useElevation(level, theme);

    return <div className={classnames(cls, styles.base)}>{props.children}</div>;
};
