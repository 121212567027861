import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const EmoticonPlusPlusOutlineIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            fill='currentColor'
            d='M14.5 12c.8 0 1.5-.7 1.5-1.5S15.3 9 14.5 9s-1.5.7-1.5 1.5.7 1.5 1.5 1.5Zm-6 0c.8 0 1.5-.7 1.5-1.5S9.3 9 8.5 9 7 9.7 7 10.5 7.7 12 8.5 12ZM19 12v.5A7.5 7.5 0 1 1 12 5a6 6 0 0 1 .8-2h-1.3a9.5 9.5 0 1 0 9.4 8.2 6 6 0 0 1-2 .7ZM17 2h2v3h3v2h-3v3h-2V7h-3V5h3V2ZM6.4 14.5a5.5 5.5 0 0 0 10.2 0H6.4Z'
        />
    </svg>
));
