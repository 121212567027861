import isEmpty from '@tinkoff/utils/is/empty';

import {getRedirectChannelNameForTeam} from 'mattermost-redux/selectors/entities/channels';

import {getCurrentTeamId, getCurrentTeamName} from 'mattermost-redux/selectors/entities/teams';

import {useAppSelector} from 'stores/redux_store';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';

import {
    getDraftsInit,
    getTeamAndUserThreadAndChannelDraftsSorted,
    getTotalNumberOfTeamDrafts,
    selectChannelDraftByChannelId,
    selectThreadDraftByRootPostId,
} from './logic/selectors';
import {type Draft} from './types';
import {makeEmptyDraft} from './logic/utils';

function useEmptyDraft(draftId: Draft['draftId'], type: Draft['type']) {
    const teamId = useAppSelector(getCurrentTeamId);
    const userId = useAppSelector(getCurrentUserId);
    return makeEmptyDraft({draftId, teamId, userId, type});
}

export function useChannelDraft(id: Draft['draftId']) {
    const draft = useAppSelector((state) => selectChannelDraftByChannelId(state, id));
    const emptyDraft = useEmptyDraft(id, 'channel');
    return draft || emptyDraft;
}

export function useHasChannelDraft(id: Draft['draftId']) {
    const hasDraft = useAppSelector((state) => !isEmpty(selectChannelDraftByChannelId(state, id)));

    return hasDraft;
}

export function useThreadDraft(id: Draft['draftId']) {
    const draft = useAppSelector((state) => selectThreadDraftByRootPostId(state, id));
    const emptyDraft = useEmptyDraft(id, 'thread');
    return draft || emptyDraft;
}

export function useDraftsInit() {
    const init = useAppSelector(getDraftsInit);

    return init;
}

export function useDefaultChannelLink() {
    const teamName = useAppSelector(getCurrentTeamName);
    const currentTeamId = useAppSelector(getCurrentTeamId);
    const channelName = useAppSelector((state) => getRedirectChannelNameForTeam(state, currentTeamId));

    return `/${teamName}/channels/${channelName}`;
}

export function useCurrentTeamDrafts() {
    const drafts = useAppSelector(getTeamAndUserThreadAndChannelDraftsSorted);

    return drafts;
}

export function useCurrentTeamDraftsTotal() {
    const total = useAppSelector(getTotalNumberOfTeamDrafts);

    return total;
}
