import React from 'react';
import classNames from 'classnames';

import {BUTTON_SIZE, IconButton} from '@time-webkit/all/atoms/icon-button';

import styles from '../../styles.module.css';

export const FormattingButton = (
    props: React.ComponentProps<typeof IconButton> & {
        isActive?: boolean;
    },
) => {
    const {isActive, ...btnProps} = props;
    return (
        <IconButton
            {...btnProps}
            flat={true}
            className={classNames(styles.button, isActive && styles.isActiveBtn)}
            size={BUTTON_SIZE.SMALL}
        />
    );
};
