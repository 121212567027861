import classnames from 'classnames';

import {LEVEL, THEME} from './constants';
import styles from './styles.module.css';

/**
 * Генерирует класс elevation
 */
export function useElevation(level: LEVEL = LEVEL.ONE, theme: THEME = THEME.DAY) {
    return classnames(styles.base, styles[level], styles[theme]);
}
