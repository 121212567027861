import type {Channel} from '@mattermost/types/channels';

import omit from '@tinkoff/utils/object/omit';

import type {ChannelMention} from './types';

function mapChannelToChannelMention(channel: Channel): ChannelMention {
    return {
        displayName: channel.display_name,
        handle: channel.name,
        type: channel.type,
        id: channel.name,
    };
}

export function transformAutocompleteChannelsForTeamResponse(response: Channel[]): ChannelMention[] {
    return response.map(mapChannelToChannelMention);
}

/**
 * Very basic ranking algorithm for channels
 * API strangely sorts them, so we have to do that ourselves
 */
export function prioritizeChannelMentionsByQuery(channelMentions: ChannelMention[], query: string): ChannelMention[] {
    return channelMentions.
        map((mention) => {
            if (mention.handle === query) {
                return {
                    ...mention,
                    rank: 0,
                };
            }

            if (mention.displayName === query) {
                return {
                    ...mention,
                    rank: 1,
                };
            }

            if (mention.handle.includes(query)) {
                return {
                    ...mention,
                    rank: 2,
                };
            }

            if (mention.displayName.includes(query)) {
                return {
                    ...mention,
                    rank: 3,
                };
            }

            if (mention.handle.includes(query.slice(0, query.length / 2))) {
                return {
                    ...mention,
                    rank: 4,
                };
            }

            if (mention.displayName.includes(query.slice(0, query.length / 2))) {
                return {
                    ...mention,
                    rank: 5,
                };
            }

            return {
                ...mention,
                rank: 6,
            };
        }).
        sort((a, b) => (a.rank > b.rank ? 1 : -1)).
        map((mention) => omit(['rank'], mention));
}
