import {type ComponentProps, useMemo, useRef} from 'react';

import {useOverlayTriggerState} from '@react-stately/overlays';

import classNames from 'classnames';

import {TagEditable} from '@time-webkit/all/organisms/input-tag';

import {Avatar} from '@time-webkit/all/atoms/avatar';
import {type UserProfile} from '@mattermost/types/users';
import {PopoverOverlay, PopoverOverlayProvider} from '@time-webkit/all/molecules/popover';
import {Client4} from 'mattermost-redux/client';
import ProfilePopover from '../../../profile_popover';

import {imageURLForUser} from 'utils/utils';

import styles from './tag-profile.module.css';

type Props = ComponentProps<typeof TagEditable> & {
    profile?: UserProfile;
}

const DEFAULT_OVERLAY_STATE = {};

export const TagProfile = ({profile, className, ...props}: Props) => {
    const tagRef = useRef<HTMLElement>(null);
    const popoverState = useOverlayTriggerState(DEFAULT_OVERLAY_STATE);
    const avatar = useMemo(() => {
        if (!profile) {
            return null;
        }
        const avatarUrl = Client4.getProfilePictureUrl(profile.id, profile.last_picture_update || Date.now());
        return (
            <Avatar
                username={profile.username}
                src={avatarUrl}
                size={16}
            />
        );
    }, [profile]);

    return (
        <>
            <TagEditable
                {...props}
                ref={tagRef}
                className={classNames(styles.root, className)}
                icon={avatar}
                onMouseEnter={() => popoverState.open()}
                onMouseLeave={() => popoverState.close()}
            />
            {profile && (
                <PopoverOverlayProvider>
                    <PopoverOverlay state={popoverState} triggerNode={tagRef.current}>
                        <ProfilePopover
                            isInteractive={false}
                            userId={profile.id}
                            src={imageURLForUser(profile.id, profile.last_picture_update)}
                        />
                    </PopoverOverlay>
                </PopoverOverlayProvider>
            )}

        </>

    );
};
