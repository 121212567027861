import {type Editor, Node, textblockTypeInputRule} from '@tiptap/core';
import classNames from 'classnames';
import {Plugin, PluginKey} from 'prosemirror-state';
import {NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer} from '@tiptap/react';

import * as SyntaxHighlighting from 'utils/syntax_highlighting';
import {lowlight} from 'lowlight';
import highlight from 'highlight.js/lib/core';

import {lowlightPlugin} from './lowlight-plugin';

import styles from './styles.module.css';

export interface CodeBlockOptions {

    /**
   * Define whether the node should be exited on triple enter.
   * Defaults to `true`.
   */
    exitOnTripleEnter: boolean;

    /**
   * Define whether the node should be exited on arrow down if there is no node after it.
   * Defaults to `true`.
   */
    exitOnArrowDown: boolean;

    ctrlSend: boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        codeBlock: {

            /**
       * Set a code block
       */
            setCodeBlock: (attributes?: { language: string }) => ReturnType;

            /**
       * Toggle a code block
       */
            toggleCodeBlock: (attributes?: { language: string }) => ReturnType;
        };
    }
}

const backtickInputRegex = /^```([a-z]+)?[\s\n]$/gm;

type CodeBlockComponentProps = {
    editor: Editor;
    node: {
        attrs: {
            language?: string;
        };
    };
    extension: {
        options: CodeBlockOptions;
    };
};

const CodeBlockComponent = (props: CodeBlockComponentProps) => {
    const exitOnTripleEnter = props.extension.options.exitOnTripleEnter;
    const blockSeparator = exitOnTripleEnter ? '\n\n\n' : '\n\n';

    let text = props.editor.getText({blockSeparator});

    if (text.endsWith('\n\n\n') && exitOnTripleEnter) {
        text = text.substring(0, text.length - 3);
    }

    const selectedLanguage = highlight.getLanguage(props.node.attrs.language || '')?.name?.toLowerCase();

    return (
        <NodeViewWrapper className={styles.codeBlockWraper}>
            {selectedLanguage && (
                <div className={styles.lineNumbers}>
                    {SyntaxHighlighting.generateLineNumbers(text).map((number) => (
                        <span key={number}>{number}</span>
                    ))}
                </div>
            )}
            <pre className={classNames(styles.codeBlock, {[styles.langDetected]: selectedLanguage})}>
                <NodeViewContent
                    data-language={selectedLanguage}
                    style={{whiteSpace: 'pre'}}
                    className={styles.code}
                    as='code'
                />
            </pre>
        </NodeViewWrapper>
    );
};

// additional aliases
lowlight.registerAlias('javascript', ['javascriptreact']);
lowlight.registerAlias('typescript', ['typescriptreact']);

export const TimeWebkitCodeBlockExtension = Node.create<CodeBlockOptions>({
    name: 'codeBlock',

    addOptions() {
        return {
            exitOnTripleEnter: true,
            exitOnArrowDown: true,
            ctrlSend: false,
        };
    },

    addNodeView() {
        // eslint-disable-next-line new-cap
        return ReactNodeViewRenderer(CodeBlockComponent, {className: styles.codeBlockRenderer});
    },

    content: 'text*',

    marks: '',

    group: 'block',

    code: true,

    defining: true,

    addAttributes() {
        return {
            language: {
                default: null,
                parseHTML: (element) => {
                    const language = (element.firstElementChild as HTMLElement | undefined)?.dataset?.language;

                    if (!language) {
                        return null;
                    }

                    return language;
                },
                rendered: false,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'pre',
                preserveWhitespace: 'full',
            },
        ];
    },

    renderHTML({node, HTMLAttributes}) {
        return [
            'pre',
            {...HTMLAttributes, class: classNames(HTMLAttributes.class, styles.codeBlock)},
            [
                'code',
                {
                    'data-language': node.attrs.language ? node.attrs.language : undefined,
                },
                0,
            ],
        ];
    },

    addCommands() {
        return {
            setCodeBlock: (attributes) => ({commands}) => {
                return commands.setNode(this.name, attributes);
            },
            toggleCodeBlock: (attributes) => ({commands}) => {
                return commands.toggleNode(this.name, 'paragraph', attributes);
            },
        };
    },

    addKeyboardShortcuts() {
        const ctrlSend = this.options.ctrlSend;

        const handleEnter = (editor: Editor) => {
            if (!this.options.exitOnTripleEnter) {
                return false;
            }

            const {state} = editor;
            const {selection} = state;
            const {$from, empty} = selection;

            if (!empty || $from.parent.type !== this.type) {
                return false;
            }

            const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;
            const endsWithDoubleNewline = $from.parent.textContent.endsWith('\n\n');

            if (!isAtEnd || !endsWithDoubleNewline) {
                return false;
            }

            return editor.
                chain().
                command(({tr}) => {
                    tr.delete($from.pos - 2, $from.pos);

                    return true;
                }).
                exitCode().
                run();
        };

        return {
            'Mod-Alt-c': () => this.editor.commands.toggleCodeBlock(),

            // remove code block when at start of document or code block is empty
            Backspace: () => {
                const {empty, $anchor} = this.editor.state.selection;
                const isAtStart = $anchor.pos === 1;

                if (!empty || $anchor.parent.type.name !== this.name) {
                    return false;
                }

                if (isAtStart || !$anchor.parent.textContent.length) {
                    return this.editor.commands.clearNodes();
                }

                return false;
            },

            // exit node on triple enter
            Enter: ({editor}) => {
                if (!ctrlSend) {
                    return false;
                }

                return handleEnter(editor);
            },
            'Shift-Enter': ({editor}) => {
                return handleEnter(editor);
            },

            'Mod-Enter': ({editor}) => {
                if (ctrlSend) {
                    return false;
                }

                return handleEnter(editor);
            },

            // exit node on arrow down
            ArrowDown: ({editor}) => {
                if (!this.options.exitOnArrowDown) {
                    return false;
                }

                const {state} = editor;
                const {selection, doc} = state;
                const {$from, empty} = selection;

                if (!empty || $from.parent.type !== this.type) {
                    return false;
                }

                const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

                if (!isAtEnd) {
                    return false;
                }

                const after = $from.after();

                if (after === undefined) {
                    return false;
                }

                const nodeAfter = doc.nodeAt(after);

                if (nodeAfter) {
                    return false;
                }

                return editor.commands.exitCode();
            },
        };
    },

    addInputRules() {
        return [
            textblockTypeInputRule({
                find: backtickInputRegex,
                type: this.type,
                getAttributes: (match) => ({
                    language: match[1],
                }),
            }),

        ];
    },
    addProseMirrorPlugins() {
        return [

            // this plugin creates a code block for pasted content from VS Code
            // we can also detect the copied code language
            new Plugin({
                key: new PluginKey('codeBlockVSCodeHandler'),
                props: {
                    handlePaste: (view, event) => {
                        if (!event.clipboardData) {
                            return false;
                        }

                        // don’t create a new code block within code blocks
                        if (this.editor.isActive(this.type.name)) {
                            return false;
                        }

                        const text = event.clipboardData.getData('text/plain');

                        const isMultilineCodeBlock = Boolean(((/^```([\s\S]+)```$/gm).exec(text) || [])[1]);

                        const match = text ? [...((/^```([a-z]*)([\s\S]+)```$/gm).exec(text) || [])] : [];
                        const vscode = event.clipboardData.getData('vscode-editor-data');
                        const vscodeData = vscode ? JSON.parse(vscode) : undefined;
                        let language = vscodeData?.mode || match[1];

                        if (language) {
                            language = highlight.getLanguage(language)?.name?.toLowerCase() || language;
                        }

                        const matchedText = match[2] || text;

                        if (isMultilineCodeBlock ? !matchedText : !vscodeData?.mode) {
                            return false;
                        }

                        const {tr} = view.state;

                        let content = null;
                        if (isMultilineCodeBlock) {
                            content = matchedText.trim().replace(/\r\n?/g, '\n');
                        } else {
                            content = matchedText.replace(/\r\n?/g, '\n');

                            if (this.options.exitOnTripleEnter) {
                                content = content.replace(/\n+$/, '\n\n');
                            }
                        }

                        const newNode = this.type.create({language}, this.editor.schema.text(content));

                        // create an empty code block
                        tr.replaceSelectionWith(newNode);

                        // store meta information
                        // this is useful for other plugins that depends on the paste event
                        // like the paste rule plugin
                        tr.setMeta('paste', true);

                        view.dispatch(tr);

                        return true;
                    },
                },
            }),

            lowlightPlugin({
                name: this.name,
                lowlight,
            }),
        ];
    },
});
