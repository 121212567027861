import {createSelector} from 'reselect';

import {getConfig} from 'mattermost-redux/selectors/entities/general';
import type {GlobalState} from 'types/store';
import {getUserDisplayName} from 'features/users/selectors/get_user_display_name';
import type {User} from 'features/users';
import type {Post} from 'mattermost-redux/types/posts';

export const getPostAuthorUserName = createSelector(
    getConfig,
    (state: GlobalState, post: Post) => post.props?.override_username,
    (state: GlobalState, post: Post, user?: User, fallbackName?: string) => getUserDisplayName(state, user, true, fallbackName),
    (config, overrideUsername, displayName) => {
        if (config.EnablePostUsernameOverride === 'true' && overrideUsername) {
            return overrideUsername;
        }

        return displayName;
    },
);
