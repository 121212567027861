import {UserAutocomplete} from '@mattermost/types/autocomplete';
import {Group} from '@mattermost/types/groups';
import {UserProfile} from '@mattermost/types/users';
import {Client4} from 'mattermost-redux/client';

import type {GroupMention, UserMention} from './types';

function mapUserProfileToUserMention(userProfile: UserProfile): UserMention {
    const baseUrl = Client4.getUrl();

    return {
        data: userProfile,
        displayName: `${userProfile.first_name} ${userProfile.last_name}`.trim(),
        handle: userProfile.username || userProfile.username,
        label: userProfile.username,
        id: userProfile.username,
        type: 'user',
        imageUrl: `${baseUrl}/api/v4/users/${userProfile.id}/image`,
    };
}

export function transformUsersAutocompleteResponse(response: UserAutocomplete): UserMention[] {
    const {users, out_of_channel: outOfChannel = []} = response;

    return users.map(mapUserProfileToUserMention).concat(outOfChannel.map(mapUserProfileToUserMention));
}

function mapGroupToGroupMention(group: Group): GroupMention {
    return {
        data: group,
        displayName: group.display_name,
        handle: group.name,
        label: group.name,
        id: group.name,
        type: 'group',
    };
}

export function transformGroupsResponse(response: Group[]): GroupMention[] {
    return response.map(mapGroupToGroupMention);
}
