import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

import type {RootState} from '../../../stores/redux_store';

export type OpenDesktopSettingsState = {
    shouldOpenDeeplink?: boolean;
}

const initialState: OpenDesktopSettingsState = {};

export const openDesktopSettingsSlice = createSlice({
    name: 'openDesktopSettings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<Partial<OpenDesktopSettingsState>>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const {setSettings: setOpenDesktopSettings} = openDesktopSettingsSlice.actions;

export const getOpenDesktopSettings = (state: RootState) => state.openDesktopSettings;
