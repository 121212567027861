import React from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import Badge from './badge';

type Props = {
    className?: string;
    show?: boolean;
};

const GuestPlusBadge: React.FC<Props> = ({show = true, className = ''}: Props) => (
    <Badge
        className={classNames('GuestBadge', className)}
        show={show}
    >
        <FormattedMessage
            id='post_info.guest_plus'
            defaultMessage='Guest +'
        />
    </Badge>
);

export default GuestPlusBadge;
