import TiptapHeading from '@tiptap/extension-heading';
import TiptapParagraph, {ParagraphOptions} from '@tiptap/extension-paragraph';
import TiptapBlockquote, {BlockquoteOptions} from '@tiptap/extension-blockquote';
import TiptapOl from '@tiptap/extension-ordered-list';
import TiptapUl, {BulletListOptions} from '@tiptap/extension-bullet-list';

import classNames from 'classnames';
import {mergeAttributes, wrappingInputRule} from '@tiptap/core';
import TiptapLink from '@tiptap/extension-link';
import TiptapListItem, {ListItemOptions} from '@tiptap/extension-list-item';
import TiptapHardBreak, {HardBreakOptions} from '@tiptap/extension-hard-break';
import TiptapText from '@tiptap/extension-text';

import {TypographySize, typographyStyles} from '@time-webkit/all/hooks/typography';

import {HeadingLevel} from '@time-webkit/all/atoms/heading';
import {LEVEL_TO_HEADING_TYPOGRAPHY} from '@time-webkit/all/atoms/heading/src/constants';

import customStyles from './styles.module.css';
import appStyles from './app-post-style.module.css';

type CtrlSendOptions = {
    ctrlSend?: boolean;
};

export const TimeWebkitTextExtension = TiptapText.extend({});

export const TimeWebkitHeadingExtension = TiptapHeading.extend({
    renderHTML({HTMLAttributes}) {
        return [
            'h1',
            {
                ...HTMLAttributes,
                class: classNames([
                    HTMLAttributes.class,
                    typographyStyles.base,
                    typographyStyles['base-color'],
                    typographyStyles[LEVEL_TO_HEADING_TYPOGRAPHY[HeadingLevel.SIXTH]],
                    appStyles.markdown__heading,
                ]),
            },
            0,
        ];
    },
    addInputRules() {
        return [];
    },
}).configure({
    levels: [3],
});

export const TimeWebkitParagraphExtension = TiptapParagraph.extend<ParagraphOptions & CtrlSendOptions>({
    renderHTML({HTMLAttributes}) {
        const cssClass = classNames([
            HTMLAttributes.class,
            typographyStyles.base,
            typographyStyles['base-color'],
            typographyStyles[TypographySize.BodyL],
            customStyles.paragraphCaretFix,
            appStyles.paragraph,
        ]);

        return [
            'p',
            {
                ...HTMLAttributes,
                class: cssClass,
            },
            0,
        ];
    },
});

export const TimeWebkitBlockquoteExtension = TiptapBlockquote.extend<BlockquoteOptions & CtrlSendOptions>({
    renderHTML({HTMLAttributes}) {
        return [
            'blockquote',
            {
                ...HTMLAttributes,
                class: classNames([HTMLAttributes.class, appStyles.blockquote]),
            },
            0,
        ];
    },
    addInputRules() {
        return [];
    },
});

export const TimeWebkitOrderedListExtension = TiptapOl.extend({
    renderHTML({HTMLAttributes}) {
        const {start, ...attributesWithoutStart} = HTMLAttributes;

        return start === 1 ? [
            'ol',
            {
                ...this.options.HTMLAttributes,
                ...attributesWithoutStart,
                class: classNames([HTMLAttributes.class, appStyles.markdown__list]),
            },
            0,
        ] : [
            'ol',
            {
                ...this.options.HTMLAttributes,
                ...HTMLAttributes,
                class: classNames([HTMLAttributes.class, appStyles.markdown__list]),
            },
            0,
        ];
    },
});

const bulletListInputRegex = /^\s*([-*])\s$/;
export const TimeWebkitBulletListExtension = TiptapUl.extend<BulletListOptions & CtrlSendOptions>({
    renderHTML({HTMLAttributes}) {
        return [
            'ul',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                class: classNames([HTMLAttributes.class, appStyles.markdown__list]),
            }),
            0,
        ];
    },

    addInputRules() {
        return [
            wrappingInputRule({
                find: bulletListInputRegex,
                type: this.type,
            }),
        ];
    },
});

export const TimeWebkitLinkExtension = TiptapLink.extend({
    renderHTML({HTMLAttributes}) {
        return [
            'a',
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                class: classNames(HTMLAttributes.class, appStyles.markdown__link),
                title: HTMLAttributes.href,
            }),
            0,
        ];
    },
}).configure({
    openOnClick: false,
    protocols: ['ftp', 'mailto', 'mattermost', 'time', 'http', 'https'],
});

export const TimeWebkitListItemExtension = TiptapListItem.extend<ListItemOptions & CtrlSendOptions>({
    addKeyboardShortcuts() {
        const ctrlSend = this.options.ctrlSend;

        const handleEnter = () => this.editor.commands.splitListItem(this.name);

        const shortcuts = {
            'Shift-Enter': handleEnter,
            'Alt-Enter': handleEnter,
            Tab: () => this.editor.commands.sinkListItem(this.name),
            'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
        };

        if (ctrlSend) {
            return {
                ...shortcuts,
                Enter: handleEnter,
            };
        }
        return {
            ...shortcuts,
            'Mod-Enter': handleEnter,
        };
    },
});

export const TimeWebkitHardBreakExtension = TiptapHardBreak.extend<HardBreakOptions & CtrlSendOptions>({
    addCommands() {
        return {
            setHardBreak: () => () => false,
        };
    },
    addKeyboardShortcuts() {
        const ctrlSend = this.options.ctrlSend;

        const handleEnter = () => this.editor.commands.setHardBreak();

        const shortcuts = {
            'Shift-Enter': handleEnter,
            'Alt-Enter': handleEnter,
        };

        if (ctrlSend) {
            return {
                ...shortcuts,
                Enter: handleEnter,
            };
        }
        return {
            ...shortcuts,
            'Mod-Enter': handleEnter,
        };
    },
});
