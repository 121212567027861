export {setDraftAction} from './actions';
export {useDrafts} from './provider';
export {useChannelDraft, useThreadDraft, useHasChannelDraft, useCurrentTeamDrafts} from './hooks';
export type {Draft} from './types';
export {draftsReducer, draftsInitialState, draftsReducerName} from './logic/slice';
export {getThreadDraft} from './logic/selectors';
// eslint-disable-next-line no-duplicate-imports
export type {DraftsReducerState} from './logic/slice';
export {DRAFTS_ROUTE} from './constants';

import {draftsPersistMiddleware} from './logic/drafts_persist_middleware';
export {areDraftsEqual} from './logic/utils';

export const middlewares = [draftsPersistMiddleware];
