import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';
import classNames from 'classnames';

import {useRef} from 'react';

import {useTooltipTriggerState} from '@react-stately/tooltip';
import {useTooltipTrigger} from '@react-aria/tooltip';

import {Tooltip, TooltipTextPrimary} from '@time-webkit/all/atoms/tooltip';

import {useIntl} from 'react-intl';

import {formatLimitCounter} from '../../utils';

import styles from './styles.module.css';

type Props = {
    max: number;
    current: number;
    className?: string;
};

export const LimitCounter = ({current, max, className}: Props) => {
    const bodySCls = useTypography({
        size: TypographySize.BodyS,
        noDefaultColor: true,
    });
    const targetRef = useRef(null);
    const state = useTooltipTriggerState({delay: 400});
    const {formatMessage} = useIntl();

    const {triggerProps, tooltipProps} = useTooltipTrigger({}, state, targetRef);

    if (current <= max) {
        return null;
    }

    return (
        <>
            <span className={classNames(styles.container, className, bodySCls)} {...triggerProps} ref={targetRef}>
                −{formatLimitCounter(current - max)}
            </span>
            {state.isOpen && (
                <Tooltip {...tooltipProps} state={state} targetRef={targetRef} offset={3} containerPadding={4}>
                    <TooltipTextPrimary className={styles.tooltipText}>
                        {formatMessage({
                            id: 'editor.errors.characters_limit.tooltip',
                            defaultMessage: 'You can add up to {count} symbols',
                        }, {
                            count: formatLimitCounter(max),
                        })}
                    </TooltipTextPrimary>
                </Tooltip>
            )}
        </>
    );
};
