import {forwardRef, useRef} from 'react';
import type {ElementType, ForwardedRef, RefObject} from 'react';
import classnames from 'classnames';
import {useTooltipTriggerState} from '@react-stately/tooltip';
import {useTooltipTrigger} from '@react-aria/tooltip';
import {useButton} from '@react-aria/button';
import {useFocusRing} from '@react-aria/focus';
import {mergeProps, mergeRefs} from '@react-aria/utils';
import type {AriaButtonProps} from '@react-aria/button';
import type {FocusableElement} from '@react-types/shared';

import {Tooltip} from '../../../atoms/tooltip';

import styles from './icon-button.module.css';

const TOOLTIP_DELAY = 200;

type Props<Component extends ElementType = 'button'> = AriaButtonProps<Component> & {
    className?: string;
    tooltip?: string;
    tooltipDelay?: number;
    tooltipDisabled?: boolean;
    download?: string;
    as?: Component;
};

export const ActionButton = forwardRef(
    <Component extends ElementType>(
        {
            className,
            tooltip: tooltipText,
            tooltipDelay = TOOLTIP_DELAY,
            tooltipDisabled,
            children,
            as: elementType,
            ...props
        }: Props<Component>,
        passedRef: ForwardedRef<Element>,
    ) => {
        const tooltipState = useTooltipTriggerState({delay: tooltipDelay, isDisabled: tooltipDisabled});
        const ownRef = useRef<Element>(null);
        const {buttonProps} = useButton({...props, elementType}, ownRef);
        const {focusProps, isFocusVisible} = useFocusRing();
        const mergedRef = mergeRefs(ownRef, passedRef);
        const {triggerProps, tooltipProps} = useTooltipTrigger({}, tooltipState, ownRef as RefObject<FocusableElement>);

        const tooltip = tooltipText ? (
            <Tooltip {...tooltipProps} targetRef={ownRef} state={tooltipState}>
                {tooltipText}
            </Tooltip>
        ) : null;

        const ComponentProp: React.ElementType = elementType ?? 'button';

        // workarond for react-aria issue
        const additionalProps = {
            download: elementType === 'a' ? props.download : undefined,
        };

        return (
            <>
                <ComponentProp
                    {...mergeProps(additionalProps, buttonProps, focusProps, triggerProps)}
                    ref={mergedRef}
                    className={classnames(className, styles.button, {
                        [styles.hideFocus]: !isFocusVisible,
                    })}
                >
                    {children}
                </ComponentProp>
                {tooltipState.isOpen && !tooltipDisabled && tooltip}
            </>
        );
    },
);
