import {Client4} from 'mattermost-redux/client';

export function getGroupsRoute() {
    return Client4.getGroupsRoute();
}

export function composeGroupsRoute(route: string) {
    const baseRoute = getGroupsRoute();

    return baseRoute + route;
}
