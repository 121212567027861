import {createAsyncThunk} from '@reduxjs/toolkit';

import {type PreferenceType} from '@mattermost/types/preferences';

import {savePreferences} from 'mattermost-redux/actions/preferences';

import {Preferences} from 'mattermost-redux/constants';

import {getChannelMessageCount, getMyChannelMember} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getUser} from 'mattermost-redux/selectors/entities/users';
import {calculateUnreadCount} from 'mattermost-redux/utils/channel_utils';

import type {AppDispatch, RootState} from 'stores/redux_store';
import {getUserIdFromChannelId} from 'utils/getUserIdFromChannelId';
import type {User} from 'features/users';
import {loadCustomEmojisForCustomStatusesByUserIds} from 'actions/emoji_actions';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {getDMChannelsForTeam} from '../selectors/get_dm_channels_for_team';
import {fetchUsersByIds, fetchUsersByIdsDebounced} from 'features/users/actions/fetch_users_by_ids';
import {isChannelArchived} from 'features/channels/utils/is_channel_archived';
import {isChannelEmpty} from 'features/channels/utils/is_channel_empty';
import {getDMChannelShowPreference} from '../selectors/get_dm_channel_show_preference';

import type {Team} from 'mattermost-redux/types/teams';

type Payload = {teamId: Team['id']; noDebounce?: boolean} | undefined;

export const getUsersForDMChannels = createAsyncThunk(
    'sidebar/actions/getUsersForDMChannels',
    (payload: Payload, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as RootState;

        const currentTeamId = getCurrentTeamId(state);
        const teamId = payload?.teamId ?? currentTeamId;

        const dmChannels = getDMChannelsForTeam(state, teamId);

        const newPreferences: PreferenceType[] = [];
        const profilesToLoad: Array<User['id']> = [];
        const profileIds: Array<User['id']> = [];

        const currentUserId = getCurrentUserId(state);

        dmChannels.forEach((channel) => {
            const teammateId = getUserIdFromChannelId(channel.name, currentUserId);

            const isVisible = getDMChannelShowPreference(state, teammateId, false);
            const isArchived = isChannelArchived(channel);
            const teammate = getUser(state, teammateId) as User | undefined;
            const teammateDeactivated = teammate && teammate?.delete_at > 0;
            const isCreatedByCurrentUser = channel.creator_id === currentUserId;
            const isEmptyChannel = isChannelEmpty(channel);

            /**
             * Не подгружаем пользователей для DM
             * который создан кем-то другим и в него ничего не писали
             */
            if (!isCreatedByCurrentUser && isEmptyChannel) {
                return;
            }

            if (!isVisible && !isArchived && !teammateDeactivated) {
                const member = getMyChannelMember(state, channel.id);
                const messageCount = getChannelMessageCount(state, channel.id);

                if (!member) {
                    return;
                }

                const unreadCount = calculateUnreadCount(messageCount, member);

                /**
                 * Если есть непрочитанные и ЛС скрыт пользователем - показываем его снова
                 */
                if (unreadCount.showUnread) {
                    newPreferences.push({
                        user_id: currentUserId,
                        category: Preferences.CATEGORY_DIRECT_CHANNEL_SHOW,
                        name: teammateId,
                        value: 'true',
                    });
                }
            }

            if (!getUser(state, teammateId)) {
                profilesToLoad.push(teammateId);
            }

            profileIds.push(teammateId);
        });

        if (newPreferences.length > 0) {
            dispatch(savePreferences(currentUserId, newPreferences));
        }

        if (profilesToLoad.length > 0) {
            dispatch(payload?.noDebounce ? fetchUsersByIds({userIds: profilesToLoad}) : fetchUsersByIdsDebounced(profilesToLoad));
        }

        dispatch(loadCustomEmojisForCustomStatusesByUserIds(profileIds));
    },
);
