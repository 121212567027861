import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const FormatListBulletedIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            d='M9 11v2h12v-2H9Zm0 6v2h12v-2H9ZM9 5v2h12V5H9Zm-2.9393 5.9393a1.5 1.5 0 1 0-2.1213 2.1214 1.5 1.5 0 0 0 2.1213-2.1214Zm0 6a1.5 1.5 0 1 0-2.1213 2.1214 1.5 1.5 0 0 0 2.1213-2.1214Zm0-12a1.5 1.5 0 1 0-2.1214 2.1214 1.5 1.5 0 0 0 2.1214-2.1214Z'
            fill='currentColor'
        />
    </svg>
));
