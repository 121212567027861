import {AxiosRequestConfig} from 'axios';

import type {CustomEmoji} from '@mattermost/types/emojis';

import {makeGetRequest} from '../request';

import {composeEmojiRoute} from './helpers';

type AutocompleteEmojiRequestParams = {

    /**
     * The emoji name to search.
     */
    name: string;
};

/**
 * Get a list of custom emoji with names starting with or matching the provided name.
 * Returns a maximum of 100 results.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/AutocompleteEmoji
 */
export function autocompleteEmoji(args: AutocompleteEmojiRequestParams, options: AxiosRequestConfig<never> = {}) {
    const params: AutocompleteEmojiRequestParams = {
        name: args.name,
    };
    const route = composeEmojiRoute('/autocomplete');

    return makeGetRequest<CustomEmoji[]>(route, {
        ...options,
        params,
    });
}
