import React, {type ReactNode, type ComponentProps} from 'react';

import {type Overlay} from 'react-bootstrap';

import {IntlContext, type IntlShape} from 'react-intl';

import {Provider} from 'react-redux';

import store from 'stores/redux_store';

import BootstrapOverlayTrigger from './bootstrap_overlay_trigger';

type Props = Partial<ComponentProps<typeof BootstrapOverlayTrigger>> & Partial<ComponentProps<typeof Overlay>> & {
    disabled?: boolean;
    className?: string;
    children: ReactNode;
    delayHide?: number;
    delayShow?: number;
};

const defaultTrigger = ['hover', 'focus'];
const OverlayTrigger = (props: Props) => {
    const {
        overlay,
        disabled,
        delay,
        delayShow,
        delayHide,
        defaultOverlayShown = false,
        defaultShow = defaultOverlayShown,
        trigger = defaultTrigger,
        ...otherProps
    } = props;

    // The overlay is rendered outside of the regular React context, and our version react-bootstrap can't forward
    // that context itself, so we have to manually forward the react-intl context to this component's child.
    const OverlayWrapper = ({intl, ...overlayProps}: { intl: IntlShape }) => (
        <Provider store={store}>
            <IntlContext.Provider value={intl}>
                {React.cloneElement(overlay, overlayProps)}
            </IntlContext.Provider>
        </Provider>
    );

    return (
        <IntlContext.Consumer>
            {(intl): React.ReactNode => {
                const overlayProps = {...overlay.props};
                if (disabled) {
                    overlayProps.style = {visibility: 'hidden', ...overlayProps.style};
                }
                return (
                    <BootstrapOverlayTrigger
                        {...otherProps}
                        trigger={trigger}
                        defaultShow={defaultShow}
                        delay={delay ?? {
                            show: delayShow,
                            hide: delayHide,
                        }}
                        overlay={
                            <OverlayWrapper
                                {...overlayProps}
                                intl={intl}
                            />
                        }
                    />
                );
            }}
        </IntlContext.Consumer>
    );
};

OverlayTrigger.displayName = 'OverlayTrigger';

export default OverlayTrigger;
