import React from 'react';
import {Overlay} from 'react-bootstrap';
import {type Placement} from '@react-aria/overlays';

import ProfilePopover from 'components/profile_popover';

import {popOverOverlayPosition} from 'utils/position_utils';
import GroupMentionDropdown from 'features/groups_mention/components/group_mention_dropdown';

const SPACE_REQUIRED_FOR_GROUP_POPOVER = 228;
const SPACE_REQUIRED_FOR_PROFILE_POPOVER = 300;

type Props = {
    mentionName: string;
    teammateNameDisplay: string;
    children?: React.ReactNode;
    channelId?: string;
    disableHighlight?: boolean;
    disableGroupHighlight?: boolean;
    isRHS?: boolean;
    isMentionUserCurrentUser: boolean;
    mentionUserDisplayUsername: string;
    mentionUserProfilePicture: string;
    mentionUserId: string;
    mentionUserUsername?: string;
    groupFromMentionNameAllowReference?: boolean;
    groupFromMentionNameName?: string;
    groupFromMentionNameId?: string;
    isGroupMembersViewAvailable: boolean;
    isGroupMember: boolean;
};

type State = {
    show: boolean;
    target?: HTMLAnchorElement;
    placement?: Placement;
};

export default class AtMention extends React.PureComponent<Props, State> {
    overlayRef: React.RefObject<HTMLAnchorElement>;

    static defaultProps: Partial<Props> = {
        isRHS: false,
        disableHighlight: false,
        disableGroupHighlight: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            show: false,
        };

        this.overlayRef = React.createRef();
    }

    handleClick = (e: React.MouseEvent<HTMLAnchorElement>, spaceRequiredAbove: number) => {
        const targetBounds = this.overlayRef.current?.getBoundingClientRect();

        if (targetBounds) {
            const placement = popOverOverlayPosition(targetBounds, window.innerHeight, spaceRequiredAbove) as Placement;
            this.setState({target: e.target as HTMLAnchorElement, show: !this.state.show, placement});
        }
    };

    handleUserMentionClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        this.handleClick(e, SPACE_REQUIRED_FOR_PROFILE_POPOVER);
    };

    handleGroupMentionClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (!this.props.isGroupMembersViewAvailable) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        this.handleClick(e, SPACE_REQUIRED_FOR_GROUP_POPOVER);
    };

    hideOverlay = (event?: Event) => {
        if (event?.target === this.overlayRef?.current) {
            return;
        }
        this.setState({show: false});
    };

    render() {
        if (!this.props.disableGroupHighlight && !this.props.mentionUserUsername) {
            if (this.props.groupFromMentionNameAllowReference) {
                const groupName = this.props.groupFromMentionNameName;

                let className = 'mention-link';

                if (!this.props.disableHighlight && this.props.isGroupMember) {
                    className += ' mention--highlight';
                }

                const suffix = this.props.mentionName.substring((groupName || '').length);

                return (
                    <GroupMentionDropdown
                        overlayRef={this.overlayRef}
                        placement={this.state.placement}
                        open={this.state.show}
                        hide={this.hideOverlay}
                        groupName={groupName}
                        groupId={this.props.groupFromMentionNameId}
                        isGroupMembersViewAvailable={this.props.isGroupMembersViewAvailable}
                        className={className}
                        onClick={this.handleGroupMentionClick}
                        suffix={suffix}
                    />
                );
            }
        }

        if (!this.props.mentionUserUsername) {
            return <React.Fragment>{this.props.children}</React.Fragment>;
        }

        const suffix = this.props.mentionName.substring((this.props.mentionUserUsername || '').length);

        let className = 'mention-link';

        if (!this.props.disableHighlight && this.props.isMentionUserCurrentUser) {
            className += ' mention--highlight';
        }

        return (
            <>
                <Overlay
                    placement={this.state.placement}
                    show={this.state.show}
                    target={this.state.target}
                    rootClose={true}
                    onHide={this.hideOverlay}
                >
                    <ProfilePopover
                        userId={this.props.mentionUserId}
                        src={this.props.mentionUserProfilePicture}
                        isRHS={this.props.isRHS}
                        hide={this.hideOverlay}
                        channelId={this.props.channelId}
                    />
                </Overlay>
                <a
                    className={className}
                    onClick={this.handleUserMentionClick}
                    ref={this.overlayRef}
                >
                    {'@' + this.props.mentionUserDisplayUsername}
                </a>
                {suffix}
            </>
        );
    }
}
