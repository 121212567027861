import {createAsyncThunk, type AsyncThunkPayloadCreator} from '@reduxjs/toolkit';

import {isNewProfileEnabled} from 'mattermost-redux/selectors/entities/preferences';

import {type AppDispatch} from 'stores/redux_store';
import {type GlobalState} from 'types/store';
import {fetchProfile} from '../api/fetch_profile';
import {type Profile} from '../types/profile';

async function getProfilePayloadCreator(userId: Profile['user_id'], thunkAPI: Parameters<AsyncThunkPayloadCreator<Profile>>[1]) {
    const dispatch = thunkAPI.dispatch as AppDispatch;

    try {
        return await fetchProfile({userId}, dispatch);
    } catch (e) {
        return thunkAPI.rejectWithValue('');
    }
}

export const getProfileIfAllowed = createAsyncThunk(
    'profiles/actions/getProfileIfAllowed',
    getProfilePayloadCreator,
    {
        condition: (_userId, thunkAPI) => {
            return isNewProfileEnabled(thunkAPI.getState() as GlobalState);
        },
    },
);

export const getProfile = createAsyncThunk(
    'profiles/actions/getProfile',
    getProfilePayloadCreator,
);
