import uniqBy from '@tinkoff/utils/array/uniqBy';
import omit from '@tinkoff/utils/object/omit';

import type {Post} from '@mattermost/types/posts';
import {receivedUsers, type User} from 'features/users';
import {PostTypes} from 'mattermost-redux/action_types';
import {createThunkAction} from 'stores/create_thunk_action';
import type {ServerThread} from '../types/threads';

import {fetchUsersByIdsDebounced} from 'features/users/actions/fetch_users_by_ids';

import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';
import {getAllPosts} from 'mattermost-redux/selectors/entities/posts';

import {receivedServerThreads} from './received_server_threads';

type Payload = {
    threads: ServerThread[];
    shouldGetMissingProfiles?: boolean;
};

function isFullUser(user: User | {id: User['id']}): user is User {
    return Boolean((user as User).username);
}

export const handleReceivedThreads = (payload: Payload) =>
    createThunkAction('threads/actions/handleReceivedThreads', (dispatch, getState) => {
        const {threads, shouldGetMissingProfiles = true} = payload;
        const currentUserId = getCurrentUserId(getState());
        const entitiesPosts = getAllPosts(getState());

        const threadsParticipants = threads.reduce((flatParticipants, thread) => {
            const {participants} = thread;
            if (participants.length) {
                flatParticipants.push(...(participants as any));
            }
            return flatParticipants;
        }, [] as ServerThread['participants']);

        const uniqueThreadsParticipants: User[] | Array<{id: User['id']}> = uniqBy(({id}) => id, threadsParticipants);

        const fullUsers = uniqueThreadsParticipants.filter(isFullUser).filter((user) => user.id !== currentUserId);

        dispatch(receivedUsers(fullUsers));

        if (uniqueThreadsParticipants.length && shouldGetMissingProfiles) {
            dispatch(fetchUsersByIdsDebounced(uniqueThreadsParticipants.map(({id}) => id)));
        }

        const posts = threads.reduce((posts, thread) => {
            const {post} = thread;
            const entityPost = entitiesPosts[post.id];

            posts[post.id] = merge(thread, entityPost);

            return posts;
        }, {} as Record<Post['id'], Post>);

        dispatch({
            type: PostTypes.RECEIVED_POSTS,
            data: {posts},
        });

        dispatch(receivedServerThreads(threads));
    });

const MISSED_FIELDS_IN_SERVER_THREAD_POST = ['metadata', 'participants'];
export function merge(serverThread: ServerThread, entityPost?: Post): Post {
    /**
     * post внутри ServerThread содержит не все данные, например, там отсутствует metadata,
     * поэтому перезаписываем только те данные, которые получили
     */
    if (!entityPost) {
        return {
            ...serverThread.post,
            is_following: true,
            reply_count: serverThread.reply_count,
            last_reply_at: serverThread.last_reply_at,
        };
    }

    return {
        ...entityPost,
        ...omit(MISSED_FIELDS_IN_SERVER_THREAD_POST, serverThread.post),
        is_following: true,
        reply_count: serverThread.reply_count,
        last_reply_at: serverThread.last_reply_at,
    };
}
