import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const AccountOutlineIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            d='M19.2 15.9 16 14c.2-.3.5-.6.7-1 .8-1.4 1.3-3.2 1.3-5 0-3.6-2.4-6-6-6S6 4.4 6 8c0 1.8.5 3.6 1.3 5.1.2.4.4.7.7 1L4.8 16c-1.1.4-1.8 1.6-1.8 2.8C3 20.6 4.4 22 6.2 22h11.6c1.8 0 3.2-1.4 3.2-3.2 0-1.2-.7-2.4-1.8-2.9ZM8 8c0-2.8 1.8-4 4-4s4 1.2 4 4c0 1.8-.6 3.8-1.8 5-.6.6-1.3 1-2.2 1-.9 0-1.6-.4-2.2-1C8.6 11.8 8 9.8 8 8Zm9.8 12H6.2c-.7 0-1.2-.5-1.2-1.2 0-.5.3-.9.7-1.1l3.9-2.3c.8.4 1.6.6 2.4.6.8 0 1.6-.2 2.3-.6l3.9 2.3c.4.2.7.6.7 1.1.1.7-.4 1.2-1.1 1.2Z'
            fill='currentColor'
        />
    </svg>
));
