import {useEffect, useMemo, useRef, useState} from 'react';
import type {HTMLAttributes, KeyboardEvent, MouseEvent} from 'react';

import classNames from 'classnames';

import {mergeProps, useId} from '@react-aria/utils';

import {InputContainer, useInput, useInputTypography} from '../input';
import type {InputSize} from '../input';

import {BorderRadiusSize, Dropdown, DropdownList} from '../../organisms/dropdown';

import {useListBoxKeyboard, useListBoxState} from '../../organisms/listbox';
import type {ListBoxItem} from '../../organisms/listbox';

import {ChevronDownIcon} from '../../icons/chevron-down';

import {SelectOption} from './select-option';

import styles from './select.module.css';

export type SelectOption = Omit<ListBoxItem, 'disabled'>

export type Props<Value extends string> = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
    options: ListBoxItem[];
    initialValue?: Value | undefined;
    size?: InputSize;
    disabled?: boolean;
    error?: boolean;
    label?: string;
    maxHeight?: number;
    fixedSizeDropdown?: boolean;
    onChange?: (value: Value) => void;
}

const INPUT_SIZE_TO_BORDER_RADIUS_SIZE: Record<InputSize, BorderRadiusSize> = {
    medium: BorderRadiusSize.Medium,
    large: BorderRadiusSize.Large,
};

export const Select = <Value extends string>({
    id,
    options,
    size = 'medium',
    disabled,
    error,
    label,
    placeholder,
    className,
    maxHeight = 200,
    fixedSizeDropdown,
    initialValue,
    onChange,
    ...props
}: Props<Value>) => {
    const isFirstRender = useRef(true);
    const [listBoxState, listBoxActionsOriginal] = useListBoxState({items: options, selectedValue: initialValue});
    const listBoxActions = useMemo(() => ({
        ...listBoxActionsOriginal,
        onSelectItem: (item: ListBoxItem) => {
            listBoxActionsOriginal.onSelectItem(item);
            setPopoverOpen(false);
        },
    }), [listBoxActionsOriginal]);
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputId = useId(id);
    const value = listBoxState.selectedItem?.value as Value ?? '';
    const containerProps = useInput({inputRef, value, inputId, isDisabled: true});
    const valueTypogaphyStyle = useInputTypography(size);
    const listBoxId = useId();
    const {onKeyDown: onListBoxKeyDown} = useListBoxKeyboard(listBoxState, listBoxActions);

    const hasPlaceholder = placeholder !== undefined;
    const hasValue = value !== '';
    const hasLabel = label !== undefined;
    const isPlaceholderVisible = (hasPlaceholder && !hasValue && !hasLabel) || (hasPlaceholder && !hasValue && isPopoverOpen);

    const handleMouseDown = (e: MouseEvent<HTMLElement>) => {
        if (!disabled) {
            setPopoverOpen(!isPopoverOpen);
            e.stopPropagation();
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
        if (['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key) && !isPopoverOpen) {
            if (!disabled) {
                setPopoverOpen(true);
                e.preventDefault();
            }
        }
        if (isPopoverOpen) {
            onListBoxKeyDown(e);
        }
    };

    useEffect(() => {
        if (!isFirstRender.current) {
            listBoxActions.onReset(options, initialValue);
        }
    }, [options, initialValue, listBoxActions, isFirstRender]);

    useEffect(() => {
        if (onChange && !isFirstRender.current) {
            onChange?.(value);
        }
    }, [value, onChange, isFirstRender]);

    useEffect(() => {
        isFirstRender.current = false;
    }, [isFirstRender]);

    const renderItem = (item: ListBoxItem, isFocused?: boolean, isSelected?: boolean) => (
        <SelectOption label={item.label} focused={isFocused} selected={isSelected} />
    );

    return (
        <>
            <InputContainer
                {...mergeProps(containerProps, props)}
                ref={containerRef}
                onMouseDown={handleMouseDown}
                tabIndex={0}
                className={classNames(styles.root, className)}
                size={size}
                disabled={disabled}
                error={error}
                label={label}
                onKeyDown={handleKeyDown}
            >
                {listBoxState.selectedItem && (
                    <span className={classNames(styles.value, valueTypogaphyStyle, {[styles.withLabel]: hasLabel})}>
                        {listBoxState.selectedItem.label}
                    </span>
                )}
                {isPlaceholderVisible && (
                    <span
                        className={classNames(styles.placeholder, valueTypogaphyStyle, {[styles.withLabel]: hasLabel})}
                    >
                        {placeholder}
                    </span>
                )}
                <div className={styles.spacer} />
                <span
                    aria-hidden='true'
                    className={classNames(styles.chevronIcon, isPopoverOpen && styles.inverted)}
                >
                    <ChevronDownIcon
                        width={16}
                        height={16}
                    />
                </span>
                <input
                    id={inputId}
                    tabIndex={-1}
                    aria-expanded={isPopoverOpen}
                    role='combobox'
                    aria-controls={isPopoverOpen ? listBoxId : undefined}
                    className={styles.input}
                    value={value}
                    readOnly={true}
                />
            </InputContainer>
            <Dropdown
                targetRef={containerRef}
                placement='bottom left'
                open={isPopoverOpen}
                shouldFlip={true}
                shouldMirrorWidth={!fixedSizeDropdown}
                maxHeight={maxHeight}
                onClose={() => setPopoverOpen(false)}
                borderRadiusSize={INPUT_SIZE_TO_BORDER_RADIUS_SIZE[size]}
            >
                <DropdownList
                    state={listBoxState}
                    actions={listBoxActions}
                    items={options}
                    id={listBoxId}
                    renderItem={renderItem}
                />
            </Dropdown>
        </>
    );
};
