import {type MutableRefObject, useMemo, useRef, useEffect} from 'react';

import {useIsMounted} from 'usehooks-ts';

/*
 * Browsers including Internet Explorer, Chrome, Safari, and Firefox store the
 * delay as a 32-bit signed integer internally. This causes an integer overflow
 * when using delays larger than 2,147,483,647 ms (about 24.8 days),
 * resulting in the timeout being executed immediately.
 *
 * via: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/setTimeout
 */
const MAX_DELAY_MS = (2 ** 31) - 1;

function setChainedTimeout(
    handleRef: MutableRefObject<unknown>,
    fn: () => void,
    timeoutAtMs: number,
) {
    const delayMs = timeoutAtMs - Date.now();

    handleRef.current =
    delayMs <= MAX_DELAY_MS ? setTimeout(fn, delayMs) : setTimeout(
        () => setChainedTimeout(handleRef, fn, timeoutAtMs),
        MAX_DELAY_MS,
    );
}

/**
 * Returns a controller object for setting a timeout that is properly cleaned up
 * once the component unmounts. New timeouts cancel and replace existing ones.
 *
 *
 *
 * ```tsx
 * const { set, clear } = useTimeout();
 * const [hello, showHello] = useState(false);
 * //Display hello after 5 seconds
 * set(() => showHello(true), 5000);
 * return (
 *   <div className="App">
 *     {hello ? <h3>Hello</h3> : null}
 *   </div>
 * );
 * ```
 */
export default function useTimeout() {
    const isMounted = useIsMounted();

    const handleRef = useRef<number>();

    useEffect(() => {
        return () => clearTimeout(handleRef.current);
    }, []);

    return useMemo(() => {
        const clear = () => clearTimeout(handleRef.current);

        function set(fn: () => void, delayMs = 0): void {
            if (!isMounted()) {
                return;
            }

            clear();

            if (delayMs <= MAX_DELAY_MS) {
                // For simplicity, if the timeout is short, just set a normal timeout.
                handleRef.current = window.setTimeout(fn, delayMs);
            } else {
                setChainedTimeout(handleRef, fn, Date.now() + delayMs);
            }
        }

        return {
            set,
            clear,
            handleRef,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
