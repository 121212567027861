import type {ComponentProps, PropsWithChildren} from 'react';
import {useRef, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';

import {MenuOverlay} from './menu_overlay';

import './menu_wrapper.scss';

type Props = {
    id?: string;
    className?: string;
    open?: boolean;
    onToggle?: (open: boolean) => void;
    placement?: ComponentProps<typeof MenuOverlay>['placement'];
    menuContainerClassName?: string;
};

const MenuWrapper = ({id, className, children, placement, onToggle: onToggleMenu, open = false, menuContainerClassName}: PropsWithChildren<Props>) => {
    const [isOpen, setOpen] = useState(open);
    const trigger = useRef<HTMLDivElement>(null);
    const menuContainer = useRef<HTMLDivElement>(null);

    const toggleMenu = useCallback((value: boolean) => {
        setOpen(value);
        onToggleMenu?.(value);
    }, [onToggleMenu]);

    const handleTriggerClick = useCallback((event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        toggleMenu(!isOpen);
    }, [isOpen, toggleMenu]);

    const handleOnClose = useCallback(() => toggleMenu(false), [toggleMenu]);

    if (!Array.isArray(children) || children.length !== 2) {
        throw new Error('MenuWrapper needs exactly 2 children');
    }

    useEffect(() => {
        let triggerElement: HTMLDivElement;
        if (trigger.current) {
            triggerElement = trigger.current;
            triggerElement.addEventListener('click', handleTriggerClick, true);
        }
        return (() => triggerElement?.removeEventListener('click', handleTriggerClick, true));
    }, [handleTriggerClick]);

    return (
        <div
            id={id}
            className={classNames('MenuWrapper', className, {'MenuWrapper--open': isOpen})}
            data-menu-open={isOpen}
            data-qa='post_dotmenu'
            data-testid='post_dotmenu'
        >
            <div ref={trigger}>{children ? Object.values(children)[0] : {}}</div>
            {isOpen && (
                <MenuOverlay
                    isOpen={isOpen}
                    triggerRef={trigger}
                    placement={placement}
                    onClose={handleOnClose}
                >
                    <div ref={menuContainer} className={classNames(menuContainerClassName, 'MenuWrapper-container')}>{children ? Object.values(children)[1] : {}}</div>
                </MenuOverlay>
            )}
        </div>
    );
};

export default MenuWrapper;
