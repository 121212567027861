import classNames from 'classnames';
import {createPortal} from 'react-dom';
import {useEffect, useRef, useState, type AnimationEvent} from 'react';
import type {PropsWithChildren} from 'react';
import {useEventCallback, useOnClickOutside} from 'usehooks-ts';

import {DropdownContainer, BorderRadiusSize} from './dropdown-container';
import {useDropdownPosition} from './use-dropdown-position';

import styles from './dropdown.module.css';

type Props = Omit<Parameters<typeof useDropdownPosition>[0], 'dropdownRef' | 'isOpen' | 'onClose'> & {
    open?: boolean;
    borderRadiusSize?: BorderRadiusSize;
    withTransition?: boolean;
    className?: string;
    onClose?: (event: Event) => void;
    onAnimationEnd?: (event: AnimationEvent) => void;
}

export const Dropdown = ({
    open = false,
    onClose,
    children,
    className,
    borderRadiusSize = BorderRadiusSize.Medium,
    withTransition = false,
    onAnimationEnd,
    ...props
}: PropsWithChildren<Props>) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownProps = useDropdownPosition({...props, dropdownRef, isOpen: open});
    const [waitForTransition, setWaitForTransition] = useState(false);

    useOnClickOutside(dropdownRef, (event) => {
        onClose?.(event);
    });

    const handleKeyDown = useEventCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape' && open) {
            onClose?.(event);
        }
    });

    const handleAnimationEnd = (event: AnimationEvent) => {
        if (event.target !== dropdownRef.current) {
            return;
        }

        onAnimationEnd?.(event);

        setWaitForTransition(open);
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const dropdown = createPortal(
        <DropdownContainer
            {...dropdownProps}
            className={classNames(className, {
                [styles.fadeAnimation]: withTransition,
                [styles.fadeIn]: withTransition && open,
                [styles.fadeOut]: withTransition && !open,
            })}
            onMouseDown={(e) => e.preventDefault()}
            ref={dropdownRef}
            borderRadiusSize={borderRadiusSize}
            onAnimationEnd={handleAnimationEnd}
        >
            {children}
        </DropdownContainer>,
        document.body,
    );

    return (open || (withTransition && waitForTransition)) ? dropdown : null;
};

