import type {SyntheticEvent} from 'react';

import {CalendarClockIcon} from '@time-webkit/all/icons/calendar-clock';

import ListItem from 'components/list_item';
import UserIcon from 'components/widgets/icons/user_icon';
import Email3Icon from 'components/widgets/icons/email3_icon';
import ClockIcon from 'components/widgets/icons/clock_icon';
import Timestamp from 'components/timestamp';
import type {UserTimezone} from '@mattermost/types/users';
import CopyToClipboardButton from 'components/copy_to_clipboard_button';

import {browserHistory} from 'utils/browser_history';

import styles from './styles.module.css';

interface Props {
    appendId?: string;
    teamUrl: string;
    user: {
        username: string;
        email?: string;
        timezone?: UserTimezone;
        schedule?: string;
    };
    hideLogin?: boolean;
    hideEmail?: boolean;
    hideTime?: boolean;
}

export const DetailsArea = ({user, teamUrl, hideLogin, hideEmail, hideTime, appendId}: Props) => {
    const link = `${teamUrl}/messages/@${user.username}`;

    const onNameClick = (e: SyntheticEvent) => {
        e.preventDefault();
        browserHistory.push(link);
    };

    return (
        <div className={styles.detailsArea}>
            {!hideLogin && (
                <ListItem
                    className={styles.detailsAreaItem}
                    image={<UserIcon />}
                    extra={
                        <CopyToClipboardButton
                            appendId={appendId}
                            compact={true}
                            value={user.username}
                        />
                    }
                    compact={true}
                >
                    <a
                        href={link}
                        onClick={onNameClick}
                    >
                        {'@' + user.username}
                    </a>
                </ListItem>
            )}
            {!hideEmail && user.email && (
                <ListItem
                    className={styles.detailsAreaItem}
                    image={<Email3Icon />}
                    extra={
                        <CopyToClipboardButton
                            appendId={appendId}
                            compact={true}
                            value={user.email}
                        />
                    }
                    compact={true}
                >
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                </ListItem>
            )}
            {!hideTime && user.schedule && (
                <ListItem
                    className={styles.detailsAreaItem}
                    image={<CalendarClockIcon className={styles.listItemIcon} />}
                    compact={true}
                >
                    {user.schedule.split(' - ')[0]}&ndash;{user.schedule.split(' - ')[1]}
                </ListItem>
            )}
            {!hideTime && user.timezone && (
                <ListItem
                    className={styles.detailsAreaItem}
                    image={<ClockIcon />}
                    compact={true}
                >
                    <Timestamp
                        useDate={false}
                        useRelative={false}
                        userTimezone={user.timezone}
                        useTime={{
                            hour: 'numeric',
                            minute: 'numeric',
                            timeZoneName: 'short',
                        }}
                    />
                </ListItem>
            )}
        </div>
    );
};
