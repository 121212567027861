import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const AtIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            fill='currentColor'
            d='M12 15c.81 0 1.5-.3 2.11-.89a2.93 2.93 0 0 0 0-4.22 2.93 2.93 0 0 0-4.22 0 2.93 2.93 0 0 0 0 4.22c.61.59 1.3.89 2.11.89Zm0-13c2.75 0 5.1 1 7.05 2.95A9.63 9.63 0 0 1 22 12v1.45c0 1-.35 1.85-1 2.55-.7.67-1.5 1-2.5 1-1.2 0-2.19-.5-2.94-1.5-1 1-2.18 1.5-3.56 1.5-1.37 0-2.55-.5-3.54-1.46A4.91 4.91 0 0 1 7 12c0-1.37.5-2.55 1.46-3.54A4.93 4.93 0 0 1 12 7c1.38 0 2.55.5 3.54 1.46A4.93 4.93 0 0 1 17 12v1.45c0 .41.16.77.46 1.08a1.42 1.42 0 0 0 2.11 0c.3-.31.43-.67.43-1.08V12c0-2.19-.77-4.07-2.35-5.65A7.69 7.69 0 0 0 12 4c-2.19 0-4.07.77-5.65 2.35A7.69 7.69 0 0 0 4 12c0 2.19.77 4.07 2.35 5.65A7.69 7.69 0 0 0 12 20h5v2h-5c-2.75 0-5.1-1-7.05-2.95A9.63 9.63 0 0 1 2 12c0-2.75 1-5.1 2.95-7.05A9.63 9.63 0 0 1 12 2Z'
        />
    </svg>
));
