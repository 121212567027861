import {memo, useCallback} from 'react';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';

import {Avatar} from '@time-webkit/all/atoms/avatar';
import CustomStatusEmoji from 'components/custom_status/custom_status_emoji';
import {getProfileIfAllowed as getProfileAdditionalInfoIfAllowed} from 'features/profiles';
import {receivedUser, type User} from 'features/users';
import {getFullName, imageURLForUser} from 'utils/utils';

import {showUserInfo} from 'actions/views/rhs';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';
import {MAX_DESCRIPTION_LENGTH} from '../constants';

import styles from './group_member_row.module.css';

const GroupMemberRow = ({user, hide}: {user: User; hide?: () => void}) => {
    const [bodyL, bodyS] = useTypography([{size: TypographySize.BodyL}, {size: TypographySize.BodyS}]);
    const dispatch = useDispatch();

    let description;
    if (user.username && (user.first_name || user.last_name)) {
        description = getFullName(user);
    } else {
        description = `${getFullName(user)} ${user.nickname ? `(${user.nickname})` : ''}`.trim();
    }

    const onClick = useCallback(() => {
        dispatch(receivedUser(user));
        dispatch(getProfileAdditionalInfoIfAllowed(user.id));
        dispatch(showUserInfo(user.id));
        hide?.();
    }, [dispatch, hide, user]);

    const withMaxWidth = Boolean(description) && description.length > MAX_DESCRIPTION_LENGTH;

    const customStatus = (
        <CustomStatusEmoji
            showTooltip={false}
            userID={user.id}
            emojiSize={16}
            emojiStyle={{marginLeft: 4}}
            spanStyle={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        />
    );

    return (
        <div
            data-testid='groupMemberRow'
            className={styles.row}
            onClick={onClick}
            role='button'
        >
            <Avatar
                username={user && user.username}
                size={24}
                src={imageURLForUser(user.id, user.last_picture_update)}
                className={styles.avatar}
            />
            {description && <span className={classNames(bodyL, styles.fullName, styles.overflow)}>{description}</span>}
            {customStatus}
            <span
                className={classNames(bodyS, styles.tag, styles.overflow, {
                    [styles.maxWidth]: withMaxWidth,
                    [styles.tagMargin]: Boolean(description),
                })}
            >
                {'@' + user.username}
            </span>
        </div>
    );
};

export default memo(GroupMemberRow);
