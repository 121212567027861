import {type User} from 'features/users';
import {General} from 'mattermost-redux/constants';

export function parseUsernamesFromText(text: string): Array<User['username']> {
    // defined inline to reset regex state due to "g" flag
    const USERNAME_PATTERN = /\B@(([a-z0-9_.-]*[a-z0-9_])[.-]*)/gim;
    const usernamesSet = new Set<string>();

    let match;

    while ((match = USERNAME_PATTERN.exec(text)) !== null) {
        // match[1] is the matched mention including trailing punctuation
        // match[2] is the matched mention without trailing punctuation
        if (General.SPECIAL_MENTIONS.indexOf(match[2]) !== -1) {
            continue;
        }

        // If there's no trailing punctuation, this will only add 1 item to the set
        usernamesSet.add(match[1].toLowerCase());
        usernamesSet.add(match[2].toLowerCase());
    }

    return Array.from(usernamesSet);
}
