import type {Channel} from '@mattermost/types/channels';
import type {GlobalState} from '@mattermost/types/store';
import type {Team} from '@mattermost/types/teams';
import type {UserProfile} from '@mattermost/types/users';

import {getBasePath} from 'selectors/general';

const getPreviousTeamIdKey = (userId: UserProfile['id']) => ['user_prev_team', userId].join(':');

const getCurrentTeamIdKey = (userId: UserProfile['id']) => ['user_cur_team', userId].join(':');

const getPreviousChannelNameKey = (userId: UserProfile['id'], teamId: Team['id']) =>
    ['user_team_prev_channel', userId, teamId].join(':');

export const getPenultimateChannelNameKey = (userId: UserProfile['id'], teamId: Team['id']) =>
    ['user_team_penultimate_channel', userId, teamId].join(':');

const getRecentEmojisKey = (userId: UserProfile['id']) => ['recent_emojis', userId].join(':');

const getWasLoggedInKey = () => 'was_logged_in';

const teamIdJoinedOnLoadKey = 'teamIdJoinedOnLoad';

const getPathScopedKey = (path: string, key: string) => {
    if (path === '' || path === '/') {
        return key;
    }

    return [path, key].join(':');
};

// LocalStorageStore exposes an interface for accessing entries in the localStorage.
//
// Note that this excludes keys managed by redux-persist. The latter cannot currently be used for
// key/value storage that persists beyond logout. Ideally, we could purge all but certain parts
// of the Redux store so as to allow them to be used on re-login.

// Lets open a separate issue to refactor local storage and state interactions.
// This whole store can be connected to redux
class LocalStorageStoreClass {
    getItem(key: string, state: GlobalState) {
        const basePath = getBasePath(state);

        return localStorage.getItem(getPathScopedKey(basePath, key));
    }

    setItem<V extends string>(key: string, value: V, state: GlobalState) {
        const basePath = getBasePath(state);

        localStorage.setItem(getPathScopedKey(basePath, key), value);
    }

    getPreviousChannelName(userId: UserProfile['id'], teamId: Team['id'], state: GlobalState) {
        return (
            this.getItem(getPreviousChannelNameKey(userId, teamId), state)
        );
    }

    removeItem(key: string, state: GlobalState) {
        const basePath = getBasePath(state);

        localStorage.removeItem(getPathScopedKey(basePath, key));
    }

    setPreviousChannelName(
        userId: UserProfile['id'],
        teamId: Team['id'],
        channelName: Channel['name'],
        state: GlobalState,
    ) {
        this.setItem(getPreviousChannelNameKey(userId, teamId), channelName, state);
    }

    getPenultimateChannelName(userId: UserProfile['id'], teamId: Team['id'], state: GlobalState) {
        return (
            this.getItem(getPenultimateChannelNameKey(userId, teamId), state)
        );
    }

    setPenultimateChannelName(
        userId: UserProfile['id'],
        teamId: Team['id'],
        channelName: Channel['name'],
        state: GlobalState,
    ) {
        this.setItem(getPenultimateChannelNameKey(userId, teamId), channelName, state);
    }

    removePreviousChannelName(userId: UserProfile['id'], teamId: Team['id'], state: GlobalState) {
        this.setItem(
            getPreviousChannelNameKey(userId, teamId),
            this.getPenultimateChannelName(userId, teamId, state),
            state,
        );
        this.removeItem(getPenultimateChannelNameKey(userId, teamId), state);
    }

    removePenultimateChannelName(userId: UserProfile['id'], teamId: Team['id'], state: GlobalState) {
        this.removeItem(getPenultimateChannelNameKey(userId, teamId), state);
    }

    getPreviousTeamId(userId: UserProfile['id'], state: GlobalState) {
        return this.getItem(getPreviousTeamIdKey(userId), state);
    }

    getCurrentTeamId(userId: UserProfile['id'], state: GlobalState) {
        return this.getItem(getCurrentTeamIdKey(userId), state);
    }

    setPreviousTeamId(userId: UserProfile['id'], teamId: Team['id'], state: GlobalState) {
        this.setItem(getPreviousTeamIdKey(userId), teamId, state);
    }

    setCurrentTeamId(userId: UserProfile['id'], teamId: Team['id'], state: GlobalState) {
        this.setItem(getCurrentTeamIdKey(userId), teamId, state);
    }

    /**
     * Returns the list of recently used emojis for the user in string format.
     * @param {string} userId The user ID.
     * @returns The list of emojis in string format. eg. '['smile','+1', 'pizza']'
     * @memberof LocalStorageStore
     * @example
     * const recentEmojis = LocalStorageStore.getRecentEmojis('userId', state);
     * if (recentEmojis) {
     *  const recentEmojisArray = JSON.parse(recentEmojis);
     * // do something with the emoji list
     * }
     **/
    getRecentEmojis(userId: UserProfile['id'], state: GlobalState) {
        const recentEmojis = this.getItem(getRecentEmojisKey(userId), state);
        if (!recentEmojis) {
            return null;
        }

        return recentEmojis;
    }

    setRecentEmojis(userId: UserProfile['id'], recentEmojis = [], state: GlobalState) {
        if (recentEmojis.length) {
            this.setItem(getRecentEmojisKey(userId), JSON.stringify(recentEmojis), state);
        }
    }

    getTeamIdJoinedOnLoad(state: GlobalState) {
        return this.getItem(teamIdJoinedOnLoadKey, state);
    }

    setTeamIdJoinedOnLoad(teamId: Team['id'] | null, state: GlobalState) {
        if (!teamId) {
            localStorage.removeItem(teamIdJoinedOnLoadKey);
            return;
        }

        this.setItem(teamIdJoinedOnLoadKey, teamId, state);
    }

    setWasLoggedIn(wasLoggedIn: boolean, state: GlobalState) {
        if (wasLoggedIn) {
            this.setItem(getWasLoggedInKey(), 'true', state);
        } else {
            this.setItem(getWasLoggedInKey(), 'false', state);
        }
    }

    getWasLoggedIn(state: GlobalState) {
        return this.getItem(getWasLoggedInKey(), state) === 'true';
    }
}

const LocalStorageStore = new LocalStorageStoreClass();

export default LocalStorageStore;
