import type {SVGProps} from 'react';

type Props = SVGProps<SVGSVGElement> & {
    size?: number;
};

export const CheckIcon: React.VoidFunctionComponent<Props> = ({size = 24, ...props}) => {
    return (
        <svg fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={size} height={size} {...props} >
            <path d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
        </svg>
    );
};
