import {createAsyncThunk} from '@reduxjs/toolkit';

import type {Team} from '@mattermost/types/teams';
import type {ServerThread} from '../types/threads';
import type {GlobalState} from 'types/store';
import type {AppDispatch} from 'stores/redux_store';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';

import {receivedUsers} from 'features/users';
import {PostTypes} from 'mattermost-redux/action_types';

import {selectThreadById} from '../selectors/select_thread_by_id';

import {getChannel} from 'mattermost-redux/selectors/entities/channels';

import {type Channel} from '@mattermost/types/channels';

import {getChannel as fetchChannel} from 'mattermost-redux/actions/channels';

import {handleThreadsReadChanged} from './handle_threads_read_changed';
import {receivedThread} from './received_thread';
import {getHasUnreadThreadsByTeamId} from './get_has_unread_threads_by_team_id';

type Payload = {
    thread: ServerThread;
    teamId: Team['id'];
    previousUnreadReplies?: number;
    previousUnreadMentions?: number;
};

export const handleThreadArrived = createAsyncThunk(
    'threads/actions/handleThreadArrived',
    async (payload: Payload, thunkAPI) => {
        const {teamId, thread, previousUnreadMentions, previousUnreadReplies} = payload;
        const state = thunkAPI.getState() as GlobalState;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const currentUserId = getCurrentUserId(state);
        const currentTeamId = getCurrentTeamId(state);
        const threadChannelId = thread.post.channel_id;
        const threadChannel: Channel | undefined = getChannel(state, threadChannelId);
        const oldThread = selectThreadById(state, thread.id);

        await dispatch(receivedUsers(thread.participants.filter((user) => user.id !== currentUserId)));

        if (!threadChannel?.id) {
            await dispatch(fetchChannel(threadChannelId));
        }

        const updatedState = thunkAPI.getState() as GlobalState;
        const updatedThreadChannel = getChannel(updatedState, threadChannelId);

        dispatch(
            receivedThread({
                ...thread,
                team_id: updatedThreadChannel?.team_id || '',
                channel_id: threadChannelId,
            }),
        );

        dispatch({
            type: PostTypes.RECEIVED_POST,
            data: {...thread.post, update_at: 0},
            features: {crtEnabled: true},
        });

        const hasPreviousUnreadsAndMentions =
            typeof previousUnreadMentions === 'number' && typeof previousUnreadReplies === 'number';

        /**
         * Если раньше у нас не было этого треда,
         * то скорее всего изменилась статистика тредов
         * мы её и перезапрашиваем
         */
        if (
            !oldThread?.id &&
            (teamId === '' || teamId === currentTeamId) &&
            (thread.unread_mentions > 0 || thread.unread_replies > 0)
        ) {
            dispatch(getHasUnreadThreadsByTeamId({teamId: teamId || currentTeamId}));
        }

        if (hasPreviousUnreadsAndMentions || oldThread) {
            dispatch(getHasUnreadThreadsByTeamId({teamId: teamId || currentTeamId}));

            dispatch(
                handleThreadsReadChanged({
                    threadId: thread.id,
                    teamId: teamId || currentTeamId,
                    channelId: thread.post.channel_id,
                    lastViewedAt: thread.last_viewed_at,
                    newUnreadMentions: thread.unread_mentions,
                    newUnreadReplies: thread.unread_replies,
                    prevUnreadMentions: oldThread?.unread_mentions ?? previousUnreadMentions,
                    prevUnreadReplies: oldThread?.unread_replies ?? previousUnreadReplies,
                    thread,
                }),
            );
        }

        return thread;
    },
);
