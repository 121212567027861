import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const FormatBoldIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            fill='currentColor'
            d='M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42ZM10 6.5h3a1.5 1.5 0 1 1 0 3h-3v-3Zm3.5 9H10v-3h3.5a1.5 1.5 0 1 1 0 3Z'
        />
    </svg>
));
