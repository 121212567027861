// ported from https://gitlab.tcsbank.ru/messenger/time-server/-/blob/f7b9c85e39adc2156cb998758fccce78edf8de15/model/search_params.go#L155
import type {SearchParams} from '../types';

import {parseSearchFlags} from './parse_search_flags';
import {splitWords} from './split_words';

const validHashtag = /^(#\p{L}[\p{L}\d\-_.]*[\p{L}\d])$/u;

export const parseSearchParams = (text: string): SearchParams[] => {
    const {searchWords, flags} = parseSearchFlags(splitWords(text));
    const hashtagTermList: string[] = [];
    const excludedHashtagTermList: string[] = [];
    const plainTermList: string[] = [];
    const excludedPlainTermList: string[] = [];

    for (const word of searchWords) {
        if (validHashtag.test(word.value)) {
            if (word.exclude) {
                excludedHashtagTermList.push(word.value);
            } else {
                hashtagTermList.push(word.value);
            }
        } else if (word.exclude) {
            excludedPlainTermList.push(word.value);
        } else {
            plainTermList.push(word.value);
        }
    }

    const hashtagTerms = hashtagTermList.join(' ');
    const excludedHashtagTerms = excludedHashtagTermList.join(' ');
    const plainTerms = plainTermList.join(' ');
    const excludedPlainTerms = excludedPlainTermList.join(' ');

    const inChannels: string[] = [];
    const excludedChannels: string[] = [];
    const fromUsers: string[] = [];
    const excludedUsers: string[] = [];
    let afterDate = '';
    let excludedAfterDate = '';
    let beforeDate = '';
    let excludedBeforeDate = '';
    let onDate = '';
    let excludedDate = '';
    const excludedExtensions: string[] = [];
    const extensions: string[] = [];

    for (const flag of flags) {
        if (flag.name === 'in' || flag.name === 'channel') {
            if (flag.exclude) {
                excludedChannels.push(flag.value);
            } else {
                inChannels.push(flag.value);
            }
        } else if (flag.name === 'from') {
            if (flag.exclude) {
                excludedUsers.push(flag.value);
            } else {
                fromUsers.push(flag.value);
            }
        } else if (flag.name === 'after') {
            if (flag.exclude) {
                excludedAfterDate = flag.value;
            } else {
                afterDate = flag.value;
            }
        } else if (flag.name === 'before') {
            if (flag.exclude) {
                excludedBeforeDate = flag.value;
            } else {
                beforeDate = flag.value;
            }
        } else if (flag.name === 'on') {
            if (flag.exclude) {
                excludedDate = flag.value;
            } else {
                onDate = flag.value;
            }
        } else if (flag.name === 'ext') {
            if (flag.exclude) {
                excludedExtensions.push(flag.value);
            } else {
                extensions.push(flag.value);
            }
        }
    }

    const paramsList: SearchParams[] = [];

    if (plainTerms !== '' || excludedPlainTerms !== '') {
        paramsList.push({
            Terms: plainTerms,
            ExcludedTerms: excludedPlainTerms,
            IsHashtag: false,
            InChannels: inChannels,
            ExcludedChannels: excludedChannels,
            FromUsers: fromUsers,
            ExcludedUsers: excludedUsers,
            AfterDate: afterDate,
            ExcludedAfterDate: excludedAfterDate,
            BeforeDate: beforeDate,
            ExcludedBeforeDate: excludedBeforeDate,
            Extensions: extensions,
            ExcludedExtensions: excludedExtensions,
            OnDate: onDate,
            ExcludedDate: excludedDate,
        });
    }

    if (hashtagTerms !== '' || excludedHashtagTerms !== '') {
        paramsList.push({
            Terms: hashtagTerms,
            ExcludedTerms: excludedHashtagTerms,
            IsHashtag: true,
            InChannels: inChannels,
            ExcludedChannels: excludedChannels,
            FromUsers: fromUsers,
            ExcludedUsers: excludedUsers,
            AfterDate: afterDate,
            ExcludedAfterDate: excludedAfterDate,
            BeforeDate: beforeDate,
            ExcludedBeforeDate: excludedBeforeDate,
            Extensions: extensions,
            ExcludedExtensions: excludedExtensions,
            OnDate: onDate,
            ExcludedDate: excludedDate,
        });
    }

    // special case for when no terms are specified but we still have a filter
    if (
        plainTerms === '' &&
        hashtagTerms === '' &&
        excludedPlainTerms === '' &&
        excludedHashtagTerms === '' &&
        (inChannels.length !== 0 ||
            fromUsers.length !== 0 ||
            excludedChannels.length !== 0 ||
            excludedUsers.length !== 0 ||
            extensions.length !== 0 ||
            excludedExtensions.length !== 0 ||
            afterDate !== '' ||
            excludedAfterDate !== '' ||
            beforeDate !== '' ||
            excludedBeforeDate !== '' ||
            onDate !== '' ||
            excludedDate !== '')
    ) {
        paramsList.push({
            Terms: '',
            ExcludedTerms: '',
            IsHashtag: false,
            InChannels: inChannels,
            ExcludedChannels: excludedChannels,
            FromUsers: fromUsers,
            ExcludedUsers: excludedUsers,
            AfterDate: afterDate,
            ExcludedAfterDate: excludedAfterDate,
            BeforeDate: beforeDate,
            ExcludedBeforeDate: excludedBeforeDate,
            Extensions: extensions,
            ExcludedExtensions: excludedExtensions,
            OnDate: onDate,
            ExcludedDate: excludedDate,
        });
    }

    return paramsList;
};
