import {Editor, Extension} from '@tiptap/core';
import {Node as ProsemirrorNode} from 'prosemirror-model';
import {Plugin} from 'prosemirror-state';
import {Decoration, DecorationSet} from 'prosemirror-view';

import styles from './styles.module.css';

export interface PlaceholderOptions {
    emptyEditorClass: string;
    emptyNodeClass: string;
    placeholder:
    | ((PlaceholderProps: {editor: Editor; node: ProsemirrorNode; pos: number; hasAnchor: boolean}) => string)
    | string;
    showOnlyWhenEditable: boolean;
    showOnlyCurrent: boolean;
    includeChildren: boolean;
}

export const TimeWebkitEditorPlaceholderPlugin = Extension.create<PlaceholderOptions>({
    name: 'time-webkit-placeholder',

    addOptions() {
        return {
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            placeholder: 'Write something …',
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
            includeChildren: false,
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    decorations: ({doc, selection}) => {
                        const active = this.editor.isEditable || !this.options.showOnlyWhenEditable;
                        const {anchor} = selection;
                        const decorations: Decoration[] = [];

                        if (!active) {
                            return null;
                        }

                        const isEditorEmpty = !doc.textContent && doc.content.childCount < 2;

                        doc.descendants((node, pos) => {
                            const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize;
                            const isEmpty = !node.isLeaf && !node.childCount;

                            if ((hasAnchor || !this.options.showOnlyCurrent) && isEmpty) {
                                const classes = [this.options.emptyNodeClass, styles.placeholder];

                                if (isEditorEmpty) {
                                    classes.push(this.options.emptyEditorClass);
                                }

                                const decoration = Decoration.node(pos, pos + node.nodeSize, {
                                    class: classes.join(' '),
                                    'data-placeholder':
                                        typeof this.options.placeholder === 'function' ? this.options.placeholder({
                                            editor: this.editor,
                                            node,
                                            pos,
                                            hasAnchor,
                                        }) : this.options.placeholder,
                                });

                                decorations.push(decoration);
                            }

                            return this.options.includeChildren;
                        });

                        return DecorationSet.create(doc, decorations);
                    },
                },
            }),
        ];
    },
});
