import React, {useMemo, memo} from 'react';
import {useSelector} from 'react-redux';

import {CustomStatusDuration, type UserCustomStatus} from 'mattermost-redux/types/users';

import {GlobalState} from 'types/store';
import {getCurrentUserTimezone} from 'selectors/general';
import {makeGetCustomStatus, isCustomStatusEnabled, isCustomStatusExpired} from 'selectors/views/custom_status';

import Constants from 'utils/constants';
import OverlayTrigger from 'components/overlay_trigger';
import Tooltip from 'components/tooltip';
import Emoji from 'components/emoji/emoji';

import ExpiryTime from './expiry_time';

interface Props {
    emojiSize?: number;
    showTooltip?: boolean;
    tooltipDirection?: 'top' | 'right' | 'bottom' | 'left';
    spanStyle?: React.CSSProperties;
    emojiStyle?: React.CSSProperties;
    userID?: string;
    onClick?: () => void;
}

const EMPTY_STYLE = {};

type ComponentProps = Omit<Props, 'userID'> & {
    customStatus?: UserCustomStatus;
};

export const CustomStatusEmojiComponent = ({
    emojiSize = 16,
    showTooltip = false,
    tooltipDirection = 'top',
    spanStyle = EMPTY_STYLE,
    emojiStyle = EMPTY_STYLE,
    customStatus,
    onClick,
}: ComponentProps) => {
    const timezone = useSelector(getCurrentUserTimezone);

    const customStatusExpired = useSelector((state: GlobalState) => isCustomStatusExpired(state, customStatus));
    const customStatusEnabled = useSelector(isCustomStatusEnabled);
    const isCustomStatusSet = Boolean(customStatusEnabled && customStatus?.emoji && !customStatusExpired);
    if (!isCustomStatusSet || !customStatus) {
        return null;
    }

    const statusEmoji = (
        <Emoji
            name={customStatus.emoji}
            size={emojiSize}
            style={emojiStyle}
            onClick={onClick}
        />
    );

    if (!showTooltip) {
        return statusEmoji;
    }

    return (
        <OverlayTrigger
            delayShow={Constants.OVERLAY_TIME_DELAY}
            placement={tooltipDirection}
            overlay={
                <Tooltip id='custom-status-tooltip'>
                    <div className='custom-status'>
                        <Emoji name={customStatus.emoji} preview={true} />
                        {customStatus.text &&
                            <div
                                className='custom-status-text'
                                style={{marginLeft: 5}}
                            >
                                {customStatus.text}
                            </div>
                        }
                    </div>
                    {customStatus.expires_at && customStatus.duration !== CustomStatusDuration.DONT_CLEAR &&
                        <div>
                            <ExpiryTime
                                time={customStatus.expires_at}
                                timezone={timezone}
                                className='custom-status-expiry'
                            />
                        </div>
                    }
                </Tooltip>
            }
        >
            <span style={spanStyle}>
                {statusEmoji}
            </span>
        </OverlayTrigger>
    );
};

function CustomStatusEmoji({
    emojiSize = 16,
    showTooltip = false,
    tooltipDirection = 'top',
    spanStyle = EMPTY_STYLE,
    emojiStyle = EMPTY_STYLE,
    userID = '',
    onClick,
}: Props) {
    const getCustomStatus = useMemo(makeGetCustomStatus, []);
    const customStatus = useSelector((state: GlobalState) => getCustomStatus(state, userID));

    if (!customStatus) {
        return null;
    }

    return (
        <CustomStatusEmojiComponent
            customStatus={customStatus}
            emojiSize={emojiSize}
            showTooltip={showTooltip}
            tooltipDirection={tooltipDirection}
            spanStyle={spanStyle}
            emojiStyle={emojiStyle}
            onClick={onClick}
        />
    );
}

function arePropsEqual(prevProps: Props, nextProps: Props) {
    return prevProps.userID === nextProps.userID;
}

export default memo(CustomStatusEmoji, arePropsEqual);
