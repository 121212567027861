import {connect} from 'react-redux';

import {createSelector} from 'reselect';

import {Preferences} from 'mattermost-redux/constants';
import {getChannelNameToDisplayNameMap} from 'mattermost-redux/selectors/entities/channels';
import {getAutolinkedUrlSchemes, getConfig, getManagedResourcePaths} from 'mattermost-redux/selectors/entities/general';
import {getBool} from 'mattermost-redux/selectors/entities/preferences';
import {getCurrentTeam} from 'mattermost-redux/selectors/entities/teams';
import {getAllUserMentionKeys} from 'mattermost-redux/selectors/entities/search';

import {type GlobalState} from 'mattermost-redux/types/store';

import {getEmojiMap} from 'selectors/emojis';
import {getSiteURL} from 'utils/url';
import {type ChannelNamesMap, type MentionKey, formatText} from 'utils/text_formatting';

import {getPost} from 'mattermost-redux/selectors/entities/posts';

import Markdown from './markdown';

type Props = {
    channelNamesMap?: ChannelNamesMap;
    mentionKeys?: MentionKey[];
    postId?: string;
    editedAt?: string;
    message: string;
    options: any;
    hasImageProxy?: boolean;
    proxyImages?: boolean;
};

function makeGetChannelNamesMap() {
    return createSelector(
        getChannelNameToDisplayNameMap,
        (state: GlobalState, props: Props) => props && props.channelNamesMap,
        (channelNamesMap, channelMentions) => {
            if (channelMentions) {
                return Object.assign({}, channelNamesMap, channelMentions);
            }

            return channelNamesMap;
        },
    );
}

function makeMapStateToProps() {
    const getChannelNamesMap = makeGetChannelNamesMap();

    return function mapStateToProps(state: GlobalState, ownProps: Props) {
        const config = getConfig(state);

        let channelId;
        if (ownProps.postId) {
            channelId = getPost(state, ownProps.postId)?.channel_id;
        }

        /*
         * An object mapping channel names to channels for the current team
         */
        const channelNamesMap = getChannelNamesMap(state, ownProps);

        /**
         * If an image proxy is enabled.
         */
        const proxyImages =
            (ownProps.hasImageProxy || config.HasImageProxy === 'true') && typeof ownProps.proxyImages !== undefined ? ownProps.proxyImages : true;

        /*
         * An array of words that can be used to mention a user
         */
        const mentionKeys = ownProps.mentionKeys || getAllUserMentionKeys(state);

        /**
         * Minimum number of characters in a hashtag.
         */
        const minimumHashtagLength = parseInt(config.MinimumHashtagLength || '', 10);

        /*
         * An array of paths on the server that are managed by another server
         */
        const managedResourcePaths = getManagedResourcePaths(state);

        /*
         * The root Site URL for the page
         */
        const siteURL = getSiteURL();
        const team = getCurrentTeam(state);

        /*
         * An array of URL schemes that should be turned into links. Anything that looks
         * like a link will be turned into a link if this is not provided.
         */
        const autolinkedUrlSchemes = getAutolinkedUrlSchemes(state);

        return {
            enableFormatting: getBool(state, Preferences.CATEGORY_ADVANCED_SETTINGS, 'formatting', true),
            htmlFormattedText: formatText(
                ownProps.message,
                {
                    ...(ownProps.options || {}),
                    ...{
                        autolinkedUrlSchemes,
                        siteURL,
                        additionalSiteURLs: config.AdditionalSiteURLs,
                        mentionKeys,
                        atMentions: true,
                        channelNamesMap,
                        proxyImages,
                        team,
                        minimumHashtagLength,
                        managedResourcePaths,
                        editedAt: ownProps.editedAt,
                        postId: ownProps.postId,
                    },
                },
                getEmojiMap(state),
            ),
            channelId,
        };
    };
}

export default connect(makeMapStateToProps)(Markdown);
