import {forwardRef} from 'react';
import type {ForwardedRef, HTMLAttributes, PropsWithChildren} from 'react';

import classNames from 'classnames';

import styles from './dropdown-container.module.css';

export enum BorderRadiusSize {
    Medium = 'medium',
    Large = 'large'
}

type Props = HTMLAttributes<HTMLDivElement> & {
    isOpen?: boolean;
    borderRadiusSize: BorderRadiusSize;
};

export const DropdownContainer = forwardRef(({
    className,
    children,
    borderRadiusSize,
    ...props
}: PropsWithChildren<Props>, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <div
            {...props}
            ref={ref}
            className={classNames(styles.root, className, styles[borderRadiusSize])}
        >
            {children}
        </div>
    );
});

DropdownContainer.displayName = 'Dropdown';
