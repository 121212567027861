import {type ParsedThreadUpdatedEvent, type ThreadUpdatedEvent} from '../types/thread_updated_event';

export function parseThreadUpdatedEvent(threadUpdatedEvent: ThreadUpdatedEvent): ParsedThreadUpdatedEvent {
    return {
        ...threadUpdatedEvent,
        data: {
            ...threadUpdatedEvent.data,

            // это поле в сообщении приходит в виде JSON-строки
            thread: JSON.parse(threadUpdatedEvent.data.thread),
        },
    };
}
