import type {FunctionComponent} from 'react';
import classNames from 'classnames';

import {BUTTON_VARIANT, Button} from '../../button';

import type {Props} from './types';
import styles from './styles.module.css';

export const IconButton: FunctionComponent<Props> = (props) => {
    const {
        destructive = false,
        inverted = false,
        compact = false,
        flat = false,
        className,
        icon,
        children,
        ...otherProps
    } = props;

    const content = icon ? (
        <span className={styles.iconWrapper}>
            {icon}
            {children ? <span>{children}</span> : null}
        </span>
    ) : (
        children
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const iconCls = compact ? styles[`compactBtn-${otherProps.size}`] : styles[`iconBtn-${otherProps.size}`];

    if (destructive) {
        if (inverted) {
            return (
                <Button
                    className={classNames(className, styles.destructiveInverted, iconCls)}
                    {...otherProps}
                    variant={BUTTON_VARIANT.SECONDARY}
                    destructive={true}
                >
                    {content}
                </Button>
            );
        }

        return (
            <Button className={classNames(className, iconCls)} {...otherProps} variant={BUTTON_VARIANT.PRIMARY} destructive={true}>
                {content}
            </Button>
        );
    }

    if (flat) {
        if (!inverted) {
            return (
                <Button
                    className={classNames(className, styles.flatNotInverted, iconCls)}
                    {...otherProps}
                    variant={BUTTON_VARIANT.SECONDARY}
                >
                    {content}
                </Button>
            );
        }

        return (
            <Button
                className={classNames(className, styles.flatInverted, iconCls)}
                {...otherProps}
                variant={BUTTON_VARIANT.SECONDARY}
                inverted={true}
            >
                {content}
            </Button>
        );
    }

    return (
        <Button
            className={classNames(className, iconCls)}
            {...otherProps}
            variant={BUTTON_VARIANT.PRIMARY}
            inverted={inverted}
        >
            {content}
        </Button>
    );
};
