import {Plugin, PluginKey, Selection, EditorState, Transaction} from 'prosemirror-state';
import {Extension, CommandManager} from '@tiptap/core';

export function isMacOS(): boolean {
    // eslint-disable-next-line no-negated-condition
    return typeof navigator !== 'undefined' ? (/Mac/).test(navigator.platform) : false;
}

export function isiOS(): boolean {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform,
        ) ||

        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
}

export function createChainableState(config: {transaction: Transaction; state: EditorState}): EditorState {
    const {state, transaction} = config;
    let {selection} = transaction;
    let {doc} = transaction;
    let {storedMarks} = transaction;

    return {
        ...state,
        apply: state.apply.bind(state),
        applyTransaction: state.applyTransaction.bind(state),
        filterTransaction: state.filterTransaction,
        plugins: state.plugins,
        schema: state.schema,
        reconfigure: state.reconfigure.bind(state),
        toJSON: state.toJSON.bind(state),
        get storedMarks() {
            return storedMarks;
        },
        get selection() {
            return selection;
        },
        get doc() {
            return doc;
        },
        get tr() {
            selection = transaction.selection;
            doc = transaction.doc;
            storedMarks = transaction.storedMarks;

            return transaction;
        },
    };
}

export const TimeWebappEditorKeymapExtension = Extension.create<{
    ctrlSend?: boolean;
    codeBlockOnCtrlEnter: boolean;
}>({
    name: 'keymap',

    addKeyboardShortcuts() {
        const ctrlSend = this.options.ctrlSend;
        const codeBlockOnCtrlEnter = this.options.codeBlockOnCtrlEnter;

        const handleBackspace = () =>
            this.editor.commands.first(({commands}) => [
                () => commands.undoInputRule(),

                // maybe convert first text block node to default node
                () =>
                    commands.command(({tr}) => {
                        const {selection, doc} = tr;
                        const {empty, $anchor} = selection;
                        const {pos, parent} = $anchor;
                        const isAtStart = Selection.atStart(doc).from === pos;

                        if (!empty || !isAtStart || !parent.type.isTextblock || parent.textContent.length) {
                            return false;
                        }

                        return commands.clearNodes();
                    }),
                () => commands.deleteSelection(),
                () => commands.joinBackward(),
                () => commands.selectNodeBackward(),
                () => commands.deleteRange({from: this.editor.state.selection.$from.pos - 1, to: this.editor.state.selection.$from.pos}),
            ]);

        const handleDelete = () =>
            this.editor.commands.first(({commands}) => [
                () => commands.deleteSelection(),
                () => commands.joinForward(),
                () => commands.selectNodeForward(),
            ]);

        const handleEnter = () => {
            return this.editor.commands.first(({commands}) => [
                () => commands.newlineInCode(),
                () => commands.createParagraphNear(),
                () => commands.liftEmptyBlock(),
                () => commands.splitBlock(),
            ]);
        };
        const handleExit = () => {
            return this.editor.commands.first(({commands}) => [
                () => codeBlockOnCtrlEnter && commands.newlineInCode(),
                () => this.editor.commands.exitCode(),
            ]);
        };

        const baseKeymap = {
            Enter: ctrlSend ? handleEnter : handleExit,
            'Shift-Enter': handleEnter,
            'Alt-Enter': handleEnter,
            'Mod-Enter': ctrlSend ? () => this.editor.commands.exitCode() : handleEnter,
            Backspace: handleBackspace,
            'Mod-Backspace': handleBackspace,
            'Shift-Backspace': handleBackspace,
            Delete: handleDelete,
            'Mod-Delete': handleDelete,
            'Mod-a': () => this.editor.commands.selectAll(),
        };

        const pcKeymap = {
            ...baseKeymap,
        };

        const macKeymap = {
            ...baseKeymap,
            'Ctrl-h': handleBackspace,
            'Alt-Backspace': handleBackspace,
            'Ctrl-d': handleDelete,
            'Ctrl-Alt-Backspace': handleDelete,
            'Alt-Delete': handleDelete,
            'Alt-d': handleDelete,
            'Ctrl-a': () => this.editor.commands.selectTextblockStart(),
            'Ctrl-e': () => this.editor.commands.selectTextblockEnd(),
        };

        if (isiOS() || isMacOS()) {
            return macKeymap;
        }

        return pcKeymap;
    },

    addProseMirrorPlugins() {
        return [

            // With this plugin we check if the whole document was selected and deleted.
            // In this case we will additionally call `clearNodes()` to convert e.g. a heading
            // to a paragraph if necessary.
            // This is an alternative to ProseMirror's `AllSelection`, which doesn’t work well
            // with many other commands.
            new Plugin({
                key: new PluginKey('clearDocument'),
                appendTransaction: (transactions, oldState, newState) => {
                    const docChanges =
                        transactions.some((transaction) => transaction.docChanged) && !oldState.doc.eq(newState.doc);

                    if (!docChanges) {
                        return null;
                    }

                    const {empty, from, to} = oldState.selection;
                    const allFrom = Selection.atStart(oldState.doc).from;
                    const allEnd = Selection.atEnd(oldState.doc).to;
                    const allWasSelected = from === allFrom && to === allEnd;
                    const isEmpty = newState.doc.textBetween(0, newState.doc.content.size, ' ', ' ').length === 0;

                    if (empty || !allWasSelected || !isEmpty) {
                        return null;
                    }

                    const tr = newState.tr;
                    const state = createChainableState({
                        state: newState,
                        transaction: tr,
                    });
                    const {commands} = new CommandManager({
                        editor: this.editor,
                        state,
                    });

                    commands.clearNodes();

                    if (!tr.steps.length) {
                        return null;
                    }

                    return tr;
                },
            }),
        ];
    },
});
