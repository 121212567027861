import React, {} from 'react';
import type {SuggestionProps} from '@tiptap/suggestion';

import classNames from 'classnames';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {sendToStatist} from '@time-webkit/statist';

import {MentionList} from '../../../../components/mention-list';

import type {CommandMention} from '../../types';

import styles from './styles.module.css';

type MentionListProps = {
    getMentions: (query: string) => Promise<CommandMention[]>;
} & Pick<SuggestionProps<CommandMention>, 'query' | 'command' | 'decorationNode'>;

const Description: React.FunctionComponent = (props) => {
    const typographyCls = useTypography({
        size: TypographySize.BodyS,
        noDefaultColor: true,
    });

    return <span className={classNames(styles.description, typographyCls)}>{props.children}</span>;
};

const Handle: React.FunctionComponent = (props) => {
    const typographyCls = useTypography({
        size: TypographySize.BodyL,
    });

    return <span className={classNames(styles.handle, typographyCls)}>{props.children}</span>;
};

const ChannelOption = (props: CommandMention) => {
    return (
        <div className={styles.mentionWrapper}>
            <span className={classNames(styles.icon, styles.groupIcon)}>
                <svg
                    width='24'
                    height='24'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='m7 21 7.9-18H17L9.1 21H7Z'
                        fill='currentColor'
                    />
                </svg>
            </span>
            <Handle>{props.handle}</Handle>
            <Description>{props.displayName}</Description>
        </div>
    );
};

/* 8px top padding, 4 list items and just enough of 5th */
// eslint-disable-next-line no-mixed-operators
const LIST_MAX_HEIGHT = 8 + 59 * 4 + 59 * 0.6;
// eslint-disable-next-line no-mixed-operators
const LIST_MAX_HEIGHT_THREE = 8 + 59 * 4 + 59 * 1;

export const ChannelMentionList: React.VoidFunctionComponent<MentionListProps> = (props) => {
    const {query, getMentions, command, decorationNode} = props;
    const calcMaxHeight = (mentions: CommandMention[]) =>
        (mentions.length === 3 ? LIST_MAX_HEIGHT_THREE : LIST_MAX_HEIGHT);
    const onSelect = (_: CommandMention, index: number) => {
        sendToStatist('editor.searchCommand.resultTap', {
            resultNumber: index + 1,
        });
    };

    return (
        <MentionList
            query={query}
            command={command}
            decorationNode={decorationNode}
            calcMaxHeight={calcMaxHeight}
            maxHeight={LIST_MAX_HEIGHT}
            getMentions={getMentions}
            onSelect={onSelect}
            renderMentionOption={(mention) => <ChannelOption {...mention} />}
        />
    );
};
