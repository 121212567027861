import React from 'react';

import {CloseIcon} from '../../../../icons';

import styles from './styles.module.css';

export type ModalCloserProps = {
    onClick: () => void;
    tabIndex?: number;
};

export const ModalCloser: React.FunctionComponent<ModalCloserProps> = (props) => {
    const {onClick, tabIndex} = props;

    return (
        <button type='button' onClick={onClick} className={styles.button} tabIndex={tabIndex}>
            <CloseIcon className={styles.svg} />
        </button>
    );
};
