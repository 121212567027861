import type {SVGProps} from 'react';
import {forwardRef} from 'react';

export const FormatListNumberedIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props} ref={ref}>
        <path
            d='M3.5 4.95V8.7h1.25v-5h-2.5v1.25H3.5ZM7 11v2h14v-2H7Zm2 6v2h12v-2H9ZM9 5v2h12V5H9ZM5.7 15.3A1 1 0 0 0 5 15H2v1.33h2.67L2 19.11v1.22h4V19H3.5l2.22-2.3a1 1 0 0 0-.01-1.4Z'
            fill='currentColor'
        />
    </svg>
));
