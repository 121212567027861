
import type {Channel} from '@mattermost/types/channels';
import type {GlobalState} from 'types/store';
import {isComment, isFromWebhook, isPostOwner, isSystemMessage} from 'utils/post_utils';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';
import {getCurrentChannelId, isMutedChannel} from 'mattermost-redux/selectors/entities/channels';
import {isCurrentUserChannelMember} from 'features/channels/selectors/is_current_user_channel_member';
import {isCurrentUserNotAvailable} from 'features/channels/selectors/is_current_user_not_available';
import {getCurrentUserChannelNotificationLevel} from 'features/channels/selectors/get_current_user_channel_notification_level';
import {NotificationLevel} from 'mattermost-redux/constants/channels';
import {isDmChannel} from 'features/sidebar';
import {isGmChannel} from 'features/sidebar/utils/isGmChannel';
import {getCurrentChannelName} from 'features/channels/selectors/get_current_channel_name';
import {isThreadOpened} from 'features/threads/selectors/is_thread_opened';
import {isAppActive} from 'features/app_activity/selector/is_app_active';

import type {Post} from 'mattermost-redux/types/posts';

import type {Meta} from './types';

import {getFollowersFromMessageProps, getMentionsFromMessageProps} from './utils';
import {RejectReason} from './constants';

type Result = { send: true } | { send: false; reason: RejectReason};

export const shouldSendNotification =
    (state: GlobalState, post: Post, meta: Meta, channel: Channel | undefined): Result => {
        if (isSystemMessage(post)) {
            return {
                send: false,
                reason: RejectReason.IS_SYSTEM_MESSAGE,
            };
        }

        // @TODO: Если в посте вебхука упомянут пользователь, то нотификация придет на мобилу
        if (isPostOwner(state, post) && isFromWebhook(post)) {
            return {
                send: false,
                reason: RejectReason.IS_CURRENT_USER_WEBHOOK_POST,
            };
        }

        if (isPostOwner(state, post)) {
            return {
                send: false,
                reason: RejectReason.IS_CURRENT_USER_POST,
            };
        }

        if (isCurrentUserNotAvailable(state)) {
            return {
                send: false,
                reason: RejectReason.CURRENT_USER_NOT_AVAILABLE,
            };
        }

        if (!isCurrentUserChannelMember(state, post.channel_id)) {
            return {
                send: false,
                reason: RejectReason.NOT_A_MEMBER,
            };
        }

        if (isMutedChannel(state, post.channel_id)) {
            return {
                send: false,
                reason: RejectReason.CHANNEL_MUTED,
            };
        }

        const isThreadPost = isComment(post);

        if (isThreadPost && isThreadOpened(state, post.root_id) && isAppActive(state)) {
            return {
                send: false,
                reason: RejectReason.THREAD_IS_VISIBLE,
            };
        }

        const currentChannelId = getCurrentChannelId(state);
        const currentChannelName = getCurrentChannelName(state);

        if (
            !isThreadPost &&
        (channel?.id === currentChannelId || currentChannelName === meta.channel_name) &&
        isAppActive(state)
        ) {
            return {
                send: false,
                reason: RejectReason.CURRENT_CHANNEL_POST,
            };
        }

        const notificationLevel = getCurrentUserChannelNotificationLevel(state, post.channel_id);

        if (notificationLevel === NotificationLevel.NONE) {
            return {
                send: false,
                reason: RejectReason.NOTIFICATIONS_TURNED_OFF,
            };
        }

        const postFollowers = getFollowersFromMessageProps(meta);
        const postMentions = Array.from(new Set([...getMentionsFromMessageProps(meta), ...postFollowers]));
        const currentUserId = getCurrentUserId(state);

        const isMentioned = postMentions.includes(currentUserId);
        const isGMChannel = isGmChannel(channel) || isGmChannel({type: meta.channel_type} as Channel);
        const isDMLikeChannel = isGMChannel || isDmChannel(channel) || isDmChannel({type: meta.channel_type} as Channel);

        if (notificationLevel === NotificationLevel.MENTION && !isMentioned && !isDMLikeChannel) {
            return {
                send: false,
                reason: RejectReason.NOT_MENTIONED,
            };
        }

        const isFollowingPost = postFollowers.includes(currentUserId);

        if (notificationLevel === NotificationLevel.MENTION && isGMChannel && !isMentioned && isThreadPost && !isFollowingPost) {
            return {
                send: false,
                reason: RejectReason.NOT_FOLLOWING_THREAD,
            };
        }

        if (isThreadPost && notificationLevel === NotificationLevel.ALL && !isFollowingPost) {
            return {
                send: false,
                reason: RejectReason.NOT_FOLLOWING_THREAD,
            };
        }

        return {send: true};
    };
