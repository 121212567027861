import type {AxiosError} from 'axios';
import React from 'react';

import debounce from '@tinkoff/utils/function/debounce';

import {CanceledError, NotAuthorizedError} from 'mattermost-redux/api/errors';

import {UserTypes} from 'mattermost-redux/action_types';

import {useDispatch} from 'react-redux';

import {Client4} from 'mattermost-redux/client';

import {SEARCH_DEBOUNCE_TIMEOUT} from './consts';
import type {Mention} from './types';

type UseState<M extends Mention> = {
    mentions: M[];
    loading: boolean;
    error: null | AxiosError;
};

enum UseMentionActionType {
    RECEIVED_MENTIONS,
    RECEIVED_ERROR,
    STARTED,
}
type UseMentionAction<M extends Mention> = {
    type: UseMentionActionType;
    payload?: M[] | AxiosError;
};

const initialState = {mentions: [], loading: false, error: null};

function useMentionReducer<M extends Mention>(state: UseState<M>, action: UseMentionAction<M>): UseState<M> {
    switch (action.type) {
    case UseMentionActionType.RECEIVED_MENTIONS:
        return {mentions: action.payload as M[], loading: false, error: null};
    case UseMentionActionType.STARTED:
        return {...state, loading: true, error: null};
    case UseMentionActionType.RECEIVED_ERROR:
        return {...state, loading: false, error: action.payload as AxiosError};
    default:
        throw new Error();
    }
}

export function useGetMentions<M extends Mention>(
    query: string,
    getMentionsRequest: (query: string) => Promise<M[]>,
    ignoreEmptyQuery = true,
) {
    const [state, dispatch] = React.useReducer<typeof useMentionReducer<M>>(useMentionReducer, initialState);
    const reduxDispatch = useDispatch();

    const getMentions = React.useCallback(
        (query) => {
            getMentionsRequest(query).
                then((mentions) =>
                    dispatch({
                        type: UseMentionActionType.RECEIVED_MENTIONS,
                        payload: mentions,
                    }),
                ).
                catch((e) => {
                    if (e instanceof CanceledError) {
                        return;
                    }

                    if (e instanceof NotAuthorizedError) {
                        Client4.setToken('');
                        reduxDispatch({type: UserTypes.LOGOUT_SUCCESS, data: {}});
                        return;
                    }

                    dispatch({
                        type: UseMentionActionType.RECEIVED_ERROR,
                        payload: e,
                    });
                });
        },
        [getMentionsRequest, dispatch, reduxDispatch],
    );

    const debouncedGetMentions = React.useCallback(debounce(SEARCH_DEBOUNCE_TIMEOUT, getMentions), [getMentions]);

    const queryMentions = React.useCallback(
        (query: string) => {
            dispatch({
                type: UseMentionActionType.STARTED,
            });
            return debouncedGetMentions(query);
        },
        [debouncedGetMentions, dispatch],
    );

    const retry = React.useCallback(() => queryMentions(query), [queryMentions, query]);

    React.useEffect(() => {
        if (query || !ignoreEmptyQuery) {
            queryMentions(query);
            return;
        }

        dispatch({
            type: UseMentionActionType.RECEIVED_MENTIONS,
            payload: [],
        });
    }, [query, queryMentions, ignoreEmptyQuery]);

    return {
        ...state,
        retry,
    };
}
