import {AxiosRequestConfig} from 'axios';

type AxiosErrorObject<D = unknown> = {

    // Standard
    message: string;
    name: string;

    // Microsoft
    description?: string;
    number?: string;

    // Mozilla
    fileName?: string;
    lineNumber?: string;
    columnNumber?: string;
    stack?: string;

    // Axios
    config: AxiosRequestConfig<D>;
    code: number;
    status: number | null;
}

export class RequestError<D = unknown> extends Error {
    public payload: AxiosErrorObject<D>;

    constructor(errorObject: AxiosErrorObject<D>, stack?: any) {
        super(errorObject.message);

        this.message = errorObject.message;
        this.name = errorObject.name;
        this.stack = stack;

        this.payload = errorObject;
    }
}

export class InvalidRequestError extends RequestError {}

export class NotAuthorizedError extends RequestError {}

export class NoPermissionError extends RequestError {}

export class NetworkError extends RequestError {}

export class TimedOutError extends RequestError {}

export class TimedOutOnClientError extends TimedOutError {}

export class TimedOutOnServerError extends TimedOutError {}

export class AbortedError extends RequestError {}

export class CanceledError extends RequestError {}

export class UnavailableError extends RequestError {}
