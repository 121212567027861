import {createAsyncThunk} from '@reduxjs/toolkit';

import {type AppDispatch} from 'stores/redux_store';

import {putProfile} from '../api/put_profile';
import {type Profile} from '../types/profile';

export const updateProfile = createAsyncThunk(
    'profiles/actions/updateProfile',
    async (profile: Profile, thunkAPI) => {
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const updatedProfile = await dispatch(putProfile(profile)).unwrap();

        return updatedProfile;
    },
);
